export const CREATE_POLL_REQUEST = 'CREATE_POLL_REQUEST';
export const CREATE_POLL_SUCCESS = 'CREATE_POLL_SUCCESS';
export const CREATE_POLL_FAILED = 'CREATE_POLL_FAILED';

export const DOWNLOAD_POLL_SUBMISSIONS_REQUEST = 'DOWNLOAD_POLL_SUBMISSIONS_REQUEST';
export const DOWNLOAD_POLL_SUBMISSIONS_SUCCESS = 'DOWNLOAD_POLL_SUBMISSIONS_SUCCESS';
export const DOWNLOAD_POLL_SUBMISSIONS_FAILED = 'DOWNLOAD_POLL_SUBMISSIONS_FAILED';

export const GET_POLL_REQUEST = 'GET_POLL_REQUEST';
export const GET_POLL_SUCCESS = 'GET_POLL_SUCCESS';
export const GET_POLL_FAILED = 'GET_POLL_FAILED';

export const GET_POLL_LIST_REQUEST = 'GET_POLL_LIST_REQUEST';
export const GET_POLL_LIST_SUCCESS = 'GET_POLL_LIST_SUCCESS';
export const GET_POLL_LIST_FAILED = 'GET_POLL_LIST_FAILED';

export const REMOVE_POLL_REQUEST = 'REMOVE_POLL_REQUEST';
export const REMOVE_POLL_SUCCESS = 'REMOVE_POLL_SUCCESS';
export const REMOVE_POLL_FAILED = 'REMOVE_POLL_FAILED';

export const REMOVE_POLL_IMAGE_REQUEST = 'REMOVE_POLL_IMAGE_REQUEST';
export const REMOVE_POLL_IMAGE_SUCCESS = 'REMOVE_POLL_IMAGE_SUCCESS';
export const REMOVE_POLL_IMAGE_FAILED = 'REMOVE_POLL_IMAGE_FAILED';

export const REMOVE_POLL_VOTE_REQUEST = 'REMOVE_POLL_VOTE_REQUEST';
export const REMOVE_POLL_VOTE_SUCCESS = 'REMOVE_POLL_VOTE_SUCCESS';
export const REMOVE_POLL_VOTE_FAILED = 'REMOVE_POLL_VOTE_FAILED';

export const RESET_POLL_URL_CHANGED = 'RESET_POLL_URL_CHANGED';
export const RESET_POLL_URL_CHANGED_SUCCESS = 'RESET_POLL_URL_CHANGED_SUCCESS';

export const UPDATE_POLL_NAME_REQUEST = 'UPDATE_POLL_NAME_REQUEST';
export const UPDATE_POLL_NAME_SUCCESS = 'UPDATE_POLL_NAME_SUCCESS';
export const UPDATE_POLL_NAME_FAILED = 'UPDATE_POLL_NAME_FAILED';

export const UPDATE_POLL_QUESTIONS_REQUEST = 'UPDATE_POLL_QUESTIONS_REQUEST';
export const UPDATE_POLL_QUESTIONS_SUCCESS = 'UPDATE_POLL_QUESTIONS_SUCCESS';
export const UPDATE_POLL_QUESTIONS_FAILED = 'UPDATE_POLL_QUESTIONS_FAILED';

export const UPDATE_POLL_VOTING_TIMEFRAME_REQUEST = 'UPDATE_POLL_VOTING_TIMEFRAME_REQUEST';
export const UPDATE_POLL_VOTING_TIMEFRAME_SUCCESS = 'UPDATE_POLL_VOTING_TIMEFRAME_SUCCESS';
export const UPDATE_POLL_VOTING_TIMEFRAME_FAILED = 'UPDATE_POLL_VOTING_TIMEFRAME_FAILED';

export const UPLOAD_POLL_IMAGE_REQUEST = 'UPLOAD_POLL_IMAGE_REQUEST';
export const UPLOAD_POLL_IMAGE_SUCCESS = 'UPLOAD_POLL_IMAGE_SUCCESS';
export const UPLOAD_POLL_IMAGE_FAILED = 'UPLOAD_POLL_IMAGE_FAILED';

export function createPollRequest (body) {
  return {
    body,
    type: CREATE_POLL_REQUEST
  }
}

export function downloadPollSubmissionsRequest (body) {
  return {
    body,
    type: DOWNLOAD_POLL_SUBMISSIONS_REQUEST
  }
}

export function getPollRequest ({ clientURL, pollURL }) {
  return {
    clientURL,
    pollURL,
    type: GET_POLL_REQUEST
  }
}

export function getPollListRequest ({ clientURL }) {
  return {
    clientURL,
    type: GET_POLL_LIST_REQUEST
  }
}

export function removePollRequest (body) {
  return {
    body,
    type: REMOVE_POLL_REQUEST
  }
}

export function removePollImageRequest (body) {
  return {
    body,
    type: REMOVE_POLL_IMAGE_REQUEST
  }
}

export function removePollVoteRequest (body) {
  return {
    body,
    type: REMOVE_POLL_VOTE_REQUEST
  }
}

export function resetPollURLChanged () {
  return {
    type: RESET_POLL_URL_CHANGED
  }
}

export function updatePollNameRequest (body) {
  return {
    body,
    type: UPDATE_POLL_NAME_REQUEST
  }
}

export function updatePollQuestionsRequest (body) {
  return {
    body,
    type: UPDATE_POLL_QUESTIONS_REQUEST
  }
}

export function updatePollVotingTimeframeRequest (body) {
  return {
    body,
    type: UPDATE_POLL_VOTING_TIMEFRAME_REQUEST
  }
}

export function uploadPollImageRequest (body) {
  return {
    body,
    type: UPLOAD_POLL_IMAGE_REQUEST
  }
}
