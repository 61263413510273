import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { getEventRequest, updateEventRegistrationQuestionsRequest } from 'src/redux/actions/events';
import FormGenerator from 'src/components/TemplateForms/Common/FormGenerator';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const EventRegistrationQuestions = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const isRequesting = useSelector(state => state.loadingStore.UPDATE_EVENT_REGISTRATION_QUESTIONS);
  const { eventURL } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventRegistrationQuestions', client, event }));
    dispatch(updateDrawerContent({
      fetching: eventFetching,
      key: 'eventRegistrationQuestions',
      options: { client, event } 
    }));
  }, [eventFetching]);

  const onSubmit = ({ formState }) => {
    dispatch(updateEventRegistrationQuestionsRequest({ clientURL: client.url, eventURL: event.url, form: {
      registrationQuestions: formState }
    }));
  }

  return (
    <section className="box">
      <div className="box-title">
        <h3>
            {
              !eventFetching && (
                <span>Optional Registration Questions</span>
              )
            }
            {
              eventFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        !eventFetching && (
          <div className="box-content registration-questions">
            <FormGenerator
              form={event?.registrationQuestions}
              isRequesting={isRequesting}
              onSubmit={onSubmit}
            />
          </div>
        )
      }
    </section>
  )
}

export default EventRegistrationQuestions;
