import React, { useEffect, useState } from 'react';
import Chart from 'src/components/Chart';
import { useDispatch, useSelector } from 'react-redux';
import { getPollRequest } from 'src/redux/actions/polls';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { formFieldTypes } from 'src/constants';

const PollVotingResults = () => {
  const client = useSelector(state => state.clientStore?.client);
  const poll = useSelector(state => state.pollStore?.poll);
  const pollFetching = useSelector(state => state.loadingStore.GET_POLL);
  const { pollURL } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [results, setResults] = useState();

  if (!pollURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!poll) {
      dispatch(getPollRequest({ clientURL: client.url, pollURL }));
    }
  }, []);

  useEffect(() => {
    if (poll?.pollID) {
      const map = createDefaultMap();
      (poll.votes || []).forEach(vote => {
        for (const key in vote.ballot) {
          const item = map.get(key);
          const type = item?.type;
          switch (type) {
            case formFieldTypes.input:
              item.values = [...item.values, vote.ballot[key]];
              break;
            case formFieldTypes.textarea:
              item.values = [...item.values, vote.ballot[key]];
              break;
            case formFieldTypes.checkbox: 
              if (!!vote.ballot[key]) {
                item.yes = item.yes + 1;
              } else {
                item.no = item.no + 1;
              }
              break;
            case formFieldTypes.radio:
              item.values.set(vote.ballot[key], item.values.get(vote.ballot[key]) + 1);
              break;
            case formFieldTypes.multiSelect:
              item.value = item.value + 1;
              break;
            default:
              break;
          }
        }
      });
      setResults(map);
    }
  }, [poll?.pollID]);

  const createDefaultMap = () => {
    const map = new Map();
    let optionMap;
    poll.questions.forEach(question => {
      switch(question.type) {
        case formFieldTypes.input:
          map.set(question.questionID, {
            type: question.type,
            values: []
          });
          break;
        case formFieldTypes.textarea:
          map.set(question.questionID, {
            type: question.type,
            values: []
          });
          break;
        case formFieldTypes.checkbox:
          map.set(question.questionID, {
            type: question.type,
            yes: 0,
            no: 0
          });
          break;
        case formFieldTypes.multiSelect:
          (question.options || []).forEach(option => {
            map.set(option.optionID, {
              type: question.type,
              value: 0
            });
          });
          break;
        case formFieldTypes.radio:
          optionMap = new Map();
          (question.options || []).forEach(option => {
            optionMap.set(option.data, 0);
          });
          map.set(question.questionID, {
            type: question.type,
            values: optionMap
          });
          break;
        default: break;
      }
    });

    return map;
  }

  const renderResult = ({ question }) => {
    switch(question.type) {
      case formFieldTypes.image:
        return (<img className="poll-voting-results-question-image" src={question.image.url} alt="" />);
      case formFieldTypes.heading: 
        return (<div className="poll-voting-results-question-title bold">{question.formElements.data}</div>);
      case formFieldTypes.paragraph: 
        return (<div className="poll-voting-results-question-title">{question.formElements.data}</div>);
      case formFieldTypes.input: 
        return (<>
          <div className="poll-voting-results-question-title">{question.formElements.data}</div>
          <div className="poll-voting-results-question-values-list">
            {
              results?.get(question.questionID).values.map((value, index) =>
                <div
                  className="poll-voting-results-question-values-list-item"
                  key={index}
                >{value}</div>
              )
            }
          </div>
        </>);
      case formFieldTypes.textarea: 
        return (<>
          <div className="poll-voting-results-question-title">{question.formElements.data}</div>
          <div className="poll-voting-results-question-values-list">
            {
              results?.get(question.questionID).values.map((value, index) =>
                <div
                  className="poll-voting-results-question-values-list-item"
                  key={index}
                >{value}</div>
              )
            }
          </div>
        </>);
      case formFieldTypes.checkbox: 
        return (<>
          <div className="poll-voting-results-question-title">{question.formElements.data}</div>
          <div className="poll-voting-results-question-values-list">
            <Chart
              data={[{ 
                name: 'Yes',
                value: results?.get(question.questionID).yes
              }, {
                name: 'No',
                value: results?.get(question.questionID).no
              }] }
            />
          </div>
        </>);
      case formFieldTypes.radio: 
        return (<>
          <div className="poll-voting-results-question-title">{question.formElements.data}</div>
          <div className="poll-voting-results-question-values-list">
            <Chart
              data={question.options.map(option => {
                return {
                  name: option.data,
                  value: results?.get(question.questionID).values.get(option.data)
                }
              })}
            />
          </div>
        </>);
      case formFieldTypes.multiSelect: 
        return (<>
          <div className="poll-voting-results-question-title">{question.formElements.data}</div>
          <div className="poll-voting-results-question-values-list">
            <Chart
              data={question.options.map(option => {
                return {
                  name: option.data,
                  value: results?.get(option.optionID).value
                }
              })}
            />
          </div>
        </>);
      default: return;
    }
  }

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'pollResults', client, poll }));
    dispatch(updateDrawerContent({
      fetching: pollFetching,
      key: 'pollResults',
      options: { client, poll } 
    }));
  }, [pollFetching]);

  return (
    <section className="poll-voting-results box">
      <div className="box-title">
        Voting Summary
      </div>
      <div className="poll-voting-results-content box-content">
        { results && 
          (poll?.questions || [])
            .map(question => 
            <div
              className="poll-voting-results-question"
              key={question.questionID}
            >
              {renderResult({ question })}
            </div>
          )
        }
      </div>
    </section>
  )
}

export default PollVotingResults;
