import { call, put, takeEvery } from 'redux-saga/effects';
import handleFetch from 'src/utilities/fetch';
import * as actions from 'src/redux/actions/polls';
import * as userActions from 'src/redux/actions/users';
import { toast } from 'react-toastify';

async function createPoll({ clientURL, form }) {
  return await handleFetch({ url: `/${clientURL}/polls`, form });
}

async function downloadPollSubmissions({ clientURL, pollURL, pollID }) {
  return await handleFetch({ url: `/${clientURL}/polls/${pollURL}/download-submissions`,
    download: `poll_${pollID}_submissions.xlsx`
  });
}

async function getPollList({ clientURL }) {
  return await handleFetch({ url: `/${clientURL}/polls/edit` });
}

async function getPoll({ clientURL, pollURL }) {
  return await handleFetch({ url: `/${clientURL}/polls/${pollURL}/edit` });
}

async function removePoll({ clientURL, pollURL }) {
  return await handleFetch({ url: `/${clientURL}/polls/${pollURL}/remove`, form: {} });
}

async function removePollImage({ clientURL, pollURL, form }) {
  return await handleFetch({ url: `/${clientURL}/polls/${pollURL}/images/remove`, form });
}

async function removePollVote({ clientURL, pollURL, form }) {
  return await handleFetch({ url: `/${clientURL}/polls/${pollURL}/vote/remove`, form });
}

async function updatePollName({ clientURL, pollURL, form }) {
  return await handleFetch({ url: `/${clientURL}/polls/${pollURL}/info`, form });
}

async function updatePollQuestions({ clientURL, pollURL, form }) {
  return await handleFetch({ url: `/${clientURL}/polls/${pollURL}/structure`, form });
}

async function updatePollVotingTimeframe({ clientURL, pollURL, form }) {
  return await handleFetch({ url: `/${clientURL}/polls/${pollURL}/dates`, form });
}

async function uploadPollImage({ clientURL, pollURL, file, handleProgress }) {
  return await handleFetch({ url: `/${clientURL}/polls/${pollURL}/images`, form: { file }, handleProgress });
}

function* createPollRequest(request) {
  try {
    const context = yield call(() => createPoll(request.body));
    yield put({ type: actions.CREATE_POLL_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CREATE_POLL_FAILED, error });
  }
}

function* downloadPollSubmissionsRequest(request) {
  try {
    const context = yield call(() => downloadPollSubmissions(request.body));
    yield put({ type: actions.DOWNLOAD_POLL_SUBMISSIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DOWNLOAD_POLL_SUBMISSIONS_FAILED, error });
  }
}

function* getPollRequest(request) {
  try {
    const context = yield call(() => getPoll(request));
    yield put({ type: actions.GET_POLL_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_POLL_FAILED, error });
  }
}

function* getPollListRequest(request) {
  try {
    const context = yield call(() => getPollList(request));
    yield put({ type: actions.GET_POLL_LIST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_POLL_LIST_FAILED, error });
  }
}

function* removePollRequest(request) {
  try {
    const context = yield call(() => removePoll(request.body));
    yield put({ type: actions.REMOVE_POLL_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_POLL_FAILED, error });
  }
}

function* removePollImageRequest(request) {
  try {
    const context = yield call(() => removePollImage(request.body));
    yield put({ type: actions.REMOVE_POLL_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_POLL_IMAGE_FAILED, error });
  }
}

function* removePollVoteRequest(request) {
  try {
    const context = yield call(() => removePollVote(request.body));
    yield put({ type: actions.REMOVE_POLL_VOTE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_POLL_VOTE_FAILED, error });
  }
}

function* resetPollURLChanged() {
  yield put({ type: actions.RESET_POLL_URL_CHANGED_SUCCESS });
}

function* updatePollNameRequest(request) {
  try {
    const context = yield call(() => updatePollName(request.body));
    yield put({ type: actions.UPDATE_POLL_NAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_POLL_NAME_FAILED, error });
  }
}

function* updatePollQuestionsRequest(request) {
  try {
    const context = yield call(() => updatePollQuestions(request.body));
    yield put({ type: actions.UPDATE_POLL_QUESTIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_POLL_QUESTIONS_FAILED, error });
  }
}

function* updatePollVotingTimeframeRequest(request) {
  try {
    const context = yield call(() => updatePollVotingTimeframe(request.body));
    yield put({ type: actions.UPDATE_POLL_VOTING_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_POLL_VOTING_TIMEFRAME_FAILED, error });
  }
}

function* uploadPollImageRequest(request) {
  try {
    const context = yield call(() => uploadPollImage(request.body));
    yield put({ type: actions.UPLOAD_POLL_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPLOAD_POLL_IMAGE_FAILED, error });
  }
}

function* apiFailed(request) {
  toast.error(request.error.message);
  if (request.error.status === 403) {
    yield put({ type: userActions.CLEAR_USER });
  }
}

function* pollSagas() {
  yield takeEvery(actions.CREATE_POLL_REQUEST, createPollRequest);
  yield takeEvery(actions.DOWNLOAD_POLL_SUBMISSIONS_REQUEST, downloadPollSubmissionsRequest);
  yield takeEvery(actions.GET_POLL_REQUEST, getPollRequest);
  yield takeEvery(actions.GET_POLL_LIST_REQUEST, getPollListRequest);
  yield takeEvery(actions.REMOVE_POLL_REQUEST, removePollRequest);
  yield takeEvery(actions.REMOVE_POLL_IMAGE_REQUEST, removePollImageRequest);
  yield takeEvery(actions.REMOVE_POLL_VOTE_REQUEST, removePollVoteRequest);
  yield takeEvery(actions.RESET_POLL_URL_CHANGED, resetPollURLChanged);
  yield takeEvery(actions.UPDATE_POLL_NAME_REQUEST, updatePollNameRequest);
  yield takeEvery(actions.UPDATE_POLL_QUESTIONS_REQUEST, updatePollQuestionsRequest);
  yield takeEvery(actions.UPDATE_POLL_VOTING_TIMEFRAME_REQUEST, updatePollVotingTimeframeRequest);
  yield takeEvery(actions.UPLOAD_POLL_IMAGE_REQUEST, uploadPollImageRequest);

  yield takeEvery(actions.CREATE_POLL_FAILED, apiFailed);
  yield takeEvery(actions.DOWNLOAD_POLL_SUBMISSIONS_FAILED, apiFailed);
  yield takeEvery(actions.GET_POLL_FAILED, apiFailed);
  yield takeEvery(actions.GET_POLL_LIST_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_POLL_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_POLL_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_POLL_VOTE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_POLL_NAME_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_POLL_QUESTIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_POLL_VOTING_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.UPLOAD_POLL_IMAGE_FAILED, apiFailed);
}

export default pollSagas;
