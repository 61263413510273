import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  clearScoringProgressRequest,
  clearScoringProgressEntriesRequest,
  clearScoringProgressJudgesRequest,
  getScoringProgressByEntryRequest,
  getScoringProgressByJudgeRequest,
  getScoringProgressForEntryRequest,
  getScoringProgressEntriesForJudgeRequest,
  getScoringProgressJudgesForEntryRequest
} from 'src/redux/actions/contests';
import ResourceList from 'src/components/ResourceList';
import UserProfile from 'src/components/UserProfile';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import { openModal } from 'src/redux/actions/modal';
import { Button, MenuItem, Select } from '@mui/material';
import createLoadingSelector from 'src/redux/loading';

const ContestScoringProgress = ({ fetchingProgress }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const [viewBy, setViewBy] = useState('judge');
  const [selectedEntry, setSelectedEntry] = useState();
  const [selectedJudge, setSelectedJudge] = useState();
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewByChange = ({ value }) => {
    setViewBy(value);
  }

  const viewByOptions = [{
    label: 'Judge',
    value: 'judge'
  }, {
    label: 'Entry',
    value: 'entry'
  }];

  if (!contestURL) {
    navigate('/');
  }

  const filters = {
    options: {
      complete: {
        fn: item => !!item.availableCount && item.availableCount === item.scoredCount,
        label: 'Complete'
      },
      incomplete: {
        fn: item => !!item.availableCount && item.availableCount !== item.scoredCount,
        label: 'Incomplete'
      },
      unassigned: {
        fn: item => !item.availableCount,
        label: 'Unassigned'
      }
    }
  };

  const scoredFilters = {
    options: {
      scored: {
        fn: item => !!item.isScored,
        label: 'Scored'
      },
      notScored: {
        fn: item => !item.isScored,
        label: 'Not Scored'
      }
    }
  };

  const handleSelectEntry = (item) => {
    if (selectedJudge) {
      if (!item.isScored) {
        return;
      }
      
      dispatch(getScoringProgressForEntryRequest({
        clientURL: client.url,
        contestURL: contest.url,
        userID: selectedJudge.userID,
        entryID: item.entryID
      }));

      return;
    }

    setSelectedEntry(item);
  }

  const handleSelectJudge = (item) => {
    if (selectedEntry) {
      if (!item.isScored) {
        return;
      }

      dispatch(getScoringProgressForEntryRequest({
        clientURL: client.url,
        contestURL: contest.url,
        userID: item.userID,
        entryID: selectedEntry.entryID
      }));

      return;
    }

    setSelectedJudge(item);
  }

  const itemDisabled = (item) => {
    return item?.isScored === false || item?.availableCount === 0;
  }

  useEffect(() => {
    if (contest) {
      if (viewBy === 'entry') {
        dispatch(getScoringProgressByEntryRequest({ clientURL: client.url, contestURL: contest.url }));
        setSelectedJudge();
      }
      if (viewBy === 'judge') {
        dispatch(getScoringProgressByJudgeRequest({ clientURL: client.url, contestURL: contest.url }));
        setSelectedEntry();
      }
    }
  }, [viewBy, contest?.url]);

  useEffect(() => {
    if (contest?.scoringProgress) {
      dispatch(openModal({
        props: { scoringProgress: contest?.scoringProgress },
        key: 'viewScoringProgress'
      }))
    }
    
  }, [contest?.scoringProgress]);

  useEffect(() => {
    return () => {
      dispatch(clearScoringProgressRequest());
    }
  }, []);

  useEffect(() => {
    if (selectedEntry) {
      dispatch(getScoringProgressJudgesForEntryRequest({
        clientURL: client.url,
        contestURL: contest.url,
        entryID: selectedEntry.entryID
      }));

      return;
    }

    if (selectedJudge) {
      dispatch(getScoringProgressEntriesForJudgeRequest({
        clientURL: client.url,
        contestURL: contest.url,
        userID: selectedJudge.userID
      }));
    }
  }, [selectedEntry, selectedJudge]);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestScoringProgress', client, contest }));
    dispatch(updateDrawerContent({
      fetching: contestFetching,
      key: 'contestScoringProgress',
      options: { client, contest } 
    }));
  }, [contestFetching]);

  return (
    <section className="scoring-progress grid">
      <section className="box">
        <div className="box-title">
          <h3>
              {
                !contestFetching && (
                  <span>Scoring Progress Options
                  {
                    fetchingProgress && (
                      <LoadingCircle variant="dark" />
                    )
                  }
                  </span>
                )
              }
              {
                contestFetching && (
                  <LoadingCircle variant="dark" />
                )
              }
            </h3>
        </div>
        {
          !contestFetching && (
          <div className="box-content options">
            <div>
              <label>View Progress By:</label>
              <Select 
                onChange={event => handleViewByChange({ value: event.target.value })}
                value={viewBy}
              >{
                viewByOptions?.map(option => {
                  return (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >{option.label}</MenuItem>
                  )
                })
              }</Select>
            </div>
            <div className="selected-items">
            {
              selectedEntry && (
                <Button
                  onClick={() => {
                    setSelectedEntry();
                    dispatch(clearScoringProgressJudgesRequest());
                  }}
                  size="small"
                  variant="contained"
                >Clear Selected Entry</Button>
              )
            }
            {
              selectedJudge && (
                <Button
                  onClick={() => {
                    setSelectedJudge();
                    dispatch(clearScoringProgressEntriesRequest());
                  }}
                  size="small"
                  variant="contained"
                >Clear Selected Judge</Button>
              )
            }
            </div>
          </div>
          )
        }
      </section>
      {
        ((contest?.scoringProgressEntries || []).length > 0 && !selectedEntry) && (
          <ResourceList
            filters={selectedJudge ? scoredFilters : filters}
            itemDisabled={itemDisabled}
            list={contest?.scoringProgressEntries}
            notSelectable
            onClickFn={handleSelectEntry}
            renderItem={entry => 
              <div className={`progress-item${
                !!selectedJudge ? !!entry.isScored ? ' complete' : ' error' : !entry.scoredCount ? ' error' : entry.scoredCount === entry.availableCount ? ' complete' : ' incomplete'}
              `}>
                <div className="progress-item-label">
                  {entry.label}-{entry.number}
                </div>
                <div className="progress-item-user">
                  <UserProfile
                    showEmail
                    user={entry}
                  />
                </div>
                <div className="progress-item-count">
                  {
                    !!selectedJudge && (
                      <span className="emphasize">{ !entry.isScored && (<>Not </>) }Scored</span>
                    )
                  }
                  {
                    !selectedJudge && (
                      <>
                        <span>Scored By</span>
                        <span
                          className="emphasize"
                        >{entry.scoredCount} of {entry.availableCount}</span>
                      </>
                    )
                  }
                </div>
              </div>}
            renderKey="entryID"
            title={`Scoring Progress Entry List${selectedJudge ? ` For ${selectedJudge.fullName}` : ''}`}
          />
        )
      }
      {
        ((contest?.scoringProgressJudges || []).length > 0 && !selectedJudge) && (
          <ResourceList
            filters={selectedEntry ? scoredFilters : filters}
            itemDisabled={itemDisabled}
            list={contest?.scoringProgressJudges}
            notSelectable
            onClickFn={handleSelectJudge}
            renderItem={judge => 
              <div className={`progress-item${
                !!selectedEntry ? !!judge.isScored ? ' complete' : ' error' : !judge.scoredCount ? ' error' : judge.scoredCount === judge.availableCount ? ' complete' : ' incomplete'}`}>
                <div className="progress-item-user">
                  <UserProfile
                    showEmail
                    user={judge}
                  />
                </div>
                <div className="progress-item-count">
                  {
                    !!selectedEntry && (
                      <span className="emphasize">{ !judge.isScored && (<>Not </>) }Scored</span>
                    )
                  }
                  {
                    !selectedEntry && (
                      <>
                        <span>Scored</span>
                        <span
                          className="emphasize"
                        >{judge.scoredCount} of {judge.availableCount}</span>
                      </>
                    )
                  }
                </div>
              </div>}
            renderKey="userID"
            title={`Scoring Progress Judge List${selectedEntry ? ` For Entry ${selectedEntry.label}-${selectedEntry.number}` : ''}`}
          />
        )
      }
    </section>
  )
}

const loadingSelector = createLoadingSelector([
  'GET_SCORING_PROGRESS_BY_ENTRY',
  'GET_SCORING_PROGRESS_BY_JUDGE',
  'GET_SCORING_PROGRESS_FOR_ENTRY',
  'GET_SCORING_PROGRESS_ENTRIES_FOR_JUDGE',
  'GET_SCORING_PROGRESS_JUDGES_FOR_ENTRY'
]);
const mapStateToProps = (state) => ({ fetchingProgress: loadingSelector(state) });
export default connect(mapStateToProps)(ContestScoringProgress);
