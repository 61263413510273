import React, { useEffect, useState } from 'react';

const Chart = ({ data }) => {
  const [maxXAxis, setMaxXAxis] = useState();
  const [xAxisValues, setXAxisValues] = useState();

  const getMaxXAxis = ({ data }) => {
    if (!data?.length) {
      return 1;
    }

    const maxValue = Math.max(...data.map(item => item.value));
    return Math.max((Math.ceil(maxValue / 5) * 5), 1, maxValue);
  }

  const getXAxisValues = ({ max }) => {
    if (max === 1) {
      return [0, 1];
    }

    if (max === 2) {
      return [0, 1, 2];
    }

    const values = [0];
    for (let i = 0; i < max; i++) {
      values.push(parseInt((((i+1)*5)/max)));
    }
    return values;
  }

  useEffect(() => {
    setMaxXAxis(getMaxXAxis({ data }));
  }, []);

  useEffect(() => {
    if (maxXAxis) {
      setXAxisValues(getXAxisValues({ max: maxXAxis }));
    }
  }, [maxXAxis]);

  return (
    <section className="chart">
      <div className="chart-rows">
        {
          (data || []).map((item, index) => 
            <div
              className="chart-row"
              key={index}
            >
              <div className="chart-row-progress" style={{width: `${(item.value / maxXAxis) * 100}%` }}/>
              {item.name} ({item.value})
            </div>
          )
        }
      </div>
      <div className="chart-x-axis">
        {
          (xAxisValues || []).map((value, index) => 
            <div
              className="chart-x-axis-value"
              key={index}
            >{value}</div>
          )
        }
      </div>
    </section>
  )
}

export default Chart;
