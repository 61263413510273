import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventRequest,
  reorderEventSlideshowImagesRequest,
  uploadEventSlideshowImageRequest
} from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import { fileRestrictions } from 'src/constants';

const EventSlideshowImages = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const [progress, setProgress] = useState();
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  const actions = [{
    fileUpload: {
      accept: fileRestrictions.jpg.accept,
      label: 'Upload',
      multiple: true,
      onChange: file => {
        dispatch(uploadEventSlideshowImageRequest({ clientURL: client.url, eventURL: event.url, file, handleProgress: setProgress }));
      },
      requestPrefix: 'UPLOAD_EVENT_SLIDESHOW_IMAGE'
    }
  }, {
    label: 'View',
    requiresSelection: true,
    variant: 'contained',
    fn: image => {
      dispatch(
        openModal({
          props: { client, event, image },
          key: 'viewImage'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: slideshowImage => {
      dispatch(
        openDialog({
          props: { client, event, slideshowImage },
          key: 'removeEventSlideshowImage'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventSlideshowImages', client, event }));
    dispatch(updateDrawerContent({
      fetching: eventFetching,
      key: 'eventSlideshowImages',
      options: { client, event } 
    }));
  }, [eventFetching]);

  const reorderSlideshowImagesList = ({ list }) => {
    dispatch(reorderEventSlideshowImagesRequest({ clientURL: client.url, eventURL: event.url, form: { slideshowImages: list } }));
  }
  
  return (
    <ResourceList
      actions={actions}
      draggable
      fetching={eventFetching}
      handleDrag={reorderSlideshowImagesList}
      list={event?.slideshowImages}
      renderItem={item => 
        <div className="slideshow-image">
          <img src={item.url} alt={item.name}/>
          <div className="slideshow-image-details">
            <span className="slideshow-image-details-name">{item.name}</span>
            <span className="slideshow-image-details-size">{item.size}</span>
          </div>
        </div>
      }
      renderKey="name"
      progress={(!!progress && progress < 100) && progress}
      title="Slideshow Images"
    />
  )
}

export default EventSlideshowImages;