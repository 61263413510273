import React, { useState } from 'react';
import { TextField } from '@mui/material';

const FormStructureInputField = ({
  displayParagraph,
  disabled,
  error,
  handleBlur,
  handleChange,
  item,
  placeholder,
  sx,
  type,
  value
}) => {
  const [touched, setTouched] = useState(false);
  const handleTouched = () => {
    setTouched(true);
  };

  return (
    <TextField
      disabled={disabled}
      error={!!(touched && error)}
      helperText={touched && error ? error : null}
      inputProps={{
        autoComplete: 'off'
      }}
      maxRows={displayParagraph ? 8 : undefined}
      minRows={displayParagraph ? 2 : undefined}
      multiline={displayParagraph}
      onChange={e => {handleChange(e.target.value, item)}}
      onBlur={handleBlur}
      onFocus={handleTouched}
      placeholder={placeholder}
      required
      sx={sx}
      type={type || "text"}
      value={value || ''}
    />
  )
};

export default FormStructureInputField;
