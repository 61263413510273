import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getParadeRequest } from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openModal } from 'src/redux/actions/modal';
import { MenuItem, Select } from '@mui/material';
import sortBy from 'src/utilities/sort';

const ParadeAttendance = () => {
  const number = 'number';
  const lowest = 'lowest';
  const highest = 'highest';
  const total = 'total';

  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const [tickets, setTickets] = useState([]);
  const [sortedTickets, setSortedTickets] = useState();
  const [sortingBy, setSortingBy] = useState(number);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sortOptions = [{
    label: 'Ticket Number',
    value: number
  }, {
    label: 'Highest Attendance',
    value: highest
  }, {
    label: 'Lowest Attendance',
    value: lowest
  }];

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);
  
  useEffect(() => {
    if (parade?.purchasedTickets && !sortedTickets) {
      const tickets = (parade?.purchasedTickets || []).map(ticket => {
        return {
          ...ticket,
          total: (parade?.properties || []).reduce((acc, item) => {
            return !!(ticket.attendance || {})[item.propertyID] ? acc + 1 : acc;
          }, 0)
        };
      });
      setTickets(tickets);
      setSortedTickets(sortBy({ key: number, list: tickets }));
    }
  }, [parade?.purchasedTickets]);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeAttendance', client, parade }));
    dispatch(updateDrawerContent({
      fetching: paradeFetching,
      key: 'paradeAttendance',
      options: { client, parade } 
    }));
  }, [paradeFetching]);

  const handleSortChange = ({ value }) => {
    setSortingBy(value);
    let sortMethod;
    let reverse;

    switch(value) {
      case highest: sortMethod = total; reverse = true; break;
      case lowest: sortMethod = total; break;
      default: sortMethod = number;
    }

    setSortedTickets(sortBy({ key: sortMethod, list: tickets, reverse }));
  }

  const handleClick = (ticket) => {
    dispatch(openModal({
      props: { properties: parade?.properties, ticket },
      key: 'viewParadeAttendance'
    }))
  }

  return (
    <section className="attendance grid">
      <section className="box">
        <h3 className="box-title">Parade Attendance Summary</h3>
        <div className="box-content">
          <div>
            <label>Sort By:</label>
            <Select 
              onChange={event => handleSortChange({ value: event.target.value })}
              value={sortingBy}
            >{
              (sortOptions || []).map(option => {
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >{option.label}</MenuItem>
                )
              })
            }</Select>
          </div>
        </div>
      </section>
      <section className="box">
        <h3 className="box-title">Tickets</h3>
        <div className="box-content">
          <div className="attendance-list">
            { (sortedTickets || []).map(ticket => <div
              className={`attendance-list-item parade${ticket.total === (parade?.properties || []).length ? ' complete' : ''}${ticket.total === 0 ? ' error' : ''}`}
              onClick={() => handleClick(ticket)}
            >
              <div>Ticket <b>#{ticket.number}</b> : {ticket.email}</div>
              <div><b>{ticket.total} / {(parade?.properties || []).length}</b> Properties Attended</div>
            </div>)
            }
          </div>
        </div>
      </section>
    </section>
  )
}

export default ParadeAttendance;
