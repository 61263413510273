import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  reorderContestEntryFormsRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';

const ContestEntryForms = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const updatingEntryForms = useSelector(state => state.loadingStore.REORDER_CONTEST_ENTRY_FORMS);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, contest },
          key: 'createContestEntryForm'
        })
      )
    }
  }, {
    label: 'Duplicate',
    requiresSelection: true,
    fn: entryForm => {
      dispatch(
        openModal({
          props: { client, contest, entryForm },
          key: 'duplicateEntryForm'
        })
      )
    }
  }, {
    label: 'Settings',
    requiresSelection: true,
    fn: entryForm => {
      dispatch(
        openModal({
          props: { client, contest, entryForm },
          key: 'editContestEntryForm'
        })
      )
    }
  }, {
    label: 'Structure',
    requiresSelection: true,
    fn: entryForm => {
      dispatch(
        openModal({
          props: { client, contest, entryForm },
          key: 'editContestEntryFormStructure'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: entryForm => {
      dispatch(
        openDialog({
          props: { client, contest, entryForm },
          key: 'removeContestEntryForm'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestEntryForms', client, contest }));
    dispatch(updateDrawerContent({
      fetching: contestFetching,
      key: 'contestEntryForms',
      options: { client, contest } 
    }));
  }, [contestFetching]);

  const reorderEntryFormList = ({ list }) => {
    dispatch(reorderContestEntryFormsRequest({ clientURL: client.url, contestURL: contest.url, form: { entryForms: list } }));
  }
  
  return (
    <ResourceList
      actions={actions}
      draggable
      fetching={contestFetching}
      handleDrag={reorderEntryFormList}
      list={contest?.entryForms}
      renderItem={entryForm => 
        <div className="entry-form">
          <div className="entry-form-name">{entryForm.name}</div>
        </div>
      }
      renderKey="name"
      title="Entry Form List"
      updating={updatingEntryForms}
    />
  )
}

export default ContestEntryForms;