import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox } from '@mui/material';
import LoadingCircles from 'src/components/Elements/LoadingCircles';
import { createContestArchiveRequest } from 'src/redux/actions/contests';

const ContestArchiveForm = ({ archive, handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const isCreating = useSelector(state => state.loadingStore?.CREATE_CONTEST_ARCHIVE);
  const [entryMap, setEntryMap] = useState();
  const [formState, setFormState] = useState();
  const [list, setList] = useState();
  const [filesOnly, setFilesOnly] = useState();
  const dispatch = useDispatch();

  const handleBlur = () => {}

  const handleChange = (checked, entryID) => {
    setFormState({
      ...formState,
      [entryID]: checked
    });
  }

  const setItems = () => {
    const items = (contest?.archiveCategoryList || [])?.map(category => {
      const entries = category?.entries?.filter(entry => filesOnly ?
        (!entry.disqualified && !!entry.complete) : true);

      return {
        ...category,
        entries
      };
    }).filter(category => category.entries?.length > 0);

    return items;
  }

  useEffect(() => {
    const map = new Map();
    (contest?.archiveCategoryList || []).forEach(category => {
      (category.entries || []).forEach(entry => {
        map.set(entry.entryID, { ...entry, categoryID: category.categoryID });
      });
    });
    setEntryMap(map);
  }, [contest?.archiveCategoryList]);

  useEffect(() => {
    setList(setItems());
  }, [filesOnly])

  useEffect(() => {
    const state = {};
    if (archive?.payload) {
      (archive?.payload || []).forEach(item => state[item] = true);
    }
    setFormState(state);
  }, [archive?.payload])

  const handleSubmit = () => {
    const entries = Object.keys(formState).map(entryID => entryMap.get(entryID));

    dispatch(createContestArchiveRequest({ clientURL: client.url, contestURL: contest.url, form: { entries, filesOnly }}));
  }

  const toggleCheckAll = (event) => {
    const state = {};
    (list || []).forEach(category => {
      (category.entries || []).forEach(entry => {
        state[entry.entryID] = !!event.target.checked;
      });
    });
    setFormState(state);
  }

  const toggleCheckWinners = (event) => {
    const state = {};
    (list || []).forEach(category => {
      (category.entries || []).forEach(entry => {
        if (!!(entry.awards || [])?.length) {
          state[entry.entryID] = !!event.target.checked;
        }
      });
    });
    setFormState(state);
  }

  const toggleCheckFilesOnly = (event) => {
    setFilesOnly(!!event.target.checked);
  }

  return (
    <section className={`archive-form${archive?.archiveID ? ' disabled' : ''}`}>
      <div className="archive-form-options">
        <div className="archive-form-options-item">
          <label>
            <Checkbox
              disabled={archive?.archiveID}
              name="winners"
              onBlur={handleBlur}
              onChange={toggleCheckWinners}
            />Select Winners Only
          </label>
        </div>
        <div className="archive-form-options-item">
          <label>
            <Checkbox
              disabled={archive?.archiveID}
              name="all"
              onBlur={handleBlur}
              onChange={toggleCheckAll}
            />Select All Entries
          </label>
        </div>
        <div className="archive-form-options-item">
          <label>
            <Checkbox
              disabled={archive?.archiveID}
              name="filesOnly"
              onBlur={handleBlur}
              onChange={toggleCheckFilesOnly}
            />Download Files Only
          </label>
        </div>
      </div>
      {
        (list || []).map(category => {
          return (
            <div
              className="archive-form-category"
              key={category.categoryID}
            >
              <div className="archive-form-category-title">
                {category.label}: {category.description}
              </div>
              {
                (category.entries || []).map(entry => {
                  return (
                    <div
                      className={`archive-form-category-entry${!entry.complete ? ' incomplete' : ''}${entry.disqualified ? ' disqualified' : ''}`}
                      key={entry.entryID}
                      onClick={() => { !archive?.archiveID && handleChange(!formState[entry?.entryID], entry?.entryID) }}
                    >
                      <div className="archive-form-category-entry-number">
                        <Checkbox
                          checked={!!(formState || {})[entry?.entryID]}
                          disabled={!!archive?.archiveID}
                          name={entry?.entryID}
                          onBlur={handleBlur}
                          onChange={e => handleChange(e.target.checked, entry?.entryID)}
                        />
                        <span>{category?.label}-{entry?.number}</span>
                      </div>
                      <div className="archive-form-category-entry-users">
                        {
                          entry?.user?.optionalGalleryText || entry?.user?.fullName
                        }
                      </div>
                      <div className="archive-form-category-entry-awards">
                        {
                          (entry?.awards || [])?.map(award => 
                            <span key={award?.awardID}>{award?.name}</span>
                          ).reduce((acc, item) => acc === null ? [item] : [...acc, ', ', item], null)
                        }
                      </div>
                    </div> 
                  )
                })
              }
            </div>
          )
        })
      }
      {
        !archive?.archiveID && (
          <div className="archive-form-buttons">
            <Button
              disabled={isCreating}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
            >
              {isCreating && (
                <LoadingCircles />
              )}
              {!isCreating && (
                <>
                  Save
                </>
              )}
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
            >Cancel</Button>
          </div>
        )
      }
    </section>
  )
}

export default ContestArchiveForm;
