import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup
} from '@mui/material';

const ViewParadeAttendance = ({ properties, ticket }) => {
  return (
    <section className="view-parade-attendance">
      {
        (properties || []).map(property => {
          return (
            <FormControl
              className="form-field"
              key={property?.propertyID}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!(ticket.attendance || {})[property?.propertyID]}
                      disabled={true}
                    />
                  }
                  label={property?.address}
                />
              </FormGroup>
            </FormControl>
          )
        })
      }
    </section>
  )
}

export default ViewParadeAttendance;
