import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { createPollRequest } from 'src/redux/actions/polls';

const CreatePollForm = ({ handleClose }) => {
  const client = useSelector(state => state.clientStore?.client);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    dispatch(createPollRequest({ clientURL: client.url, form: event }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Survey Name',
          name: 'name',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: '',
          required: true
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="create-poll">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'CREATE_POLL'}
      />
    </section>
  )
}

export default CreatePollForm;
