import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getEventRequest,
  updateEventConfirmationEmailRequest
} from 'src/redux/actions/events';
import { fieldTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const EventConfirmationEmail = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventConfirmationEmail', client, event }));
    dispatch(updateDrawerContent({
      fetching: eventFetching,
      key: 'eventConfirmationEmail',
      options: { client, event } 
    }));
  }, [eventFetching]);

  const handleSubmit = (evt) => {
    dispatch(updateEventConfirmationEmailRequest({ clientURL: client.url, eventURL: event.url, form: evt }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Confirmation Email Text',
          name: 'confirmationEmailText',
          type: fieldTypes.textarea,
          value: event?.confirmationEmailText || ''
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="box">
      <div className="box-title">
        <h3>
            {
              !eventFetching && (
                <span>Confirmation Email Text</span>
              )
            }
            {
              eventFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!eventFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_EVENT_CONFIRMATION_EMAIL'}
          />
        </div>
        )
      }
    </section>
  )
}

export default EventConfirmationEmail;
