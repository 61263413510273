import { all, fork } from 'redux-saga/effects';
import appSaga from './app';
import breadcrumbSaga from './breadcrumbs';
import clientSaga from './client';
import contestSaga from './contests';
import dialogSaga from './dialog';
import drawerContentSaga from './drawer';
import eventSaga from './events';
import modalSaga from './modal';
import paradeSaga from './parades';
import pollSaga from './polls';
import searchSaga from './search';
import userSaga from './users';

function* combinedSagas () {
  yield all ([
    fork(appSaga),
    fork(breadcrumbSaga),
    fork(clientSaga),
    fork(contestSaga),
    fork(dialogSaga),
    fork(drawerContentSaga),
    fork(eventSaga),
    fork(modalSaga),
    fork(paradeSaga),
    fork(pollSaga),
    fork(searchSaga),
    fork(userSaga)
  ])
}

export default combinedSagas;
