import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventRequest } from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import AppCard from 'src/components/Elements/AppCard';

const Event = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    dispatch(getEventRequest({ clientURL: client.url, eventURL }));
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'event', client, event }));
    dispatch(updateDrawerContent({
      fetching: eventFetching,
      key: 'event',
      options: { client, event } 
    }));
  }, [event?.name, event?.url, eventFetching]);

  return (
    <section className="app-grid">
      {
        eventFetching && (
          <div className="app-grid-loading">
              <LoadingCircle variant="dark" />
          </div>
        )
      }
      {
        !eventFetching && (
          <>
            <div className="app-grid-row">
              <AppCard
                className="flex-1"
                title="Online Event Portal"
              >
                <div className="app-grid-title">
                  <img
                    className="app-grid-image-fullsize"
                    src={event?.bannerImage}
                    alt={event?.name}
                  />
                  <div className="app-grid-title-details">
                    <h1>
                      {event?.name}
                    </h1>
                  </div>
                </div>
              </AppCard>
              <div className="card-group">
                <AppCard
                  className="shrinkable"
                  title="Landing Page"
                >
                  <Link
                    className="item"
                    target="_blank"
                    to={`https://${client.url}.cpjam.com/${event?.url}`}
                  >
                    {`https://${client.url}.cpjam.com/${event?.url}`}
                  </Link>
                </AppCard>
                <AppCard
                  className="shrinkable"
                  title="Registration Page"
                >
                  <Link
                    className="item"
                    target="_blank"
                    to={`https://${client.url}.cpjam.com/${event?.url}/tickets`}
                  >
                    {`https://${client.url}.cpjam.com/${event?.url}/tickets`}
                  </Link>
                </AppCard>
                <AppCard
                  className="shrinkable"
                  title="Become A Partner Page"
                >
                  <Link
                    className="item"
                    target="_blank"
                    to={`https://${client?.url}.cpjam.com/${event?.url}/become-a-partner`}
                  >
                    {`https://${client?.url}.cpjam.com/${event?.url}/become-a-partner`}
                  </Link>
                </AppCard>
              </div>
            </div>
          </>
        )
      }
    </section>
  )
}

export default Event;
