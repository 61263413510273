import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getParadeRequest,
  updateParadeSupportingProductTimeframeRequest
} from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import { supportingProductTypes } from 'src/constants';

const ParadeTimeframes = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: {
            client,
            handleSubmit: (event) => updateParadeSupportingProductTimeframeRequest({ clientURL: client.url, paradeURL: parade.url, form: event }),
            items: (parade.sponsorTiers || []),
            parade,
            requestPrefix: 'UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME'
          },
          key: 'createParadeSponsorTimeframe'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: timeframe => {
      dispatch(
        openModal({
          props: {
            client,
            handleSubmit: (event) => updateParadeSupportingProductTimeframeRequest({ clientURL: client.url, paradeURL: parade.url, form: {...(timeframe || {}), ...event} }),
            items: (parade.sponsorTiers || []),
            parade,
            requestPrefix: 'UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME',
            timeframe
          },
          key: 'editParadeSponsorTimeframe'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: timeframe => {
      timeframe.type = supportingProductTypes.sponsor;
      
      dispatch(
        openDialog({
          props: { client, parade, timeframe },
          key: 'removeParadeSponsorTimeframe'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeTimeframes', client, parade }));
    dispatch(updateDrawerContent({
      fetching: paradeFetching,
      key: 'paradeTimeframes',
      options: { client, parade } 
    }));
  }, [paradeFetching]);
  
  return (
    <ResourceList
      actions={actions}
      fetching={paradeFetching}
      list={parade?.sponsorshipTimeframes}
      renderHeaders={() => 
        <div className="timeframe">
          <div className="timeframe-start">Pricing Timeframe Starts</div>
          <div className="timeframe-end">Pricing Timeframe Ends</div>
        </div>
      }
      renderItem={timeframe => 
        <div className="timeframe">
          <div className="timeframe-start">{timeframe.startDisplayDate}</div>
          <div className="timeframe-end">{timeframe.endDisplayDate}</div>
        </div>
      }
      renderKey="timeframeID"
      title="Sponsorship Pricing & Availability"
    />
  )
}

export default ParadeTimeframes;