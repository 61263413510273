import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  entryFormInputOptions,
  entryFormInputTypes,
  formFieldTypes,
  formFieldOptions
} from './types';
import StructureForm from 'src/components/TemplateForms/Common/StructureForm';
import DragDrop from 'src/components/DragDrop';
import FormStructureInputField from 'src/components/TemplateForms/Common/StructureForm/FormStructureInputField';
import FormStructureSelectField from 'src/components/TemplateForms/Common/StructureForm/FormStructureSelectField';
import FormStructureSliderField from 'src/components/TemplateForms/Common/StructureForm/FormStructureSliderField';
import { Button } from '@mui/material';
import { createResourceID } from 'src/utilities/strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCanXmark } from '@fortawesome/pro-light-svg-icons';
import { openDialog } from 'src/redux/actions/dialog';
import { cloneDeep } from 'lodash';
import sortBy from 'src/utilities/sort';
import { openModal } from 'src/redux/actions/modal';
import { updatePollQuestionsRequest } from 'src/redux/actions/polls';

const PollGenerator = () => {
  const client = useSelector(state => state.clientStore?.client);
  const poll = useSelector(state => state.pollStore?.poll);
  const isRequesting = useSelector(state => state.loadingStore?.UPDATE_POLL_QUESTIONS);
  const dispatch = useDispatch();
  const [formValid, setFormValid] = useState(true);
  const [formState, setFormState] = useState({});
  const [list, setList] = useState();

  useEffect(() => {
    const list = (poll?.questions?.length && poll?.questions) || [createDefaultQuestion()];
    const newState = createInitialState({ list });
    setFormState(newState);
    setList(list);
    errorCheck(newState);
  }, []);

  useEffect(() => {
    if (poll?.imageRemoved) {
      handleImageRemoved({ fileID: poll.imageRemoved });
    }
  }, [poll?.imageRemoved])

  const createInitialState = ({ list }) => {
    return list.reduce((acc, question, index) => {
      return {...acc, [question.questionID]: {
        options: (question.type === formFieldTypes.radio || question.type === formFieldTypes.multiSelect) ? 
          (question.options || []).reduce((acc, option, index) => {
            return { ...acc, [option.optionID]: { value: option.data, sequence: index } }
          }, {}) : undefined
        ,
        image: question.image,
        inputType: question.formElements?.inputType,
        required: question.formElements?.required,
        restriction: question.formElements?.restriction,
        restrictionCount: question.formElements?.restrictionCount,
        sequence: index,
        value: (question.formElements?.data === null || question.formElements?.data === undefined) ? '' : question.formElements?.data,
        type: question.type
      }}
    }, {});
  }

  const createOption = ({ item }) => {
    const optionID = createResourceID();

    const index = list.findIndex(question => question.questionID === item.questionID);
    const updatedItems = cloneDeep(list);
    updatedItems[index].options = [...(item.options || []), {
      data: '',
      optionID
    }];

    setList(updatedItems);

    const newState = cloneDeep(formState);
    newState[item.questionID].options = { ...newState[item.questionID].options, [optionID]: {
      sequence: (item.options || []).length || 0,
      value: ''
    }};
    setFormState(newState);
    errorCheck(newState);
  }

  const createButton = () => {
    return (
      <Button
        onClick={createQuestion}
        variant="contained"
      >Create Form Element</Button>
    )
  }

  const createDefaultQuestion = () => {
    return {
      formElements: {
        data: '',
        inputType: entryFormInputTypes.text,
        required: false,
      },
      questionID: createResourceID(),
      type: formFieldTypes.input,
      value: ''
    };
  }

  const createQuestion = () => {
    const newQuestion = createDefaultQuestion();
    setList([...list, newQuestion]);

    const newState = {...formState, [newQuestion.questionID]: {
      inputType: entryFormInputTypes.text,
      required: false,
      sequence: list?.length || 0,
      type: formFieldTypes.input,
      value: ''
    }}
    
    setFormState(newState);
    errorCheck(newState);
  }

  const errorCheck = (newState) => {
    let inValid = false;
    for (const question in newState) {
      if (newState[question].type === formFieldTypes.image && !newState[question].image) {
        inValid = true;
        break;
      }

      if (newState[question].type !== formFieldTypes.image &&
        (!newState[question].value || (newState[question].restriction && !newState[question].restrictionCount))) {
        inValid = true;
        break;
      }

      inValid = Object.values(newState[question].options || {}).some(option => {
        return !option.value;
      });

      if (inValid) {
        break;
      }
    }
    setFormValid(!inValid);
  }

  const handleBlur = () => {
    errorCheck(formState);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updatePollQuestionsRequest({
      clientURL: client.url,
      pollURL: poll.url,
      form: {
        questions: prepareFormValues({ formState })
      }
    }))
  }

  const handleImageRemoved = ({ fileID }) => {
    const newState = { ...formState };
    for (const key in newState) {
      if (newState[key].image?.fileID === fileID) {
        newState[key].image = null;
      }
    }
    setFormState(newState);
    errorCheck(newState);
  }
  
  const handleSelect = ({ file, questionID }) => {
    const newState = { ...formState, [questionID]: { ...formState[questionID], image: file }};
    setFormState(newState)
    errorCheck(newState);
  }

  const managePollImages = ({ question, questionID }) => {
    dispatch(
      openModal({
        props: { handleSelect, question, questionID },
        key: 'managePollImages'
      })
    )
  }

  const onElementChange = ({ item, prop, propValue }) => {
    if (prop === 'type') {
      const optionID = createResourceID();
      const newState = cloneDeep(formState);
      const { required, sequence, value } = newState[item.questionID];
      newState[item.questionID] = {
        required,
        sequence,
        type: propValue,
        value
      };

      const newList = cloneDeep(list);
      const index = newList.findIndex(question => question.questionID === item.questionID);
      delete newList[index].options;
      
      switch(propValue) {
        case formFieldTypes.input:
          newState[item.questionID].inputType = entryFormInputTypes.text;
          break;

        case formFieldTypes.radio:
          newState[item.questionID].options = { [optionID]: { value: '' } };
          newList[index].options = [{
            optionID,
            data: ''
          }];
          break;

        case formFieldTypes.multiSelect:
          newState[item.questionID].options = { [optionID]: { value: '' } };
          newList[index].options = [{
            optionID,
            data: ''
          }];
          break;
        
        default:
      }

      setFormState(newState);
      setList(newList);
      errorCheck(newState);

      return;
    }

    const newState = cloneDeep(formState);
    newState[item.questionID][prop] = propValue;

    setFormState(newState);
    errorCheck(newState);
  }

  const onOptionChange = ({ option, question, value }) => {
    const newState = cloneDeep(formState);
    newState[question.questionID].options[option.optionID].value = value;

    setFormState(newState);
    errorCheck(newState);
  }

  const onDrag = ({ questionList }) => {
    setList(questionList);
    const newState = cloneDeep(formState);
    
    (questionList || []).forEach((item, index) => {
      newState[item.questionID].sequence = index;
    });

    setFormState(newState);
  }

  const onOptionDrag = ({ item, optionList }) => {
    const index = list.findIndex(question => question.questionID === item.questionID);
    const newList = cloneDeep(list);
    newList[index].options = [...optionList];

    setList(newList);

    const newState = cloneDeep(formState);
    (optionList || []).forEach((option, index) => {
      newState[item.questionID].options[option.optionID].sequence = index;
    });

    setFormState(newState);
  }

  const prepareFormValues = ({ formState }) => {
    let options;
    const questions = Array.from(Object.keys(formState)).map(key => {
      switch (formState[key].type) {
        case formFieldTypes.multiSelect:
          options = Array.from(Object.keys((formState[key].options || {}))).map(option => {
            return {
              data: formState[key].options[option].value,
              optionID: option,
              sequence: formState[key].options[option].sequence || 0
            }
          });

          return {
            formElements: {
              data: formState[key].value,
            },
            options: sortBy({ key: 'sequence', list: options }),
            questionID: key,
            sequence: formState[key].sequence,
            type: formState[key].type
          };
        
        case formFieldTypes.radio:
          options = Array.from(Object.keys((formState[key].options || {}))).map(option => {
            return {
              data: formState[key].options[option].value,
              optionID: option,
              sequence: formState[key].options[option].sequence || 0
            }
          });

          return {
            formElements: {
              data: formState[key].value,
            },
            options: sortBy({ key: 'sequence', list: options }),
            questionID: key,
            sequence: formState[key].sequence,
            type: formState[key].type
          };

        case formFieldTypes.image:
          return {
            image: formState[key].image?.fileID,
            questionID: key,
            sequence: formState[key].sequence,
            type: formState[key].type
          };

        case formFieldTypes.textarea:
          return {
            formElements: {
              data: formState[key].value,
              required: formState[key].required,
              restriction: formState[key].restriction,
              restrictionCount: formState[key].restrictionCount,
            },
            questionID: key,
            sequence: formState[key].sequence,
            type: formState[key].type
          };

        case formFieldTypes.checkbox:
          return {
            formElements: {
              data: formState[key].value,
              required: formState[key].required
            },
            questionID: key,
            sequence: formState[key].sequence,
            type: formState[key].type
          };

        case formFieldTypes.paragraph:
          return {
            formElements: {
              data: formState[key].value,
            },
            questionID: key,
            sequence: formState[key].sequence,
            type: formState[key].type
          };

        case formFieldTypes.heading:
          return {
            formElements: {
              data: formState[key].value,
            },
            questionID: key,
            sequence: formState[key].sequence,
            type: formState[key].type
          }

        default:
          return {
            formElements: {
              data: formState[key].value,
              inputType: formState[key].inputType,
              required: formState[key].required
            },
            questionID: key,
            sequence: formState[key].sequence,
            type: formState[key].type
          }
      }
    });
    return sortBy({ key: 'sequence', list: questions });
  }

  const removeOption = ({ option, question }) => {
    dispatch(
      openDialog({
        key: 'removeFormOption',
        props: {
          fn: () => {
            const newList = cloneDeep(list);
            const questionIndex = newList.findIndex(item => item.questionID === question.questionID);
            const optionIndex = newList[questionIndex].options.findIndex(opt => opt.optionID === option.optionID);
            newList[questionIndex].options.splice(optionIndex, 1);
            setList(newList);

            const newState = cloneDeep(formState);

            delete newState[question.questionID].options[option.optionID];

            setFormState(newState);
            errorCheck(newState);
          } 
        }
      })
    );
  }

  const removeQuestion = ({ item }) => {
    dispatch(
      openDialog({
        key: 'removeFormQuestion',
        props: {
          fn: () => {
            const newList = cloneDeep(list);
            const index = list.findIndex(question => question.questionID === item.questionID);
            newList.splice(index, 1)
            setList(newList);

            const newState = cloneDeep(formState);
            delete newState[item.questionID];

            setFormState(newState);
            errorCheck(newState);
          }
        }
      })
    );
  }

  const renderOptionItem = ({ item: option, question }) => {
    return (
      <div className="poll-question-options-option">
        <FormStructureInputField
          error={!formState[question.questionID]?.options[option.optionID].value && 'Option value required'}
          handleBlur={handleBlur}
          handleChange={value => onOptionChange({ option, question, value })}
          placeholder="Option"
          value={formState[question.questionID]?.options[option.optionID].value || ''}
        />
        <Button
          className="trash"
          onClick={() => removeOption({ option, question })}
        ><FontAwesomeIcon icon={faTrashCanXmark} /></Button>
      </div>
    )
  }

  const renderListItem = ({ item }) => {
    return (
      <div
        className="poll-question"
        key={item.questionID}
      >
        {
          formState[item.questionID].type !== formFieldTypes.image && 
          <FormStructureInputField
            displayParagraph={formState[item.questionID]?.type === formFieldTypes.paragraph}
            error={!formState[item.questionID]?.value && 'Question value required'}
            handleBlur={handleBlur}
            handleChange={propValue => onElementChange({ item, prop: 'value', propValue })}
            placeholder={
              formState[item.questionID].type === formFieldTypes.heading ? 'Title' :
              formState[item.questionID].type === formFieldTypes.paragraph ? 'Paragraph' : 'Type Your Question'
            }
            value={formState[item.questionID]?.value || ''}
          />
        }
        <div className="poll-question-config">
          <div className="poll-question-config-content">
            <FormStructureSelectField
              handleBlur={handleBlur}
              handleChange={propValue => onElementChange({ item, prop: 'type', propValue })}
              options={formFieldOptions}
              value={formState[item.questionID]?.type}
            />
            {
              formState[item.questionID]?.type === formFieldTypes.input && (
                <FormStructureSelectField
                  handleBlur={handleBlur}
                  handleChange={propValue => onElementChange({ item, prop: 'inputType', propValue })}
                  options={entryFormInputOptions}
                  value={formState[item.questionID]?.inputType}
                />
              )
            }
            {
              formState[item.questionID]?.type === formFieldTypes.textarea && (
                <>
                  <FormStructureSliderField
                    checked={formState[item.questionID].restriction}
                    label="Restrict Word Length"
                    onChange={e => onElementChange({ item, prop: 'restriction', propValue: e.target.checked })}
                  />
                  {
                    formState[item.questionID].restriction && (
                      <FormStructureInputField
                        error={!formState[item.questionID]?.restrictionCount && 'Max required'}
                        handleBlur={handleBlur}
                        handleChange={propValue => onElementChange({ item, prop: 'restrictionCount', propValue })}
                        placeholder="Max Words"
                        sx={{ width: 110 }}
                        type="number"
                        value={formState[item.questionID]?.restrictionCount || ''}
                      />
                    )
                  }
                </>
              )
            }
            {
              formState[item.questionID]?.type === formFieldTypes.radio && (
                <Button
                  onClick={() => createOption({ item })}
                  variant="contained"
                >Create Option</Button>
              )
            }
            {
              formState[item.questionID]?.type === formFieldTypes.multiSelect && (
                <Button
                  onClick={() => createOption({ item })}
                  variant="contained"
                >Create Option</Button>
              )
            }
            {
              formState[item.questionID]?.type === formFieldTypes.image && (
                <div className="poll-question-image">
                  {
                    !formState[item.questionID]?.image && <Button
                      color="confirm"
                      onClick={() => managePollImages({ question: formState[item.questionID], questionID: item.questionID})}
                      variant="contained"
                    >Choose Image</Button>
                  }
                  {
                    formState[item.questionID]?.image && <>
                      <Button
                        onClick={() => managePollImages({ question: formState[item.questionID], questionID: item.questionID})}
                        variant="contained"
                      >Change Image</Button>
                      <span>{formState[item.questionID]?.image?.name}</span>
                    </>
                  }
                </div>
              )
            }
          </div>
          <div className="poll-question-config-content">
            {
              (
                formState[item.questionID]?.type === formFieldTypes.checkbox ||
                formState[item.questionID]?.type === formFieldTypes.input ||
                formState[item.questionID]?.type === formFieldTypes.textarea
              ) && (
                <FormStructureSliderField
                  checked={formState[item.questionID].required}
                  label="Required"
                  onChange={e => onElementChange({ item, prop: 'required', propValue: e.target.checked })}
                />
              )
            }
            <Button
              className="trash"
              onClick={() => removeQuestion({ item })}
            ><FontAwesomeIcon icon={faTrashCanXmark} /></Button>
          </div>
        </div>
        {
          (formState[item.questionID]?.type === formFieldTypes.radio || formState[item.questionID]?.type === formFieldTypes.multiSelect) && (
            <div className="poll-question-options">
              <DragDrop
                droppableId={`${item.questionID}-options`}
                handleDrag={event => onOptionDrag({ item, optionList: event.list })}
                item={item}
                list={item?.options}
                renderKey="optionID"
                renderListArgs={{ question: item }}
                renderListItem={renderOptionItem}
                secondary
                setList={() => {}}
              />
            </div>
          )
        }
      </div>
    )
  }

  return (
    <StructureForm
      createButton={createButton}
      disabled={!formValid}
      isRequesting={isRequesting}
      list={list}
      onDrag={event => {onDrag({ questionList: event.list })}}
      onSubmit={handleSubmit}
      renderKey="questionID"
      renderListItem={renderListItem}
      setList={() => {}}
    />
  )
}

export default PollGenerator;
