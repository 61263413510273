import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import TinyMCE from 'src/components/TinyMCE';
import { TextField } from '@mui/material';
import { sendNotificationsRequest } from 'src/redux/actions/app';

const EmailAdministrators = () => {
  const submitted = useSelector(state => state.completedStore.SEND_NOTIFICATIONS);
  const [subject, setSubject] = useState();
  const [touched, setTouched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'emailAdministrators' }));
    dispatch(updateDrawerContent({ key: 'networkEmailAdministrators' }));
  }, []);

  useEffect(() => {
    if (submitted) {
      setSubject();
      setTouched(false);
    }
  }, [submitted]);

  const handleSubjectChange = (value) => {
    setSubject(value);
  }

  const handleTouched = () => {
    setTouched(true);
  };

  const handleSubmit = (event) => {
    dispatch(sendNotificationsRequest({ form: {
      mailContent: {
        notificationText: event,
        notifySubject: subject
      }
    } }));
  }

  return (
    <TinyMCE
      clearOnSave
      CTA="Send"
      disabled={!subject}
      handleSubmit={(event => handleSubmit(event))}
      label="Email Message:"
      optionalFormData={() => 
        <div className="box-content optional-form-data">
          <div className="optional-form-data-subject">
            <label>Email Subject:</label>
            <TextField
              error={!!(touched && !subject)}
              helperText={touched && !subject ? 'Subject Required' : null}
              inputProps={{
                autoComplete: 'off'
              }}
              onChange={e => {handleSubjectChange(e.target.value)}}
              onFocus={handleTouched}
              required
              type="text"
              value={subject || ''}
            />
          </div>
        </div>
      }
      requestPrefix="SEND_NOTIFICATIONS"
      required
      title="Email All Administrators"
      value=""
    />
  )
}

export default EmailAdministrators;
