import React from 'react';
import AutoForm from 'src/components/AutoForm';
import {
  fieldTypes,
  homePageBackgroundTypes,
  homePageOverlayTypes,
  inputTypes,
  yesOrNo
} from 'src/constants';

const HomepageRowSettingsForm = ({ handleClose, images, onSubmit, row }) => {
  const handleSubmit = (event) => {
    onSubmit({ event, row });
    handleClose();
  }

  const backgroundTypes = [{
    label: 'Color',
    value: homePageBackgroundTypes.color
  }, {
    label: 'Image',
    value: homePageBackgroundTypes.image
  }, {
    label: 'Parallax',
    value: homePageBackgroundTypes.parallax
  }];

  const overlayTypes = [{
    label: 'None',
    value: homePageOverlayTypes.none
  }, {
    label: 'Dark',
    value: homePageOverlayTypes.dark
  }, {
    label: 'Light',
    value: homePageOverlayTypes.light
  }];

  const configuration = {
    formElements: {
      rows: [{
        heading: 'Background'
      }, {
        elements: [{
          label: 'Background Type',
          name: 'backgroundType',
          type: fieldTypes.select,
          options: backgroundTypes,
          value: row.background.backgroundType || homePageBackgroundTypes.color
        }]
      }, {
        elements: [{
          label: 'Background Color',
          name: 'backgroundColor',
          hide: { key: 'backgroundType', values: [homePageBackgroundTypes.image, homePageBackgroundTypes.parallax] },
          placeholder: '#000000',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: row.background.backgroundColor || ''
        }, {
          label: 'Background Image',
          name: 'backgroundImage',
          hide: { key: 'backgroundType', value: homePageBackgroundTypes.color },
          type: fieldTypes.select,
          options: images,
          value: row.background.backgroundImage?.fileID || images[0]?.value
        }, {
          label: 'Use Background Overlay',
          name: 'overlay',
          hide: { key: 'backgroundType', value: homePageBackgroundTypes.color },
          type: fieldTypes.select,
          options: overlayTypes,
          value: row.background.overlay || overlayTypes[0].value
        }]
      }, {
        heading: 'Box Model'
      }, {
        elements: [{
          label: 'Margin (Space Above/Below The Row)',
          name: 'margin',
          placeholder: '0px',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: row.boxModel.margin || ''
        }]
      }, {
        elements: [{
          label: 'Padding (Space Surrounding The Columns)',
          name: 'padding',
          placeholder: '0px',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: row.boxModel.padding || ''
        }]
      }, {
        elements: [{
          label: 'Gap (Space Between The Columns)',
          name: 'gap',
          placeholder: '0px',
          type: fieldTypes.input,
          inputType: inputTypes.text,
          value: row.boxModel.gap || ''
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="homepage-row-settings-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={''}
      />
    </section>
  )
}

export default HomepageRowSettingsForm;
