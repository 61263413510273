import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getParadeRequest, updateParadeDateRequest } from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import {
  advanceDate,
  displayDate,
  displayTime,
  parseTime
} from 'src/utilities/dates';

const ParadeDates = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeDates', client, parade }));
    dispatch(updateDrawerContent({
      fetching: paradeFetching,
      key: 'paradeDates',
      options: { client, parade } 
    }));
  }, [paradeFetching]);

  const handleDateCopy = (date) => {
    const startTime = parseTime({ date: date.startDate });
    const endTime = parseTime({ date: date.endDate });
    const lastDate = parade.dates[parade.dates.length - 1];
    const newDate = advanceDate({ date: lastDate.startDate });

    dispatch(updateParadeDateRequest({
      clientURL: client.url,
      paradeURL: parade.url,
      form: {
        startDate: `${newDate}T${startTime}`,
        endDate: `${newDate}T${endTime}`
      }
    }));
  }

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, parade, restrictedDates: parade.dates },
          key: 'createParadeDate'
        })
      )
    }
  }, {
    label: 'Copy',
    requiresSelection: true,
    variant: 'contained',
    fn: date => {
      handleDateCopy(date)
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: date => {
      dispatch(
        openModal({
          props: { client, parade, date, restrictedDates: parade.dates.filter(d => d.dateID !== date.dateID) },
          key: 'editParadeDate'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: date => {
      dispatch(
        openDialog({
          props: { client, parade, date },
          key: 'removeParadeDate'
        })
      )
    }
  }];

  return (
    <ResourceList
      actions={actions}
      fetching={paradeFetching}
      list={parade?.dates || []}
      renderHeaders={() => 
        <div className="parade-date">
          <div className="parade-date-date">Date</div>
          <div className="parade-date-time-start">Start Time</div>
          <div className="parade-date-time-end">End Time</div>
        </div>
      }
      renderItem={item => 
        <div className="parade-date">
          <div className="parade-date-date">{ displayDate({ date: item.startDate}) }</div>
          <div className="parade-date-time-start">{ displayTime({ date: item.startDate}) }</div>
          <div className="parade-date-time-end">{ displayTime({ date: item.endDate}) }</div>
        </div>
      }
      renderKey="dateID"
      title="Show Dates / Times"
    />
  )
}

export default ParadeDates;
