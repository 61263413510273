import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPollRequest,
  resetPollURLChanged,
  updatePollNameRequest
} from 'src/redux/actions/polls';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fieldTypes, inputTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const PollName = () => {
  const client = useSelector(state => state.clientStore?.client);
  const poll = useSelector(state => state.pollStore?.poll);
  const pollFetching = useSelector(state => state.loadingStore.GET_POLL);
  const [configuration, setConfiguration] = useState(null);
  const { pollURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!pollURL) {
    navigate('/');
  }

  const handleSubmit = (poll) => {
    dispatch(updatePollNameRequest({ clientURL: client.url, pollURL, form: poll }));
  }

  useEffect(() => {
    if (!poll) {
      dispatch(getPollRequest({ clientURL: client.url, pollURL }));
    }
  }, []);

  useEffect(() => {
    if (poll?.urlChanged) {
      dispatch(resetPollURLChanged());
      navigate(`/${client.url}/polls/${poll.urlChanged}/name`);
    }
  }, [poll?.urlChanged]);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'pollName', client, poll }));
    dispatch(updateDrawerContent({
      fetching: pollFetching,
      key: 'pollName',
      options: { client, poll } 
    }));

    if (!configuration && poll) {
      setConfiguration({
        formElements: {
          rows: [{
            elements: [{
              label: 'Survey Name',
              name: 'name',
              type: fieldTypes.input,
              inputType: inputTypes.text,
              value: poll?.name,
              required: true
            }]
          }, {
            elements: [{
              label: 'Survey URL',
              name: 'url',
              type: fieldTypes.input,
              inputType: inputTypes.domain,
              value: poll?.url,
              required: true
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [poll?.name, poll?.url, pollFetching]);

  return (
    <section className="poll-name box">
      <div className="box-title">
        <h3>
            {
              !pollFetching && (
                <span>Name & URL</span>
              )
            }
            {
              pollFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!pollFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_POLL_NAME'}
          />
          <hr />
          Public Landing Page: <Link
            target="_blank"
            to={`https://${client.url}.cpjam.com/${pollURL}`}
          >{`https://${client.url}.cpjam.com/${pollURL}`}</Link>
        </div>
        )
      }
    </section>
  )
}

export default PollName;
