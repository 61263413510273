import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import createLoadingSelector from 'src/redux/loading';
import { getContestRequest } from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import TypeaheadList from 'src/components/TypeaheadList';
import ResourceList from 'src/components/ResourceList';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import UserProfile from 'src/components/UserProfile';
import { toast } from 'react-toastify';
import { getClientVolunteerListRequest } from 'src/redux/actions/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotebook } from '@fortawesome/pro-light-svg-icons';

const ContestJudges = ({ creatingJudge }) => {
  const client = useSelector(state => state.clientStore?.client);
  const volunteerClients = useSelector(state => state.clientStore?.volunteerClients);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  const handleCreateJudge = (judge) => {
    const { email, fullName, headshot, sendInvite, userID } = judge;
    dispatch(openModal({
      props: { client, contest, judge: {
        sendInvite,
        user: { email, fullName, headshot, userID }
      } },
      key: sendInvite ? 'editContestJudgeInvitation' : 'editContestJudge'
    }));
  }

  const actions = [{
    label: 'Request Volunteers',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, contest },
          key: 'requestJudgeVolunteers'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    variant: 'contained',
    fn: judge => {
      dispatch(
        openModal({
          props: { client, contest, judge },
          key: 'editContestJudge'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: judge => {
      dispatch(
        openDialog({
          props: { client, contest, judge },
          key: 'removeContestJudge'
        })
      )
    }
  }];

  const invitationActions = [{
    color: 'confirm',
    label: 'Copy Link',
    requiresSelection: true,
    variant: 'contained',
    fn: invitation => {
      navigator.clipboard.writeText(`https://${client.url}.cpjam.com/signup/${invitation.invitationKey}`);
      toast.success('Link copied to clipboard.');
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    variant: 'contained',
    fn: invitation => {
      dispatch(
        openModal({
          props: { client, contest, judge: invitation },
          key: 'editContestJudgeInvitation'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: invitation => {
      dispatch(
        openDialog({
          props: { client, contest, invitation },
          key: 'removeContestJudgeInvitation'
        })
      )
    }
  }];

  const prospectActions = [{
    color: 'confirm',
    label: 'Convert',
    requiresSelection: true,
    variant: 'contained',
    fn: judge => {
      dispatch(
        openModal({
          props: { client, contest, judge },
          key: 'convertContestJudgeProspect'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    variant: 'contained',
    fn: prospect => {
      dispatch(
        openModal({
          props: { client, contest, prospect },
          key: 'editContestJudgeProspect'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: prospect => {
      dispatch(
        openDialog({
          props: { client, contest, prospect },
          key: 'removeContestJudgeProspect'
        })
      )
    }
  }];


  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
    if (!volunteerClients) {
      dispatch(getClientVolunteerListRequest());
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestJudges', client, contest }));
    dispatch(updateDrawerContent({
      fetching: contestFetching,
      key: 'contestJudges',
      options: { client, contest }
    }));
  }, [contestFetching]);
  
  return (
    <section className="contest-judges grid">
      {
        ((contest?.judgeProspects || []).length > 0) && (
          <ResourceList
            actions={prospectActions}
            list={contest.judgeProspects}
            renderItem={prospect => 
              <div className="contest-judges-prospect">
                <UserProfile
                  linkEmail
                  linkPhone
                  user={prospect?.user}
                />
                { prospect.notes && <FontAwesomeIcon icon={faNotebook} /> }
              </div>
            }
            renderKey="prospectID"
            title="Prospective Judges"
          />
        )
      }
      {
        ((contest?.judgeInvitations || []).length > 0) && (
          <ResourceList
            actions={invitationActions}
            list={contest.judgeInvitations}
            renderItem={invitation => 
              <div className="invitation">
                <div className="invitation-details">
                  <span className="strong">{invitation.email}</span> &mdash; <span className="italic">expires: {invitation.exp}</span>
                </div>
                <div className="invitation-link">
                  {`https://${client.url}.cpjam.com/signup/${invitation.invitationKey}`}
                </div>
              </div>}
            renderKey="invitationKey"
            title="Pending Invitations"
          />
        )
      }
      <TypeaheadList
        actions={actions}
        create={handleCreateJudge}
        fetching={contestFetching}
        list={contest?.judges}
        permitInvitation
        placeholder="Type To Add Judge..."
        renderItem={judge =>
          <UserProfile
            showEmail
            user={judge.user}
          ></UserProfile>
        }
        renderKey="assignmentID"
        selecting={creatingJudge}
        title="Assigned Judges"
      />
    </section>
  )
}

const creatingSelector = createLoadingSelector(['UPDATE_CONTEST_JUDGE', 'CREATE_CONTEST_JUDGE_INVITATION']);
const mapStateToProps = (state) => ({ creatingJudge: creatingSelector(state) });
export default connect(mapStateToProps)(ContestJudges);