import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getParadeRequest } from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import { currency } from 'src/utilities/strings';

const ParadePromoCodes = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, parade },
          key: 'createParadePromoCode'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: promoCode => {
      dispatch(
        openModal({
          props: { client, parade, promoCode },
          key: 'editParadePromoCode'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: promoCode => {
      dispatch(
        openDialog({
          props: { client, parade, promoCode },
          key: 'removeParadePromoCode'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradePromoCodes', client, parade }));
    dispatch(updateDrawerContent({
      fetching: paradeFetching,
      key: 'paradePromoCodes',
      options: { client, parade } 
    }));
  }, [paradeFetching]);
  
  return (
    <ResourceList
      actions={actions}
      fetching={paradeFetching}
      list={parade?.promoCodes}
      renderHeaders={() => 
        <div className="promo-code">
          <div className="promo-code-key">Promotional Code Key</div>
          <div className="promo-code-amount">Amount</div>
        </div>
      }
      renderItem={promoCode => 
        <div className="promo-code">
          <div className="promo-code-key">{promoCode.promoKey}</div>
          <div className="promo-code-amount">{currency(promoCode.amount)}</div>
        </div>
      }
      renderKey="promoCodeID"
      title="Promo Code List"
    />
  )
}

export default ParadePromoCodes;