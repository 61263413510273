import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  updateContestConfigurationRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fieldTypes,
  productTypes,
  yesOrNo
} from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ContestAdvancedConfiguration = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  const handleSubmit = (event) => {
    dispatch(updateContestConfigurationRequest({ clientURL: client.url, contestURL, form: event }));
  }

  const showScoresOptions = [{
    label: 'No',
    value: 'no'
  }, {
    label: 'Yes, but judge names are kept private',
    value: 'private'
  }, {
    label: 'Yes, but judge names are kept private (comments only)',
    value: 'private-comments'
  }, {
    label: 'Yes, and judge names are displayed',
    value: 'yes'
  }, {
    label: 'Yes, and judge names are displayed (comments only)',
    value: 'yes-comments'
  }];

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestConfiguration', client, contest }));
    dispatch(updateDrawerContent({
      fetching: contestFetching,
      key: 'contestConfiguration',
      options: { client, contest } 
    }));
  }, [contestFetching]);

  const contestConfiguration = {
    formElements: {
      rows: [{
        heading: 'Operational settings'
      }, {
        elements: [{
          label: 'Discounts are automatically applied for entrants who purchase again at a later time?',
          name: 'autoApplyDiscount',
          type: fieldTypes.select,
          options: yesOrNo,
          value: !!contest?.autoApplyDiscount
        }]
      }, {
        heading: 'Change how elements are displayed',
      }, {
        elements: [{
          label: 'Category list is displayed on Landing page?',
          name: 'displayCategoryList',
          type: fieldTypes.select,
          options: yesOrNo,
          value: !!contest?.displayCategoryList
        }]
      }, {
        elements: [{
          label: 'Entry Forms are displayed before Document Folders?',
          name: 'entryFormsFirst',
          type: 'select',
          options: yesOrNo,
          value: !!contest?.entryFormsFirst
        }]
      }, {
        elements: [{
          label: 'Optional Fees are displayed before Categories?',
          name: 'optionalFeesFirst',
          type: 'select',
          options: yesOrNo,
          value: !!contest?.optionalFeesFirst
        }]
      }, {
        elements: [{
          label: 'Optional CSS (Developers Only)',
          name: 'optionalCSS',
          type: fieldTypes.textarea,
          value: contest?.optionalCSS
        }]
      }, {
        heading: 'Scoring settings (do not change until scoring is completed)',
      }, {
        elements: [{
          label: 'Scores Are Visible To Entrants?',
          name: 'showScoresToUsers',
          type: fieldTypes.select,
          options: showScoresOptions,
          value: contest?.showScoresToUsers
        }]
      }]
    },
    submitCTA: 'Save'
  };

  const scholarshipConfiguration = {
    formElements: {
      rows: [{
        heading: 'Change how elements are displayed',
      }, {
        elements: [{
          label: 'Entry Forms are displayed before Document Folders?',
          name: 'entryFormsFirst',
          type: 'select',
          options: yesOrNo,
          value: !!contest?.entryFormsFirst
        }]
      }]
    },
    submitCTA: 'Save'
  };
  
  return (
    <section className="box">
      <div className="box-title">
        <h3>
          {
            !contestFetching && (
              <span>Advanced Configuration</span>
            )
          }
          {
            contestFetching && (
              <LoadingCircle variant="dark" />
            )
          }
        </h3>
      </div>
      {
        (!contestFetching && (contestConfiguration || scholarshipConfiguration)) && (
        <div className="box-content">
          <AutoForm
            configuration={contest?.type === productTypes.contest ? contestConfiguration : scholarshipConfiguration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_CONTEST_CONFIGURATION'}
          />
        </div>
        )
      }
    </section>
  )
}

export default ContestAdvancedConfiguration;
