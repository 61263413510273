import AccountHeadshot from 'src/components/Account/AccountHeadshot';
import ActionPanel from 'src/components/ActionPanel';
import ForgotPassword from 'src/components/ForgotPassword';

import AdministratorForm from 'src/components/TemplateForms/Client/Administrator';

import AssignAwardsForm from 'src/components/TemplateForms/Contests/AssignAwardsForm';
import ArchiveForm from 'src/components/TemplateForms/Contests/ArchiveForm';
import AwardForm from 'src/components/TemplateForms/Contests/AwardForm';
import Checkout from 'src/components/Checkout';
import CreateContestForm from 'src/components/TemplateForms/Contests/CreateContestForm';
import DownloadFileFoldersForm from 'src/components/TemplateForms/Contests/DownloadFileFoldersForm';
import DuplicateContestForm from 'src/components/TemplateForms/Contests/DuplicateContestForm';
import DuplicateEntryFormForm from 'src/components/TemplateForms/Contests/DuplicateEntryFormForm';
import DuplicateScoresheetForm from 'src/components/TemplateForms/Contests/DuplicateScoresheetForm';
import CategoryForm from 'src/components/TemplateForms/Contests/CategoryForm';
import ContestDocumentFolderForm from 'src/components/TemplateForms/Contests/DocumentFolderForm';
import EntryFormForm from 'src/components/TemplateForms/Contests/EntryFormForm';
import EntryFormStructureForm from 'src/components/TemplateForms/Contests/EntryFormStructureForm';
import JudgeForm from 'src/components/TemplateForms/Contests/JudgeForm';
import JudgeProspectForm from 'src/components/TemplateForms/Contests/JudgeProspectForm';
import ContestOptionalFeeForm from 'src/components/TemplateForms/Contests/OptionalFeeForm';
import ManualContestCoordinator from 'src/components/TemplateForms/Contests/ManualCoordinatorProfile';
import ContestPromoCodeForm from 'src/components/TemplateForms/Contests/PromoCodeForm';
import RequestJudgeVolunteerForm from 'src/components/TemplateForms/Contests/RequestJudgeVolunteerForm';
import ScholarshipForm from 'src/components/TemplateForms/Contests/ScholarshipForm';
import ScholarshipRequirementsForm from 'src/components/TemplateForms/Contests/ScholarshipRequirementsForm';
import ScoresheetForm from 'src/components/TemplateForms/Contests/ScoresheetForm';
import ScoresheetCriteriaForm from 'src/components/TemplateForms/Contests/ScoresheetCriteriaForm';
import ContestSponsorTierForm from 'src/components/TemplateForms/Contests/SponsorTierForm';

import CreatePollForm from 'src/components/TemplateForms/Polls/CreatePollForm';
import PollImages from 'src/pages/Polls/Poll/Settings/PollImages';

import CreateEventForm from 'src/components/TemplateForms/Events/CreateEventForm';
import EditRegistration from 'src/components/EditRegistration';
import EventOptionalFeeForm from 'src/components/TemplateForms/Events/OptionalFeeForm';
import ManualEventCoordinator from 'src/components/TemplateForms/Events/ManualCoordinatorProfile';
import TicketForm from 'src/components/TemplateForms/Events/TicketForm';
import EventSponsorTierForm from 'src/components/TemplateForms/Events/SponsorTierForm';
import EventPromoCodeForm from 'src/components/TemplateForms/Events/PromoCodeForm';
import viewImage from 'src/components/ViewImage';
import ViewRegistration from 'src/components/ViewRegistration';
import ViewScoringProgress from 'src/components/ViewScoringProgress';
import ViewPollVote from 'src/components/ViewPollVote';
import ViewInvoice from 'src/components/ViewInvoice';

import CreateParadeForm from 'src/components/TemplateForms/Parades/CreateParadeForm';
import HomepageColumnSettingsForm from 'src/components/TemplateForms/Parades/HomepageColumnSettingsForm';
import HomepageColumnTextForm from 'src/components/TemplateForms/Parades/HomepageColumnTextForm';
import HomepageRowSettingsForm from 'src/components/TemplateForms/Parades/HomepageRowSettingsForm';
import ParadeDateForm from 'src/components/TemplateForms/Parades/ParadeDateForm';
import ParadeOptionalFeeForm from 'src/components/TemplateForms/Parades/OptionalFeeForm';
import ParadePromoCodeForm from 'src/components/TemplateForms/Parades/PromoCodeForm';
import ParadePropertyGroupForm from 'src/components/TemplateForms/Parades/PropertyGroupForm';
import ParadeSponsorTierForm from 'src/components/TemplateForms/Parades/SponsorTierForm';
import ParadeTicketForm from 'src/components/TemplateForms/Parades/TicketForm';
import ParadeTimeframeForm from 'src/components/TemplateForms/Parades/TimeframeForm';
import ViewParadeAttendance from 'src/components/ViewParadeAttendance';

import PropertyTimeframeForm from 'src/components/TemplateForms/Common/PropertyTimeframeForm';
import SponsorTimeframeForm from 'src/components/TemplateForms/Common/SponsorTimeframeForm';
import TimeframeForm from 'src/components/TemplateForms/Common/TimeframeForm';

import ClientForm from "src/components/TemplateForms/ClientForm";
import InvoiceForm from "src/components/TemplateForms/InvoiceForm";
import SystemNotificationForm from "src/components/TemplateForms/SystemNotificationForm";


const modalActions = {
  actionPanel: {
    content: ActionPanel,
    title: () => 'My Action Panel'
  },
  assignContestAwards: {
    actionKey: 'ASSIGN_CONTEST_AWARDS',
    content: AssignAwardsForm,
    title: ({ category, entry }) => category ?
      `Assigning Awards To ${category.label}-${entry.number}` :
      `Assigning Awards To ${entry.applicantName}`
  },
  convertContestJudgeProspect: {
    actionKey: 'CONVERT_CONTEST_JUDGE_PROSPECT',
    content: JudgeForm,
    title: ({ contest }) => `Edit Judging Assignment For ${contest.name}`
  },
  createClient: {
    actionKey: 'UPDATE_CLIENT',
    content: ClientForm,
    title: () => 'Creating New Client'
  },
  createClientAdministrator: {
    actionKey: 'UPDATE_ADMINISTRATOR',
    content: AdministratorForm,
    title: () => 'Creating New Client Administrator'
  },
  createClientAdministratorInvite: {
    actionKey: 'UPDATE_ADMINISTRATOR_INVITATION',
    content: AdministratorForm,
    title: () => 'Creating New Client Administrator'
  },
  createContest: {
    actionKey: 'CREATE_CONTEST',
    content: CreateContestForm,
    title: () => 'Creating New Online Awards Program'
  },
  createContestArchive: {
    actionKey: 'CREATE_CONTEST_ARCHIVE',
    content: ArchiveForm,
    title: () => 'Creating File Archive'
  },
  createContestAward: {
    actionKey: 'UPDATE_CONTEST_AWARD',
    content: AwardForm,
    title: ({ contest }) => `Adding Award For ${contest.name}`
  },
  createContestCategory: {
    actionKey: 'UPDATE_CONTEST_CATEGORY',
    content: CategoryForm,
    title: ({ contest }) => `Adding Category For ${contest.name}`
  },
  createContestDocumentFolder: {
    actionKey: 'UPDATE_CONTEST_DOCUMENT_FOLDER',
    content: ContestDocumentFolderForm,
    title: ({ contest }) => `Adding Document Folder For ${contest.name}`
  },
  createContestEntryForm: {
    actionKey: 'UPDATE_CONTEST_ENTRY_FORM',
    content: EntryFormForm,
    title: ({ contest }) => `Adding Entry Form For ${contest.name}`
  },
  createContestOptionalFee: {
    actionKey: 'UPDATE_CONTEST_OPTIONAL_FEE',
    content: ContestOptionalFeeForm,
    title: ({ contest }) => `Adding Optional Fee For ${contest.name}`
  },
  createContestPromoCode: {
    actionKey: 'UPDATE_CONTEST_PROMO_CODE',
    content: ContestPromoCodeForm,
    title: ({ contest }) => `Adding Promotional Code For ${contest.name}`
  },
  createContestScholarship: {
    actionKey: 'UPDATE_CONTEST_SCHOLARSHIP',
    content: ScholarshipForm,
    title: ({ contest }) => `Adding Scholarship For ${contest.name}`
  },
  createContestScoresheet: {
    actionKey: 'UPDATE_CONTEST_SCORESHEET',
    content: ScoresheetForm,
    title: ({ contest }) => `Adding Scoresheet For ${contest.name}`
  },
  createContestSponsorTier: {
    actionKey: 'UPDATE_CONTEST_SPONSOR_TIER',
    content: ContestSponsorTierForm,
    title: ({ contest }) => `Adding Sponsor Tier For ${contest.name}`
  },
  createContestSponsorTimeframe: {
    actionKey: 'UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME',
    content: SponsorTimeframeForm,
    title: ({ contest }) => `Adding Sponsor Pricing Timeframe For ${contest.name}`
  },
  createContestTimeframe: {
    actionKey: 'UPDATE_CONTEST_TIMEFRAME',
    content: TimeframeForm,
    title: ({ contest }) => `Adding Pricing Timeframe For ${contest.name}`
  },
  createPoll: {
    actionKey: 'CREATE_POLL',
    content: CreatePollForm,
    title: () => 'Creating New Survey'
  },
  createEvent: {
    actionKey: 'CREATE_EVENT',
    content: CreateEventForm,
    title: () => 'Creating New Event'
  },
  createEventOptionalFee: {
    actionKey: 'UPDATE_EVENT_OPTIONAL_FEE',
    content: EventOptionalFeeForm,
    title: ({ event }) => `Adding Optional Fee For ${event.name}`
  },
  createEventPromoCode: {
    actionKey: 'UPDATE_EVENT_PROMO_CODE',
    content: EventPromoCodeForm,
    title: ({ event }) => `Adding Promotional Code For ${event.name}`
  },
  createEventSponsorTier: {
    actionKey: 'UPDATE_EVENT_SPONSOR_TIER',
    content: EventSponsorTierForm,
    title: ({ event }) => `Adding Sponsor Tier For ${event.name}`
  },
  createEventSponsorTimeframe: {
    actionKey: 'UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME',
    content: SponsorTimeframeForm,
    title: ({ event }) => `Adding Sponsor Pricing Timeframe For ${event.name}`
  },
  createEventTicket: {
    actionKey: 'UPDATE_EVENT_TICKET',
    content: TicketForm,
    title: ({ event }) => `Adding Ticket For ${event.name}`
  },
  createEventTimeframe: {
    actionKey: 'UPDATE_EVENT_TIMEFRAME',
    content: TimeframeForm,
    title: ({ event }) => `Adding Pricing Timeframe For ${event.name}`
  },
  createManualContestCoordinator: {
    actionKey: 'UPDATE_CONTEST_COORDINATOR',
    content: ManualContestCoordinator,
    title: ({ contest }) => `Adding Coordinator For ${contest.name}`
  },
  createManualEventCoordinator: {
    actionKey: 'UPDATE_EVENT_COORDINATOR',
    content: ManualEventCoordinator,
    title: ({ event }) => `Adding Coordinator For ${event.name}`
  },
  createParade: {
    actionKey: 'CREATE_PARADE',
    content: CreateParadeForm,
    title: () => 'Creating New Parade Website'
  },
  createParadeDate: {
    actionKey: 'UPDATE_PARADE_DATE',
    content: ParadeDateForm,
    title: () => `Adding Date / Time For Parade Website`
  },
  createParadeOptionalFee: {
    actionKey: 'UPDATE_PARADE_OPTIONAL_FEE',
    content: ParadeOptionalFeeForm,
    title: ({ parade }) => `Adding Optional Fee For ${parade.name}`
  },
  createParadePromoCode: {
    actionKey: 'UPDATE_PARADE_PROMO_CODE',
    content: ParadePromoCodeForm,
    title: ({ parade }) => `Adding Promotional Code For ${parade.name}`
  },
  createParadePropertyGroup: {
    actionKey: 'UPDATE_PARADE_PROPERTY_GROUP',
    content: ParadePropertyGroupForm,
    title: ({ parade }) => `Adding Property Group For ${parade.name}`
  },
  createParadePropertyTimeframe: {
    actionKey: 'UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME',
    content: PropertyTimeframeForm,
    title: ({ parade }) => `Adding Property Pricing Timeframe For ${parade.name}`
  },
  createParadeSponsorTier: {
    actionKey: 'UPDATE_PARADE_SPONSOR_TIER',
    content: ParadeSponsorTierForm,
    title: ({ parade }) => `Adding Sponsor Tier For ${parade.name}`
  },
  createParadeSponsorTimeframe: {
    actionKey: 'UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME',
    content: SponsorTimeframeForm,
    title: ({ parade }) => `Adding Sponsor Pricing Timeframe For ${parade.name}`
  },
  createParadeTicket: {
    actionKey: 'UPDATE_PARADE_TICKET',
    content: ParadeTicketForm,
    title: ({ parade }) => `Adding Ticket For ${parade.name}`
  },
  createParadeTimeframe: {
    actionKey: 'UPDATE_PARADE_TIMEFRAME',
    content: ParadeTimeframeForm,
    title: ({ parade }) => `Adding Pricing Timeframe For ${parade.name}`
  },
  createSystemNotification: {
    actionKey: 'UPDATE_SYSTEM_NOTIFICATION',
    content: SystemNotificationForm,
    title: () => 'Creating New System Notification'
  },
  duplicateContest: {
    actionKey: 'DUPLICATE_CONTEST',
    content: DuplicateContestForm,
    title: ({ contest }) => `Duplicating ${contest.name}`
  },
  duplicateEntryForm: {
    actionKey: 'DUPLICATE_ENTRY_FORM',
    content: DuplicateEntryFormForm,
    title: ({ entryForm }) => `Duplicating ${entryForm.name}`
  },
  duplicateScoresheet: {
    actionKey: 'DUPLICATE_SCORESHEET',
    content: DuplicateScoresheetForm,
    title: ({ scoresheet }) => `Duplicating ${scoresheet.name}`
  },
  editClient: {
    actionKey: 'UPDATE_CLIENT',
    content: ClientForm,
    title: () => 'Editing Client'
  },
  editClientAdministrator: {
    actionKey: 'UPDATE_ADMINISTRATOR',
    content: AdministratorForm,
    title: () => 'Editing Client Administrator'
  },
  editClientAdministratorInvitation: {
    actionKey: 'UPDATE_ADMINISTRATOR_INVITATION',
    content: AdministratorForm,
    title: () => 'Editing Client Administrator'
  },
  editContestAward: {
    actionKey: 'UPDATE_CONTEST_AWARD',
    content: AwardForm,
    title: ({ contest }) => `Edit Award For ${contest.name}`
  },
  editContestCategory: {
    actionKey: 'UPDATE_CONTEST_CATEGORY',
    content: CategoryForm,
    title: ({ contest }) => `Edit Category For ${contest.name}`
  },
  editContestDownloadableFileFolders: {
    actionKey: 'UPDATE_CONTEST_DOWNLOADABLE_FILE',
    content: DownloadFileFoldersForm,
    title: () => 'Edit File Attributes'
  },
  editContestDocumentFolder: {
    actionKey: 'UPDATE_CONTEST_DOCUMENT_FOLDER',
    content: ContestDocumentFolderForm,
    title: ({ contest }) => `Edit Document Folder For ${contest.name}`
  },
  editContestEntryForm: {
    actionKey: 'UPDATE_CONTEST_ENTRY_FORM',
    content: EntryFormForm,
    title: ({ contest }) => `Edit Entry Form For ${contest.name}`
  },
  editContestEntryFormStructure: {
    actionKey: 'UPDATE_CONTEST_ENTRY_FORM_STRUCTURE',
    content: EntryFormStructureForm,
    title: ({ contest }) => `Edit Entry Form Structure For ${contest.name}`
  },
  editContestJudge: {
    actionKey: 'UPDATE_CONTEST_JUDGE',
    content: JudgeForm,
    title: ({ contest }) => `Edit Judging Assignment For ${contest.name}`
  },
  editContestJudgeInvitation: {
    actionKey: 'UPDATE_CONTEST_JUDGE_INVITATION',
    content: JudgeForm,
    title: ({ contest }) => `Edit Judging Invitation For ${contest.name}`
  },
  editContestJudgeProspect: {
    actionKey: 'UPDATE_CONTEST_JUDGE_PROSPECT',
    content: JudgeProspectForm,
    title: ({ contest }) => `Edit Judging Prospect For ${contest.name}`
  },
  editContestOptionalFee: {
    actionKey: 'UPDATE_CONTEST_OPTIONAL_FEE',
    content: ContestOptionalFeeForm,
    title: ({ contest }) => `Edit Optional Fee For ${contest.name}`
  },
  editContestPromoCode: {
    actionKey: 'UPDATE_CONTEST_PROMO_CODE',
    content: ContestPromoCodeForm,
    title: ({ contest }) => `Edit Promotional Code For ${contest.name}`
  },
  editContestRegistration: {
    actionKey: 'UPDATE_CONTEST_REGISTRATION',
    content: EditRegistration,
    title: () => 'Edit Awards Program Registration'
  },
  editContestScholarship: {
    actionKey: 'UPDATE_CONTEST_SCHOLARSHIP',
    content: ScholarshipForm,
    title: ({ contest }) => `Edit Scholarship For ${contest.name}`
  },
  editContestScholarshipRequirements: {
    actionKey: 'UPDATE_CONTEST_SCHOLARSHIP_REQUIREMENTS',
    content: ScholarshipRequirementsForm,
    title: ({ contest }) => `Edit Scholarship Requirements For ${contest.name}`
  },
  editContestScoresheet: {
    actionKey: 'UPDATE_CONTEST_SCORESHEET',
    content: ScoresheetForm,
    title: ({ contest }) => `Edit Scoresheet For ${contest.name}`
  },
  editContestScoresheetStructure: {
    actionKey: 'UPDATE_CONTEST_SCORESHEET_CRITERIA',
    content: ScoresheetCriteriaForm,
    title: ({ contest }) => `Edit Scoresheet Criteria For ${contest.name}`
  },
  editContestSponsorTier: {
    actionKey: 'UPDATE_CONTEST_SPONSOR_TIER',
    content: ContestSponsorTierForm,
    title: ({ contest }) => `Edit Sponsor Tier For ${contest.name}`
  },
  editContestSponsorTimeframe: {
    actionKey: 'UPDATE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME',
    content: SponsorTimeframeForm,
    title: ({ contest }) => `Edit Sponsor Pricing Timeframe For ${contest.name}`
  },
  editContestTimeframe: {
    actionKey: 'UPDATE_CONTEST_TIMEFRAME',
    content: TimeframeForm,
    title: ({ contest }) => `Editing Pricing Timeframe For ${contest.name}`
  },
  editEventOptionalFee: {
    actionKey: 'UPDATE_EVENT_OPTIONAL_FEE',
    content: EventOptionalFeeForm,
    title: ({ event }) => `Edit Optional Fee For ${event.name}`
  },
  editEventPromoCode: {
    actionKey: 'UPDATE_EVENT_PROMO_CODE',
    content: EventPromoCodeForm,
    title: ({ event }) => `Edit Promotional Code For ${event.name}`
  },
  editEventRegistration: {
    actionKey: 'UPDATE_EVENT_REGISTRATION',
    content: EditRegistration,
    title: () => 'Edit Event Registration'
  },
  editEventSponsorTier: {
    actionKey: 'UPDATE_EVENT_SPONSOR_TIER',
    content: EventSponsorTierForm,
    title: ({ event }) => `Edit Sponsor Tier For ${event.name}`
  },
  editEventSponsorTimeframe: {
    actionKey: 'UPDATE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME',
    content: SponsorTimeframeForm,
    title: ({ event }) => `Edit Sponsor Pricing Timeframe For ${event.name}`
  },
  editEventTicket: {
    actionKey: 'UPDATE_EVENT_TICKET',
    content: TicketForm,
    title: ({ event }) => `Edit Ticket For ${event.name}`
  },
  editEventTimeframe: {
    actionKey: 'UPDATE_EVENT_TIMEFRAME',
    content: TimeframeForm,
    title: ({ event }) => `Editing Pricing Timeframe For ${event.name}`
  },
  editInvoice: {
    actionKey: 'UPDATE_INVOICE',
    content: InvoiceForm,
    title: () => 'Editing Invoice'
  },
  editManualContestCoordinator: {
    actionKey: 'UPDATE_CONTEST_COORDINATOR',
    content: ManualContestCoordinator,
    title: ({ contest }) => `Editing Coordinator For ${contest.name}`
  },
  editManualEventCoordinator: {
    actionKey: 'UPDATE_EVENT_COORDINATOR',
    content: ManualEventCoordinator,
    title: ({ event }) => `Editing Coordinator For ${event.name}`
  },
  editParadeDate: {
    actionKey: 'UPDATE_PARADE_DATE',
    content: ParadeDateForm,
    title: () => `Editing Date / Time For Parade Website`
  },
  editParadeOptionalFee: {
    actionKey: 'UPDATE_PARADE_OPTIONAL_FEE',
    content: ParadeOptionalFeeForm,
    title: ({ parade }) => `Edit Optional Fee For ${parade.name}`
  },
  editParadePromoCode: {
    actionKey: 'UPDATE_PARADE_PROMO_CODE',
    content: ParadePromoCodeForm,
    title: ({ parade }) => `Edit Promotional Code For ${parade.name}`
  },
  editParadePropertyGroup: {
    actionKey: 'UPDATE_PARADE_PROPERTY_GROUP',
    content: ParadePropertyGroupForm,
    title: ({ parade }) => `Edit Property Group For ${parade.name}`
  },
  editParadePropertyTimeframe: {
    actionKey: 'UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME',
    content: PropertyTimeframeForm,
    title: ({ parade }) => `Edit Property Pricing Timeframe For ${parade.name}`
  },
  editParadeRegistration: {
    actionKey: 'UPDATE_PARADE_REGISTRATION',
    content: EditRegistration,
    title: () => 'Edit Parade Registration'
  },
  editParadeSponsorTier: {
    actionKey: 'UPDATE_PARADE_SPONSOR_TIER',
    content: ParadeSponsorTierForm,
    title: ({ parade }) => `Edit Sponsor Tier For ${parade.name}`
  },
  editParadeSponsorTimeframe: {
    actionKey: 'UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME',
    content: SponsorTimeframeForm,
    title: ({ parade }) => `Edit Sponsor Pricing Timeframe For ${parade.name}`
  },
  editParadeTicket: {
    actionKey: 'UPDATE_PARADE_TICKET',
    content: ParadeTicketForm,
    title: ({ parade }) => `Edit Ticket For ${parade.name}`
  },
  editParadeTimeframe: {
    actionKey: 'UPDATE_PARADE_TIMEFRAME',
    content: ParadeTimeframeForm,
    title: ({ parade }) => `Edit Pricing Timeframe For ${parade.name}`
  },
  editSystemNotification: {
    actionKey: 'UPDATE_SYSTEM_NOTIFICATION',
    content: SystemNotificationForm,
    title: () => 'Editing System Notification'
  },
  forgotPassword: {
    content: ForgotPassword,
    title: () => 'Forgot Password?'
  },
  managePollImages: {
    content: PollImages,
    title: () => `Managing Images For Survey`
  },
  paradeHomepageColumnSettings: {
    content: HomepageColumnSettingsForm,
    title: () => `Homepage Column Settings`
  },
  paradeHomepageColumnText: {
    content: HomepageColumnTextForm,
    title: () => `Homepage Column Text`
  },
  paradeHomepageRowSettings: {
    content: HomepageRowSettingsForm,
    title: () => `Homepage Row Settings`
  },
  payInvoice: {
    actionKey: 'PAY_INVOICE',
    content: Checkout,
    title: () => 'Pay CPJ Invoice'
  },
  requestJudgeVolunteers: {
    actionKey: 'REQUEST_CONTEST_JUDGE_VOLUNTEERS',
    content: RequestJudgeVolunteerForm,
    title: ({ contest }) => `Request Judging Volunteers For ${contest.name}`
  },
  updateUserHeadshot: {
    actionKey: 'UPDATE_USER_HEADSHOT',
    content: AccountHeadshot,
    title: () => 'Update Profile Image',
    width: 'small'
  },
  viewImage: {
    content: viewImage,
    title: ({ image }) => `Viewing ${image.name}`
  },
  viewContestArchive: {
    content: ArchiveForm,
    title: () => 'Viewing File Archive'
  },
  viewContestRegistration: {
    content: ViewRegistration,
    title: () => 'View Awards Program Registration'
  },
  viewEventRegistration: {
    content: ViewRegistration,
    title: () => 'View Event Registration'
  },
  viewInvoice: {
    content: ViewInvoice,
    title: () => 'View CPJ Invoice Details'
  },
  viewParadeRegistration: {
    content: ViewRegistration,
    title: () => 'View Parade Registration'
  },
  viewParadeAttendance: {
    content: ViewParadeAttendance,
    title: () => 'View Ticket Attendance'
  },
  viewPollVote: {
    content: ViewPollVote,
    title: () => 'View Survey Submission'
  },
  viewScoringProgress: {
    content: ViewScoringProgress,
    title: ({ scoringProgress }) => `Viewing Scoresheet Results For ${scoringProgress.name}`
  },
  
}

export default modalActions;
