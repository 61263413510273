import * as actions from 'src/redux/actions/polls';
import { toast } from 'react-toastify';
import sortBy from 'src/utilities/sort';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.CREATE_POLL_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Survey created.');

      return { ...state, polls: [action.context.poll, ...state.polls] };

    case actions.GET_POLL_REQUEST:
      return { ...state, poll: null };

    case actions.GET_POLL_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return action.context?.poll ? { ...state,poll: action.context.poll } : state;

    case actions.GET_POLL_LIST_REQUEST:
        return { ...state, polls: [] };

    case actions.GET_POLL_LIST_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      return action.context?.polls ? { ...state, polls: action.context.polls } : state;

    case actions.REMOVE_POLL_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.pollID) {
        toast.success('Survey removed.');
        const updatedPolls = state.polls.filter(poll => poll.pollID !== action.context.pollID);
        return { ...state, polls: updatedPolls };
      }

      return state;

    case actions.REMOVE_POLL_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.file) {
        toast.success('Survey image removed.');
        const updatedFiles = state.poll.images.filter(file => {
          return file.fileID !== action.context.file.fileID;
        });
        return { ...state, poll: { ...state.poll, images: updatedFiles, imageRemoved: action.context.file.fileID } };
      }

      return state;

    case actions.REMOVE_POLL_VOTE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.voteID) {
        toast.success('Vote removed.');
        const updatedVotes = state.poll.votes.filter(voter => voter.voteID !== action.context.voteID);
        return { ...state, poll: { ...state.poll, votes: updatedVotes } };
      }

      return state;

    case actions.RESET_POLL_URL_CHANGED:
      const updatedPoll = { ...state.poll, urlChanged: false };
      return { ...state, poll: updatedPoll };

    case actions.UPDATE_POLL_NAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.poll) {
        const updatedPoll = { ...action.context.poll };
        if (action.context?.poll?.url !== state.poll.url) {
          updatedPoll.urlChanged = action.context?.poll?.url;
        }

        toast.success('Survey name updated.');
        return { ...state, poll: {
          ...state.poll,
          ...updatedPoll
        } };
      }
      return state;

    case actions.UPDATE_POLL_QUESTIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.poll) {
        toast.success('Survey configuration updated.');
        return { ...state, poll: {
          ...state.poll,
          ...action.context.poll
        } };
      }
      return state;

    case actions.UPDATE_POLL_VOTING_TIMEFRAME_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.poll) {
        toast.success('Survey voting times updated.');
        return { ...state, poll: {
          ...state.poll,
          ...action.context.poll
        } };
      }
      return state;

    case actions.UPLOAD_POLL_IMAGE_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.file) {
        toast.success('Survey image uploaded.');
        const updatedFiles = sortBy({ key: 'name', list: [...state.poll.images, action.context.file] });
        return { ...state, poll: { ...state.poll, images: updatedFiles } };
      }
      return state;

    default:
      return state;
  }
}

export default reducer;
