import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContestRequest } from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import AppCard from 'src/components/Elements/AppCard';
import { productTypes } from 'src/constants';

const Contest = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    dispatch(getContestRequest({ clientURL: client?.url, contestURL }));
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contest', client, contest }));
    dispatch(updateDrawerContent({
      fetching: contestFetching,
      key: 'contest',
      options: { client, contest } 
    }));
  }, [contest?.name, contest?.url, contestFetching]);

  return (
    <section className="app-grid">
      {
        contestFetching && (
          <div className="app-grid-loading">
              <LoadingCircle variant="dark" />
          </div>
        )
      }
      {
        !contestFetching && (
          <>
            <div className="app-grid-row">
              <AppCard
                className="flex-1"
                title="Online Awards Program Portal"
              >
                <div className="app-grid-title">
                  <img
                    className="app-grid-image-fullsize"
                    src={contest?.bannerImage}
                    alt={contest?.name}
                  />
                  <div className="app-grid-title-details">
                    <h1>
                      {contest?.name}
                    </h1>
                  </div>
                </div>
              </AppCard>
              <div className="card-group">
                <AppCard
                  className="shrinkable"
                  title="Public Landing Page"
                >
                  <Link
                    className="item"
                    target="_blank"
                    to={`https://${client?.url}.cpjam.com/${contest?.url}`}
                  >
                    {`https://${client?.url}.cpjam.com/${contest?.url}`}
                  </Link>
                </AppCard>
                {
                  contest?.type === productTypes.contest && <>
                  <AppCard
                    className="shrinkable"
                    title="Registration Page"
                  >
                    <Link
                      className="item"
                      target="_blank"
                      to={`https://${client?.url}.cpjam.com/${contest?.url}/registration`}
                    >
                      {`https://${client?.url}.cpjam.com/${contest?.url}/registration`}
                    </Link>
                  </AppCard>
                  <AppCard
                    className="shrinkable"
                    title="Entry List Page"
                  >
                    <Link
                      className="item"
                      target="_blank"
                      to={`https://${client?.url}.cpjam.com/${contest?.url}/entries`}
                    >
                      {`https://${client?.url}.cpjam.com/${contest?.url}/entries`}
                    </Link>
                  </AppCard>
                  <AppCard
                    className="shrinkable"
                    title="Scoring List Page"
                  >
                    <Link
                      className="item"
                      target="_blank"
                      to={`https://${client?.url}.cpjam.com/${contest?.url}/scoring`}
                    >
                      {`https://${client?.url}.cpjam.com/${contest?.url}/scoring`}
                    </Link>
                  </AppCard>
                  <AppCard
                    className="shrinkable"
                    title="Become A Partner Page"
                  >
                    <Link
                      className="item"
                      target="_blank"
                      to={`https://${client?.url}.cpjam.com/${contest?.url}/become-a-partner`}
                    >
                      {`https://${client?.url}.cpjam.com/${contest?.url}/become-a-partner`}
                    </Link>
                  </AppCard>
                </>
                }
              </div>
            </div>
          </>
        )
      }
    </section>
  )
}

export default Contest;
