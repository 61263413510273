import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reorderEventCoordinatorsRequest,
  updateEventCoordinatorRequest,
  getEventRequest
} from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import TypeaheadList from 'src/components/TypeaheadList';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import UserProfile from 'src/components/UserProfile';

const EventCoordinators = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const creatingCoordinator = useSelector(state => state.loadingStore.UPDATE_EVENT_COORDINATOR);
  const updatingCoordinators = useSelector(state => state.loadingStore.REORDER_EVENT_COORDINATORS);


  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  const handleCreateCoordinator = (coordinator) => {
    if (coordinator.typedValue) {
      dispatch(
        openModal({
          props: { client, event, coordinator },
          key: 'createManualEventCoordinator'
        })
      );
      return;
    }
    dispatch(updateEventCoordinatorRequest({ clientURL: client.url, eventURL: event.url, form: coordinator }));
  }

  const reorderCoordinatorList = ({ list }) => {
    dispatch(reorderEventCoordinatorsRequest({ clientURL: client.url, eventURL: event.url, form: { coordinators: list } }));
  }

  const actions = [{
    disabled: (coordinator) => !!coordinator?.userID,
    label: 'Edit',
    requiresSelection: true,
    variant: 'contained',
    fn: coordinator => {
      dispatch(
        openModal({
          props: { client, event, coordinator },
          key: 'editManualEventCoordinator'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: coordinator => {
      dispatch(
        openDialog({
          props: { client, event, coordinator },
          key: 'removeEventCoordinator'
        })
      )
    }
  }];

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventCoordinators', client, event }));
    dispatch(updateDrawerContent({
      fetching: eventFetching,
      key: 'eventCoordinators',
      options: { client, event } 
    }));
  }, [eventFetching]);
  
  return (
    <section className="event-coordinators">
      <TypeaheadList
        actions={actions}
        create={handleCreateCoordinator}
        draggable
        fetching={eventFetching}
        handleDrag={reorderCoordinatorList}
        list={event?.coordinators}
        permitTypedValue
        placeholder="Type To Add Coordinator..."
        renderItem={coordinator =>
          <UserProfile
            showEmail
            user={coordinator}
          ></UserProfile>
        }
        renderKey="email"
        selecting={creatingCoordinator}
        title="Coordinators"
        updating={updatingCoordinators}
      />
    </section>
  )
}

export default EventCoordinators;