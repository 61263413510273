import React from 'react';
import { FormHelperText, MenuItem, Select } from '@mui/material';

const SelectField = ({
  element,
  error,
  handleChange,
  handleBlur,
  value
}) => {
  return (
    <div className={`form-field ${element.class || 'flex-1'}`}>
      <label>{element.label}</label>
      <Select
        error={!!error}
        id={element.name}
        name={element.name}
        onBlur={handleBlur}
        onChange={e => {handleChange(e.target.value, element)}}
        value={typeof value === 'undefined' ? '' : value}
      >
        {
          (element.options || []).map(option => {
            return (
              <MenuItem
                key={option.value}
                value={option.value}
              >{option.label}</MenuItem>
            )
          })
        }
      </Select>
      {
        error && <FormHelperText className="Mui-error">{error}</FormHelperText>
      }
      
    </div>
  )
}

export default SelectField;
