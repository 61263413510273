import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadContestEntryFormRequest,
  downloadContestScoresRequest,
  downloadContestWinnersRequest,
  getContestRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, MenuItem, Select } from '@mui/material';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import LoadingCircles from 'src/components/Elements/LoadingCircles';

const ContestFinalReports = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const downloadingEntryForm = useSelector(state => state.loadingStore.DOWNLOAD_CONTEST_ENTRY_FORM);
  const downloadingScores = useSelector(state => state.loadingStore.DOWNLOAD_CONTEST_SCORES);
  const downloadingWinners = useSelector(state => state.loadingStore.DOWNLOAD_CONTEST_WINNERS);
  const [entryForm, setEntryForm] = useState('');
  const [scoresheet, setScoresheet] = useState('');
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  const handleEntryFormChange = ({ value }) => {
    setEntryForm(value);
  }

  const handleScoresheetChange = ({ value }) => {
    setScoresheet(value);
  }

  const handleScoreDownload = () => {
    dispatch(downloadContestScoresRequest({ clientURL: client?.url, contestURL: contest?.url, scoresheetID: scoresheet }));
  }

  const handleEntryFormDownload = () => {
    dispatch(downloadContestEntryFormRequest({ clientURL: client?.url, contestURL: contest?.url, entryFormID: entryForm }))
  }

  const handleWinnersDownload = () => {
    dispatch(downloadContestWinnersRequest({ clientURL: client?.url, contestURL: contest?.url, contestID: contest?.contestID }));
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestFinalReports', client, contest }));
    dispatch(updateDrawerContent({
      fetching: contestFetching,
      key: 'contestFinalReports',
      options: { client, contest } 
    }));
  }, [contestFetching]);

  useEffect(() => {
    if (contest?.entryForms?.length) {
      setEntryForm(contest?.entryForms[0].entryFormID);
    }
  }, [contest?.entryForms]);

  useEffect(() => {
    if (contest?.scoresheets?.length) {
      setScoresheet(contest?.scoresheets[0].scoresheetID);
    }
  }, [contest?.scoresheets]);

  return (
    <section className="final-reports grid">
      <section className="box">
        <div className="box-title">
          <h3>
            {
              !contestFetching && (
                <span>Download Scoring Reports
                {
                  (downloadingScores) && (
                    <LoadingCircle variant="dark" />
                  )
                }
                </span>
              )
            }
            {
              contestFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
        </div>
        {
          !contestFetching && (
            <div className="box-content options">
              <div className="select">
                <label>Select A Scoresheet:</label>
                <Select 
                  onChange={event => handleScoresheetChange({ value: event.target.value })}
                  value={scoresheet}
                >{
                  (contest?.scoresheets || [])?.map(scoresheet => {
                    return (
                      <MenuItem
                        key={scoresheet.scoresheetID}
                        value={scoresheet.scoresheetID}
                      >{scoresheet.name}</MenuItem>
                    )
                  })
                }</Select>
              </div>
              <Button
                color="confirm"
                disabled={downloadingScores}
                onClick={handleScoreDownload}
                variant="contained"
              >
                {
                  downloadingScores && (<LoadingCircles />)
                }
                {
                  !downloadingScores && <>Download Scores</>
                }
              </Button>
            </div>
          )
        }
      </section>
      <section className="box">
        <div className="box-title">
          <h3>
            {
              !contestFetching && (
                <span>Download Entry Form Responses
                {
                  (downloadingEntryForm) && (
                    <LoadingCircle variant="dark" />
                  )
                }
                </span>
              )
            }
            {
              contestFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
        </div>
        {
          !contestFetching && (
            <div className="box-content options">
              <div className="select">
                <label>Select An Entry Form:</label>
                <Select 
                  onChange={event => handleEntryFormChange({ value: event.target.value })}
                  value={entryForm}
                >{
                  (contest?.entryForms || [])?.map(entryForm => {
                    return (
                      <MenuItem
                        key={entryForm.entryFormID}
                        value={entryForm.entryFormID}
                      >{entryForm.name}</MenuItem>
                    )
                  })
                }</Select>
              </div>
              <Button
                color="confirm"
                disabled={downloadingEntryForm}
                onClick={handleEntryFormDownload}
                variant="contained"
              >
                {
                  downloadingEntryForm && (<LoadingCircles />)
                }
                {
                  !downloadingEntryForm && <>Download Entry Form Responses</>
                }
              </Button>
            </div>
          )
        }
      </section>
      <section className="box">
        <div className="box-title">
          <h3>
            {
              !contestFetching && (
                <span>Download Winners Report
                {
                  (downloadingWinners) && (
                    <LoadingCircle variant="dark" />
                  )
                }
                </span>
              )
            }
            {
              contestFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
        </div>
        {
          !contestFetching && (
            <div className="box-content options flex-end">
              <Button
                color="confirm"
                disabled={downloadingWinners}
                onClick={handleWinnersDownload}
                variant="contained"
              >
                {
                  downloadingWinners && (<LoadingCircles />)
                }
                {
                  !downloadingWinners && <>Download Winners</>
                }
              </Button>
            </div>
          )
        }
      </section>
    </section>
  )
}

export default ContestFinalReports;
