import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResourceList from 'src/components/ResourceList';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { getPollListRequest } from 'src/redux/actions/polls';
import { Link, useNavigate } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import { updateDrawerContent } from 'src/redux/actions/drawer';

const Polls = () => {
  const client = useSelector(state => state.clientStore?.client);
  const polls = useSelector(state => state.pollStore?.polls);
  const pollsFetching = useSelector(state => state.loadingStore.GET_POLL_LIST);
  const navigate = useNavigate();
  
  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client },
          key: 'createPoll'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: poll => {
      navigate(poll.url);
    }
  }, {
    label: 'Delete',
    color: 'danger',
    variant: 'contained',
    requiresSelection: true,
    fn: poll => {
      dispatch(
        openDialog({
          props: { client, poll },
          key: 'removePoll'
        })
      )
    }
  }];

  const filters = {
    searchBy: ['name']
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'polls', client }));
    dispatch(updateDrawerContent({ key: 'clientPolls', options: { client } }));

    if (!pollsFetching) {
      dispatch(getPollListRequest({ clientURL: client.url }));
    }
  }, []);

  return (
    <ResourceList
      actions={actions}
      fetching={pollsFetching}
      filters={filters}
      list={polls}
      renderHeaders={() => 
        <div className="poll-list">
          <div className="poll-list-name">Name</div>
        </div>
      }
      renderItem={item => 
        <div className="poll-list">
          <div className="poll-list-name">
            <Link to={item.url}>{item.name}</Link>
          </div>
        </div>
      }
      renderKey="name"
      title="Survey List"
    />
  )
}

export default Polls;
