import React from 'react';
import { currency } from 'src/utilities/strings';

const ViewRegistration = ({ items }) => {
  const balance = items.reduce((acc, item) => acc + (item.quantity * item.price), 0)

  return (
    <section className="view-invoice">
      <div className="view-invoice-item heading">
        <div className="view-invoice-item-name">Name</div>
        <div className="view-invoice-item-price">Price</div>
        <div className="view-invoice-item-quantity">Qty</div>
        <div className="view-invoice-item-total">Total</div>
      </div>
      {
        (items || []).map((item, index) => {
          return (
            <div className="view-invoice-item" key={`${item?.itemName}${index}`}>
              <div className="view-invoice-item-name">{item?.itemName}</div>
              <div className="view-invoice-item-price">{currency(parseFloat(item?.price))}</div>
              <div className="view-invoice-item-quantity">{(item?.quantity || 1)}</div>
              <div className="view-invoice-item-total">{currency((item?.quantity || 1) * parseFloat(item?.price))}</div>
            </div>
          )
        })
      }
      <div className="view-invoice-item summary">
        <div className="view-invoice-item-name">Total</div>
        <div className="view-invoice-item-price"></div>
        <div className="view-invoice-item-quantity"></div>
        <div className="view-invoice-item-total">{currency(balance || 0)}</div>
      </div>
    </section>
  )
}

export default ViewRegistration;
