import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getParadePublicVotingRequest,
  getParadeRequest,
  updateParadePublicVotingRequest
} from 'src/redux/actions/parades';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import { fieldTypes, maximumVotesPerGroup } from 'src/constants';

const ParadePublicVotingOptions = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const fetchingPublicVotes = useSelector(state => state.loadingStore.GET_PARADE_PUBLIC_VOTES);
  const { paradeURL } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  useEffect(() => {
    if (parade?.url && !parade?.publicVotes) {
      dispatch(getParadePublicVotingRequest({ clientURL: client.url, paradeURL }));
    }
  }, [parade?.url]);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradePublicVoting', client, parade }));
    dispatch(updateDrawerContent({
      fetching: paradeFetching,
      key: 'paradePublicVoting',
      options: { client, parade } 
    }));
  }, [paradeFetching]);

  const handleSubmit = (event) => {
    dispatch(updateParadePublicVotingRequest({
      clientURL: client.url,
      paradeURL: parade.url,
      form: event
    }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Enable Public Voting',
          name: 'enablePublicVoting',
          type: fieldTypes.checkbox,
          value: parade?.enablePublicVoting || false
        }, {
          label: 'Maximum Votes Per Group',
          name: 'maxPublicVotes',
          type: fieldTypes.select,
          options: maximumVotesPerGroup,
          value: parade?.maxPublicVotes
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="public-voting grid">
      <section className="box">
        <div className="box-title">
          <h3>
            <span>Public Voting Options
              {
                (paradeFetching || fetchingPublicVotes) && <LoadingCircle variant="dark" />
              }
            </span>
          </h3>
        </div>
        {
          parade && 
          <div className="box-content">
            <p>Public Voting URL: <a
                href={`https://${client.url}.cpjam.com/${parade?.url}/vote`}
                rel="noreferrer"
                target="_blank"
              >{`https://${client.url}.cpjam.com/${parade?.url}/vote`}</a>
            </p>
            <AutoForm
              configuration={configuration}
              handleSubmit={handleSubmit}
              requestPrefix={'UPDATE_PARADE_PUBLIC_VOTING'}
            />
          </div>
        }
      </section>
      {
        !!parade?.publicVotes &&
          <section className="public-voting-results box">
            <div className="box-title">
              <h3>
                Total Number Of Votes Cast
              </h3>
            </div>
            <div className="box-content">
              <p>
                {parade.publicVotes.count || 0} Vote{`${parade.publicVotes.count === 1 ? '' : 's'}`} Cast
              </p>
              {
                (parade?.publicVotes?.propertyGroups || []).map(group => 
                  <div
                    className="public-voting-results-section"
                    key={group.propertyGroupID}
                  >
                    <div className="public-voting-results-section-name">{group.name}</div>
                    {
                      group.properties.map(property => 
                        <div
                          className="public-voting-results-item"
                          key={property.propertyID}
                        >
                          <div className="public-voting-results-item-votes">{property.votes === 1 ? '1 Vote' : `${property.votes} Votes`}</div>
                          <div className="public-voting-results-item-address">{property.address}</div>
                        </div>
                      )
                    }
                  </div>
                )
              }
              {
                (parade?.publicVotes?.properties || []).length &&
                  <div className="public-voting-results-section">
                  {
                    (parade?.publicVotes?.properties || []).map(property => 
                      <div
                        className="public-voting-results-item"
                        key={property.propertyID}
                      >
                        <div className="public-voting-results-item-votes">{property.votes === 1 ? '1 Vote' : `${property.votes} Votes`}</div>
                        <div className="public-voting-results-item-address">{property.address}</div>
                      </div>
                    )
                  }
                  </div>
              }
              
            </div>
          </section>
      }
    </section>
  )
}

export default ParadePublicVotingOptions;
