import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventRequest,
  updateEventLocationRequest
} from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { fieldTypes, inputTypes, states } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import {
  getFirstMinuteToday,
  getLastMinuteToday,
  parseDate,
  parseDateTime
} from 'src/utilities/dates';

const EventLocation = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const [configuration, setConfiguration] = useState(null);
  const [startDate, setStartDate] = useState(event?.startDate);
  const [endDate, setEndDate] = useState(event?.endDate);
  const [allDay, setAllDay] = useState(event?.allDay);
  const [noLocation, setNoLocation] = useState(event?.noLocation);
  const [address, setAddress] = useState(event?.address);
  const [address2, setAddress2] = useState(event?.address2);
  const [city, setCity] = useState(event?.city);
  const [state, setState] = useState(event?.state);
  const [zip, setZip] = useState(event?.zip);

  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  const handleSubmit = (evt) => {
    const startDate = evt.allDay ? parseDate({ date: evt.startDate }) : parseDateTime({ date: evt.startDate });
    const endDate = evt.allDay ? parseDate({ date: evt.endDate }) : parseDateTime({ date: evt.endDate });
    dispatch(updateEventLocationRequest({ clientURL: client.url, eventURL: event.url, form: {
      ...evt, startDate, endDate
    } }));
  }

  const onChange = ({ element, value }) => {
    if (element?.name === 'startDate') {
      setStartDate(value);
    }
    if (element?.name === 'endDate') {
      setEndDate(value);
    }
    if (element?.name === 'allDay') {
      setAllDay(value);
    }
    if (element?.name === 'noLocation') {
      setNoLocation(value);
    }
    if (element?.name === 'address') {
      setAddress(value);
    }
    if (element?.name === 'address2') {
      setAddress2(value);
    }
    if (element?.name === 'city') {
      setCity(value);
    }
    if (element?.name === 'state') {
      setState(value);
    }
    if (element?.name === 'zip') {
      setZip(value);
    }
  }

  const createConfiguration = () => {
    const formElements = {};

    if ((allDay === true || allDay === false) ? allDay : event.allDay) {
      formElements.rows = [{
        elements: [{
          label: 'Start Date',
          name: 'startDate',
          dateRangeEnd: 'endDate',
          type: fieldTypes.input,
          inputType: inputTypes.date,
          value: startDate || event.startDate,
          required: true
        }, {
          label: 'End Date',
          name: 'endDate',
          dateRangeStart: 'startDate',
          type: fieldTypes.input,
          inputType: inputTypes.date,
          value: endDate || event.endDate,
          required: true
        }]
      }]
    } else {
      formElements.rows = [{
        elements: [{
          label: 'Start Date',
          name: 'startDate',
          dateRangeEnd: 'endDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: startDate || event.startDate || getFirstMinuteToday(),
          required: true
        }, {
          label: 'End Date',
          name: 'endDate',
          dateRangeStart: 'startDate',
          type: fieldTypes.input,
          inputType: inputTypes.dateTime,
          value: endDate || event.endDate || getLastMinuteToday(),
          required: true
        }]
      }]
    }

    formElements.rows = [...formElements.rows, ...[{
      elements: [{
        label: 'This Is An All Day Event',
        name: 'allDay',
        type: fieldTypes.checkbox,
        value: (allDay === true || allDay === false) ? allDay : event.allDay
      }]
    }, {
      elements: [{
        label: 'This Event Does Not Have A Physical Location',
        name: 'noLocation',
        type: fieldTypes.checkbox,
        value: noLocation || event.noLocation
      }]
    }, {
      elements: [{
        label: 'Address',
        name: 'address',
        hide: { key: 'noLocation', value: true },
        type: fieldTypes.input,
        inputType: inputTypes.text,
        value: address || event.address
      }]
    }, {
      elements: [{
        label: 'Address (cont)',
        name: 'address2',
        hide: { key: 'noLocation', value: true },
        type: fieldTypes.input,
        inputType: inputTypes.text,
        value: address2 || event.address2
      }]
    }, {
      elements: [{
        class: 'city',
        label: 'City',
        name: 'city',
        hide: { key: 'noLocation', value: true },
        type: fieldTypes.input,
        inputType: inputTypes.text,
        value: city || event.city
      }, {
        class: 'state',
        label: 'State',
        name: 'state',
        hide: { key: 'noLocation', value: true },
        type: fieldTypes.select,
        options: states,
        value: state || event.state || states[0].value
      }, {
        class: 'zip',
        label: 'Zip Code',
        name: 'zip',
        hide: { key: 'noLocation', value: true },
        type: fieldTypes.input,
        inputType: inputTypes.zip,
        value: zip || event.zip
      }]
    }]];

    setConfiguration({
      formElements: { ...formElements },
      submitCTA: 'Save'
    });
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventLocation', client, event }));
    dispatch(updateDrawerContent({
      fetching: eventFetching,
      key: 'eventLocation',
      options: { client, event } 
    }));

    if (!configuration && event) {
      createConfiguration();
    }
  }, [event?.name, event?.url, eventFetching]);

  useEffect(() => {
    if (configuration && event) {
      createConfiguration();
    }
  }, [allDay]);

  return (
    <section className="box">
      <div className="box-title">
        <h3>
            {
              !eventFetching && (
                <span>Location & Date/Time</span>
              )
            }
            {
              eventFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!eventFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            onChange={onChange}
            requestPrefix={'UPDATE_EVENT_LOCATION'}
          />
        </div>
        )
      }
    </section>
  )
}

export default EventLocation;
