import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getContestPublicVotingRequest,
  getContestRequest,
  updateContestPublicVotingRequest
} from 'src/redux/actions/contests';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import { fieldTypes, maximumVotesPerGroup } from 'src/constants';

const ContestPublicVoting = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const fetchingPublicVotes = useSelector(state => state.loadingStore.GET_CONTEST_PUBLIC_VOTES);
  const { contestURL } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    if (contest?.url && !contest.publicVotes) {
      dispatch(getContestPublicVotingRequest({ clientURL: client.url, contestURL }));
    }
  }, [contest?.url]);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestPublicVoting', client, contest }));
    dispatch(updateDrawerContent({
      fetching: contestFetching,
      key: 'contestPublicVoting',
      options: { client, contest } 
    }));
  }, [contestFetching]);

  const handleSubmit = (event) => {
    dispatch(updateContestPublicVotingRequest({
      clientURL: client.url,
      contestURL: contest.url,
      form: event
    }));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Enable Public Voting',
          name: 'enablePublicVoting',
          type: fieldTypes.checkbox,
          value: contest?.enablePublicVoting || false
        }, {
          label: 'Maximum Votes Per Category',
          name: 'maxPublicVotes',
          type: fieldTypes.select,
          options: maximumVotesPerGroup,
          value: contest?.maxPublicVotes
        }]
      }]
    },
    submitCTA: 'Save'
  };

  return (
    <section className="public-voting grid">
      <section className="box">
        <div className="box-title">
          <h3>
            <span>Public Voting Options
              {
                (contestFetching || fetchingPublicVotes) && <LoadingCircle variant="dark" />
              }
            </span>
          </h3>
        </div>
        {
          contest && 
          <div className="box-content">
            <p>Public Voting URL: <a
                href={`https://${client.url}.cpjam.com/${contest?.url}/vote`}
                rel="noreferrer"
                target="_blank"
              >{`https://${client.url}.cpjam.com/${contest?.url}/vote`}</a>
            </p>
            <AutoForm
              configuration={configuration}
              handleSubmit={handleSubmit}
              requestPrefix={'UPDATE_CONTEST_PUBLIC_VOTING'}
            />
          </div>
        }
      </section>
      {
        !!contest?.publicVotes &&
          <section className="box">
            <div className="box-title">
              <h3>
                Total Number Of Votes Cast
              </h3>
            </div>
            <div className="box-content">
              <p>
                {contest.publicVotes.total || 0} Vote{`${contest.publicVotes.total === 1 ? '' : 's'}`} Cast
              </p>
              <p><i>Votes cast per entry are visible under Phase Three: Confirm Winners</i></p>
            </div>
          </section>
      }
    </section>
  )
}

export default ContestPublicVoting;
