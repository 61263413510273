import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getEventRequest,
  updateEventPartnerPageRequest
} from 'src/redux/actions/events';
import TinyMCE from 'src/components/TinyMCE';

const EventPartnerPage = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventPartnerPage', client, event }));
    dispatch(updateDrawerContent({
      fetching: eventFetching,
      key: 'eventPartnerPage',
      options: { client, event } 
    }));
  }, [eventFetching]);

  const handleSubmit = (evt) => {
    dispatch(updateEventPartnerPageRequest({ clientURL: client.url, eventURL: event.url, form: { partnerPageText: evt } }));
  }

  return (
    event && (
      <TinyMCE
        handleSubmit={(event => handleSubmit(event))}
        requestPrefix="UPDATE_EVENT_PARTNER_PAGE"
        title="Partner Page Content"
        value={event?.partnerPageText}
      />
    )
  )
}

export default EventPartnerPage;
