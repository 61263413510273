import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getParadeRequest,
  updateParadePartnerPageRequest
} from 'src/redux/actions/parades';
import TinyMCE from 'src/components/TinyMCE';

const ParadePartnerPage = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradePartnerPage', client, parade }));
    dispatch(updateDrawerContent({
      fetching: paradeFetching,
      key: 'paradePartnerPage',
      options: { client, parade } 
    }));
  }, [paradeFetching]);

  const handleSubmit = (event) => {
    dispatch(updateParadePartnerPageRequest({ clientURL: client.url, paradeURL: parade.url, form: { partnerPageText: event } }));
  }

  return (
    parade && (
      <TinyMCE
        handleSubmit={(event => handleSubmit(event))}
        requestPrefix="UPDATE_PARADE_PARTNER_PAGE"
        title="Partner Page Content"
        value={parade?.partnerPageText}
      />
    )
  )
}

export default ParadePartnerPage;
