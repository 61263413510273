import React from 'react';
import { useDispatch } from 'react-redux';
import AutoForm from 'src/components/AutoForm';
import { fieldTypes, inputTypes } from 'src/constants';
import { updateInvoiceRequest } from 'src/redux/actions/app';
import { parseDate } from 'src/utilities/dates';

const InvoiceForm = ({ clientURL, handleClose, invoice, items }) => {
  const existingItems = ((invoice || {}).items || []).map(item => item.itemID);
  const dispatch = useDispatch();

  const assembleLineItemList = ({ form }) => {
    const reg = new RegExp(/[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}/);
    const list = [];
    for (const key in form) {
      if (key?.length === 11 && reg.test(key)) {
        list.push({
          amount: form[`${key}-amount`],
          itemID: key,
          selected: form[key] === true
        });
      }
    }
    return list;
  }

  const handleSubmit = (event) => {
    const invoiceDate = parseDate({ date: event.invoiceDate });
    const paidDate = event.paidBy === 'Unpaid' ? null : parseDate({ date: event.paidDate });
    const items = assembleLineItemList({ form: event });
    dispatch(updateInvoiceRequest({ clientURL, form: {
      invoiceID: (invoice || {})?.invoiceID,
      invoiceDate,
      paidBy: event.paidBy,
      paidDate,
      items
    } }));
  }

  const paymentStatusOptions = [{
    label: 'Unpaid',
    value: 'Unpaid'
  }, {
    label: 'Credit Card',
    value: 'Credit Card'
  }, {
    label: 'Check',
    value: 'Check'
  }];

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Invoice Date',
          name: 'date',
          type: fieldTypes.input,
          inputType: inputTypes.date,
          value: invoice?.invoiceDate || '',
          required: true
        }]
      }, ...[
        ...(items || []).map(item => {
          return {
            class: 'center',
            elements: [{
              label: `${item.name} <${item.type}>`,
              name: item.itemID,
              type: fieldTypes.checkbox,
              value: existingItems.includes(item.itemID)
            }, {
              label: 'Amount',
              name: `${item.itemID}-amount`,
              type: fieldTypes.input,
              inputType: inputTypes.currency,
              value: item.amount,
              required: true
            }]
          }
        })
      ], {
        elements: [{
          label: 'Payment Status',
          name: 'paidBy',
          type: fieldTypes.select,
          options: paymentStatusOptions,
          value: invoice?.paidBy || 'Unpaid'
        }, {
          label: 'Payment Date',
          name: 'paidDate',
          type: fieldTypes.input,
          inputType: inputTypes.date,
          value: invoice?.paidDate || '',
          hide: { key: 'paidBy', value: 'Unpaid' }
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="invoice-form">
      <AutoForm
        configuration={configuration}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        requestPrefix={'UPDATE_INVOICE'}
      />
    </section>
  )
}

export default InvoiceForm;
