import { call, put, takeEvery } from 'redux-saga/effects';
import handleFetch from 'src/utilities/fetch';
import * as actions from 'src/redux/actions/parades';
import { toast } from 'react-toastify';

async function archiveParade({ clientURL, paradeURL }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/archive`, form: {} });
}

async function createParade({ clientURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades`, form });
}

async function downloadParadeRegistrations({ clientURL, paradeURL, paradeID }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/download-registrations`,
    download: `parade_registrations_${paradeID}.xlsx`
  });
}

async function getParadeList({ clientURL }) {
  return await handleFetch({ url: `/${clientURL}/parades/edit` });
}

async function getParade({ clientURL, paradeURL }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/edit` });
}

async function getParadePublicVotes({ clientURL, paradeURL }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/public-votes` });
}

async function removeParadeDate({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/dates/remove`, form });
}

async function removeParadeDownloadableFile({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/files/remove`, form });
}

async function removeParadeHomePageImage({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/home-page-images/remove`, form });
}

async function removeParadeImage({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/images/remove`, form });
}

async function removeParadeOptionalFee({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/fees/remove`, form });
}

async function removeParadePromoCode({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/promo-codes/remove`, form });
}

async function removeParadePropertyGroup({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/property-groups/remove`, form });
}

async function removeParadeRegistration({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/transactions/remove`, form });
}

async function removeParadeSponsorTier({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/sponsors/tiers/remove`, form });
}

async function removeParadeSupportingProductTimeframe({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/supporting-product-timeframes/remove`, form });
}

async function removeParadeTicket({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/tickets/remove`, form });
}

async function removeParadeTicketTaker({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/ticket-takers/remove`, form });
}

async function removeParadeTicketTakerInvitation({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/ticket-takers/invitation/remove`, form });
}

async function removeParadeTimeframe({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/timeframes/remove`, form });
}

async function reorderParadeOptionalFees({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/fees/order`, form });
}

async function reorderParadePropertyGroups({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/property-groups/order`, form });
}

async function reorderParadeSponsorTiers({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/sponsors/tiers/order`, form });
}

async function reorderParadeTickets({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/tickets/order`, form });
}

async function selectParadeBannerImage({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/banners/select`, form });
}

async function updateParadeConfirmationEmail({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/confirmation-email`, form });
}

async function updateParadeDate({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/dates`, form });
}

async function updateParadeHomePageContent({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/home-page`, form });
}

async function updateParadeName({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/info`, form });
}

async function updateParadeOptionalFee({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/fees`, form });
}

async function updateParadePartnerPage({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/partner-page`, form });
}

async function updateParadePromoCode({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/promo-codes`, form });
}

async function updateParadePropertyGroup({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/property-groups`, form });
}

async function updateParadePublicVoting({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/public-voting`, form });
}

async function updateParadeRegistration({ clientURL, paradeURL, transactionID, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/transactions/${transactionID}`, form });
}

async function updateParadeSEO({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/seo`, form });
}

async function updateParadeSponsorTier({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/sponsors/tiers`, form });
}

async function updateParadeSponsorOptions({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/sponsor-options`, form });
}

async function updateParadeSupportingProductTimeframe({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/supporting-product-timeframes`, form });
}

async function updateParadeTicket({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/tickets`, form });
}

async function updateParadeTicketTaker({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/ticket-takers`, form });
}

async function updateParadeTicketTakerInvitation({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/ticket-takers/invitation`, form });
}

async function updateParadeTimeframe({ clientURL, paradeURL, form }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/timeframes`, form });
}

async function uploadParadeDownloadableFile({ clientURL, paradeURL, file, handleProgress }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/files`, form: { file }, handleProgress });
}

async function uploadParadeHomePageImage({ clientURL, paradeURL, file, handleProgress }) {
  return await handleFetch({ url: `/${clientURL}/parades/${paradeURL}/home-page-images`, form: { file }, handleProgress });
}

function* archiveParadeRequest(request) {
  try {
    const context = yield call(() => archiveParade(request.body));
    yield put({ type: actions.ARCHIVE_PARADE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.ARCHIVE_PARADE_FAILED, error });
  }
}

function* createParadeRequest(request) {
  try {
    const context = yield call(() => createParade(request.body));
    yield put({ type: actions.CREATE_PARADE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.CREATE_PARADE_FAILED, error });
  }
}

function* downloadParadeRegistrationsRequest(request) {
  try {
    const context = yield call(() => downloadParadeRegistrations(request.body));
    yield put({ type: actions.DOWNLOAD_PARADE_REGISTRATIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.DOWNLOAD_PARADE_REGISTRATIONS_FAILED, error });
  }
}

function* getParadeRequest(request) {
  try {
    const context = yield call(() => getParade(request));
    yield put({ type: actions.GET_PARADE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_PARADE_FAILED, error });
  }
}

function* getParadeListRequest(request) {
  try {
    const context = yield call(() => getParadeList(request));
    yield put({ type: actions.GET_PARADE_LIST_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_PARADE_LIST_FAILED, error });
  }
}

function* getParadePublicVotesRequest(request) {
  try {
    const context = yield call(() => getParadePublicVotes(request.body));
    yield put({ type: actions.GET_PARADE_PUBLIC_VOTES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.GET_PARADE_PUBLIC_VOTES_FAILED, error });
  }
}

function* removeParadeDateRequest(request) {
  try {
    const context = yield call(() => removeParadeDate(request.body));
    yield put({ type: actions.REMOVE_PARADE_DATE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_DATE_FAILED, error });
  }
}

function* removeParadeDownloadableFileRequest(request) {
  try {
    const context = yield call(() => removeParadeDownloadableFile(request.body));
    yield put({ type: actions.REMOVE_PARADE_DOWNLOADABLE_FILE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_DOWNLOADABLE_FILE_FAILED, error });
  }
}

function* removeParadeHomePageImageRequest(request) {
  try {
    const context = yield call(() => removeParadeHomePageImage(request.body));
    yield put({ type: actions.REMOVE_PARADE_HOME_PAGE_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_HOME_PAGE_IMAGE_FAILED, error });
  }
}

function* removeParadeImageRequest(request) {
  try {
    const context = yield call(() => removeParadeImage(request.body));
    yield put({ type: actions.REMOVE_PARADE_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_IMAGE_FAILED, error });
  }
}

function* removeParadeOptionalFeeRequest(request) {
  try {
    const context = yield call(() => removeParadeOptionalFee(request.body));
    yield put({ type: actions.REMOVE_PARADE_OPTIONAL_FEE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_OPTIONAL_FEE_FAILED, error });
  }
}

function* removeParadePromoCodeRequest(request) {
  try {
    const context = yield call(() => removeParadePromoCode(request.body));
    yield put({ type: actions.REMOVE_PARADE_PROMO_CODE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_PROMO_CODE_FAILED, error });
  }
}

function* removeParadePropertyGroupRequest(request) {
  try {
    const context = yield call(() => removeParadePropertyGroup(request.body));
    yield put({ type: actions.REMOVE_PARADE_PROPERTY_GROUP_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_PROPERTY_GROUP_FAILED, error });
  }
}

function* removeParadeRegistrationRequest(request) {
  try {
    const context = yield call(() => removeParadeRegistration(request.body));
    yield put({ type: actions.REMOVE_PARADE_REGISTRATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_REGISTRATION_FAILED, error });
  }
}

function* removeParadeSponsorTierRequest(request) {
  try {
    const context = yield call(() => removeParadeSponsorTier(request.body));
    yield put({ type: actions.REMOVE_PARADE_SPONSOR_TIER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_SPONSOR_TIER_FAILED, error });
  }
}

function* removeParadeSupportingProductTimeframeRequest(request) {
  try {
    const context = yield call(() => removeParadeSupportingProductTimeframe(request.body));
    yield put({ type: actions.REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, error });
  }
}

function* removeParadeTicketRequest(request) {
  try {
    const context = yield call(() => removeParadeTicket(request.body));
    yield put({ type: actions.REMOVE_PARADE_TICKET_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_TICKET_FAILED, error });
  }
}

function* removeParadeTicketTakerRequest(request) {
  try {
    const context = yield call(() => removeParadeTicketTaker(request.body));
    yield put({ type: actions.REMOVE_PARADE_TICKET_TAKER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_TICKET_TAKER_FAILED, error });
  }
}

function* removeParadeTicketTakerInvitationRequest(request) {
  try {
    const context = yield call(() => removeParadeTicketTakerInvitation(request.body));
    yield put({ type: actions.REMOVE_PARADE_TICKET_TAKER_INVITATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_TICKET_TAKER_INVITATION_FAILED, error });
  }
}

function* removeParadeTimeframeRequest(request) {
  try {
    const context = yield call(() => removeParadeTimeframe(request.body));
    yield put({ type: actions.REMOVE_PARADE_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REMOVE_PARADE_TIMEFRAME_FAILED, error });
  }
}

function* reorderParadeOptionalFeesRequest(request) {
  try {
    const context = yield call(() => reorderParadeOptionalFees(request.body));
    yield put({ type: actions.REORDER_PARADE_OPTIONAL_FEES_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_PARADE_OPTIONAL_FEES_FAILED, error });
  }
}

function* reorderParadePropertyGroupsRequest(request) {
  try {
    const context = yield call(() => reorderParadePropertyGroups(request.body));
    yield put({ type: actions.REORDER_PARADE_PROPERTY_GROUPS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_PARADE_PROPERTY_GROUPS_FAILED, error });
  }
}

function* reorderParadeSponsorTiersRequest(request) {
  try {
    const context = yield call(() => reorderParadeSponsorTiers(request.body));
    yield put({ type: actions.REORDER_PARADE_SPONSOR_TIERS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_PARADE_SPONSOR_TIERS_FAILED, error });
  }
}

function* reorderParadeTicketsRequest(request) {
  try {
    const context = yield call(() => reorderParadeTickets(request.body));
    yield put({ type: actions.REORDER_PARADE_TICKETS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.REORDER_PARADE_TICKETS_FAILED, error });
  }
}

function* resetParadeURLChanged() {
  yield put({ type: actions.RESET_PARADE_URL_CHANGED_SUCCESS });
}

function* selectParadeBannerImageRequest(request) {
  try {
    const context = yield call(() => selectParadeBannerImage(request.body));
    yield put({ type: actions.SELECT_PARADE_BANNER_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.SELECT_PARADE_BANNER_IMAGE_FAILED, error });
  }
}

function* updateParadeConfirmationEmailRequest(request) {
  try {
    const context = yield call(() => updateParadeConfirmationEmail(request.body));
    yield put({ type: actions.UPDATE_PARADE_CONFIRMATION_EMAIL_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_CONFIRMATION_EMAIL_FAILED, error });
  }
}

function* updateParadeDateRequest(request) {
  try {
    const context = yield call(() => updateParadeDate(request.body));
    yield put({ type: actions.UPDATE_PARADE_DATE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_DATE_FAILED, error });
  }
}

function* updateParadeHomePageContentRequest(request) {
  try {
    const context = yield call(() => updateParadeHomePageContent(request.body));
    yield put({ type: actions.UPDATE_PARADE_HOME_PAGE_CONTENT_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_HOME_PAGE_CONTENT_FAILED, error });
  }
}

function* updateParadeNameRequest(request) {
  try {
    const context = yield call(() => updateParadeName(request.body));
    yield put({ type: actions.UPDATE_PARADE_NAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_NAME_FAILED, error });
  }
}

function* updateParadeOptionalFeeRequest(request) {
  try {
    const context = yield call(() => updateParadeOptionalFee(request.body));
    yield put({ type: actions.UPDATE_PARADE_OPTIONAL_FEE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_OPTIONAL_FEE_FAILED, error });
  }
}

function* updateParadePartnerPageRequest(request) {
  try {
    const context = yield call(() => updateParadePartnerPage(request.body));
    yield put({ type: actions.UPDATE_PARADE_PARTNER_PAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_PARTNER_PAGE_FAILED, error });
  }
}

function* updateParadePromoCodeRequest(request) {
  try {
    const context = yield call(() => updateParadePromoCode(request.body));
    yield put({ type: actions.UPDATE_PARADE_PROMO_CODE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_PROMO_CODE_FAILED, error });
  }
}

function* updateParadePropertyGroupRequest(request) {
  try {
    const context = yield call(() => updateParadePropertyGroup(request.body));
    yield put({ type: actions.UPDATE_PARADE_PROPERTY_GROUP_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_PROPERTY_GROUP_FAILED, error });
  }
}

function* updateParadePublicVotingRequest(request) {
  try {
    const context = yield call(() => updateParadePublicVoting(request.body));
    yield put({ type: actions.UPDATE_PARADE_PUBLIC_VOTING_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_PUBLIC_VOTING_FAILED, error });
  }
}

function* updateParadeRegistrationRequest(request) {
  try {
    const context = yield call(() => updateParadeRegistration(request.body));
    yield put({ type: actions.UPDATE_PARADE_REGISTRATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_REGISTRATION_FAILED, error });
  }
}

function* updateParadeSEORequest(request) {
  try {
    const context = yield call(() => updateParadeSEO(request.body));
    yield put({ type: actions.UPDATE_PARADE_SEO_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_SEO_FAILED, error });
  }
}

function* updateParadeSponsorTierRequest(request) {
  try {
    const context = yield call(() => updateParadeSponsorTier(request.body));
    yield put({ type: actions.UPDATE_PARADE_SPONSOR_TIER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_SPONSOR_TIER_FAILED, error });
  }
}

function* updateParadeSponsorOptionsRequest(request) {
  try {
    const context = yield call(() => updateParadeSponsorOptions(request.body));
    yield put({ type: actions.UPDATE_PARADE_SPONSOR_OPTIONS_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_SPONSOR_OPTIONS_FAILED, error });
  }
}

function* updateParadeSupportingProductTimeframeRequest(request) {
  try {
    const context = yield call(() => updateParadeSupportingProductTimeframe(request.body));
    yield put({ type: actions.UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, error });
  }
}

function* updateParadeTicketRequest(request) {
  try {
    const context = yield call(() => updateParadeTicket(request.body));
    yield put({ type: actions.UPDATE_PARADE_TICKET_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_TICKET_FAILED, error });
  }
}

function* updateParadeTicketTakerRequest(request) {
  try {
    const context = yield call(() => updateParadeTicketTaker(request.body));
    yield put({ type: actions.UPDATE_PARADE_TICKET_TAKER_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_TICKET_TAKER_FAILED, error });
  }
}

function* updateParadeTicketTakerInvitationRequest(request) {
  try {
    const context = yield call(() => updateParadeTicketTakerInvitation(request.body));
    yield put({ type: actions.UPDATE_PARADE_TICKET_TAKER_INVITATION_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_TICKET_TAKER_INVITATION_FAILED, error });
  }
}

function* updateParadeTimeframeRequest(request) {
  try {
    const context = yield call(() => updateParadeTimeframe(request.body));
    yield put({ type: actions.UPDATE_PARADE_TIMEFRAME_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPDATE_PARADE_TIMEFRAME_FAILED, error });
  }
}

function* uploadParadeDownloadableFileRequest(request) {
  try {
    const context = yield call(() => uploadParadeDownloadableFile(request.body));
    yield put({ type: actions.UPLOAD_PARADE_DOWNLOADABLE_FILE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPLOAD_PARADE_DOWNLOADABLE_FILE_FAILED, error });
  }
}

function* uploadParadeHomePageImageRequest(request) {
  try {
    const context = yield call(() => uploadParadeHomePageImage(request.body));
    yield put({ type: actions.UPLOAD_PARADE_HOME_PAGE_IMAGE_SUCCESS, context });
  } catch (error) {
    yield put({ type: actions.UPLOAD_PARADE_HOME_PAGE_IMAGE_FAILED, error });
  }
}

function apiFailed(request) {
  toast.error(request.error.message);
}

function* paradeSagas() {
  yield takeEvery(actions.ARCHIVE_PARADE_REQUEST, archiveParadeRequest);
  yield takeEvery(actions.CREATE_PARADE_REQUEST, createParadeRequest);
  yield takeEvery(actions.DOWNLOAD_PARADE_REGISTRATIONS_REQUEST, downloadParadeRegistrationsRequest);
  yield takeEvery(actions.GET_PARADE_REQUEST, getParadeRequest);
  yield takeEvery(actions.GET_PARADE_LIST_REQUEST, getParadeListRequest);
  yield takeEvery(actions.GET_PARADE_PUBLIC_VOTES_REQUEST, getParadePublicVotesRequest);
  yield takeEvery(actions.REMOVE_PARADE_DATE_REQUEST, removeParadeDateRequest);
  yield takeEvery(actions.REMOVE_PARADE_DOWNLOADABLE_FILE_REQUEST, removeParadeDownloadableFileRequest);
  yield takeEvery(actions.REMOVE_PARADE_HOME_PAGE_IMAGE_REQUEST, removeParadeHomePageImageRequest);
  yield takeEvery(actions.REMOVE_PARADE_IMAGE_REQUEST, removeParadeImageRequest);
  yield takeEvery(actions.REMOVE_PARADE_OPTIONAL_FEE_REQUEST, removeParadeOptionalFeeRequest);
  yield takeEvery(actions.REMOVE_PARADE_PROMO_CODE_REQUEST, removeParadePromoCodeRequest);
  yield takeEvery(actions.REMOVE_PARADE_PROPERTY_GROUP_REQUEST, removeParadePropertyGroupRequest);
  yield takeEvery(actions.REMOVE_PARADE_REGISTRATION_REQUEST, removeParadeRegistrationRequest);
  yield takeEvery(actions.REMOVE_PARADE_SPONSOR_TIER_REQUEST, removeParadeSponsorTierRequest);
  yield takeEvery(actions.REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST, removeParadeSupportingProductTimeframeRequest);
  yield takeEvery(actions.REMOVE_PARADE_TICKET_REQUEST, removeParadeTicketRequest);
  yield takeEvery(actions.REMOVE_PARADE_TICKET_TAKER_REQUEST, removeParadeTicketTakerRequest);
  yield takeEvery(actions.REMOVE_PARADE_TICKET_TAKER_INVITATION_REQUEST, removeParadeTicketTakerInvitationRequest);
  yield takeEvery(actions.REMOVE_PARADE_TIMEFRAME_REQUEST, removeParadeTimeframeRequest);
  yield takeEvery(actions.REORDER_PARADE_OPTIONAL_FEES_REQUEST, reorderParadeOptionalFeesRequest);
  yield takeEvery(actions.REORDER_PARADE_PROPERTY_GROUPS_REQUEST, reorderParadePropertyGroupsRequest);
  yield takeEvery(actions.REORDER_PARADE_SPONSOR_TIERS_REQUEST, reorderParadeSponsorTiersRequest);
  yield takeEvery(actions.REORDER_PARADE_TICKETS_REQUEST, reorderParadeTicketsRequest);
  yield takeEvery(actions.RESET_PARADE_URL_CHANGED, resetParadeURLChanged);
  yield takeEvery(actions.SELECT_PARADE_BANNER_IMAGE_REQUEST, selectParadeBannerImageRequest);
  yield takeEvery(actions.UPDATE_PARADE_CONFIRMATION_EMAIL_REQUEST, updateParadeConfirmationEmailRequest);
  yield takeEvery(actions.UPDATE_PARADE_DATE_REQUEST, updateParadeDateRequest);
  yield takeEvery(actions.UPDATE_PARADE_HOME_PAGE_CONTENT_REQUEST, updateParadeHomePageContentRequest);
  yield takeEvery(actions.UPDATE_PARADE_NAME_REQUEST, updateParadeNameRequest);
  yield takeEvery(actions.UPDATE_PARADE_OPTIONAL_FEE_REQUEST, updateParadeOptionalFeeRequest);
  yield takeEvery(actions.UPDATE_PARADE_PARTNER_PAGE_REQUEST, updateParadePartnerPageRequest);
  yield takeEvery(actions.UPDATE_PARADE_PROMO_CODE_REQUEST, updateParadePromoCodeRequest);
  yield takeEvery(actions.UPDATE_PARADE_PROPERTY_GROUP_REQUEST, updateParadePropertyGroupRequest);
  yield takeEvery(actions.UPDATE_PARADE_PUBLIC_VOTING_REQUEST, updateParadePublicVotingRequest);
  yield takeEvery(actions.UPDATE_PARADE_REGISTRATION_REQUEST, updateParadeRegistrationRequest);
  yield takeEvery(actions.UPDATE_PARADE_SEO_REQUEST, updateParadeSEORequest);
  yield takeEvery(actions.UPDATE_PARADE_SPONSOR_TIER_REQUEST, updateParadeSponsorTierRequest);
  yield takeEvery(actions.UPDATE_PARADE_SPONSOR_OPTIONS_REQUEST, updateParadeSponsorOptionsRequest);
  yield takeEvery(actions.UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST, updateParadeSupportingProductTimeframeRequest);
  yield takeEvery(actions.UPDATE_PARADE_TICKET_REQUEST, updateParadeTicketRequest);
  yield takeEvery(actions.UPDATE_PARADE_TICKET_TAKER_REQUEST, updateParadeTicketTakerRequest);
  yield takeEvery(actions.UPDATE_PARADE_TICKET_TAKER_INVITATION_REQUEST, updateParadeTicketTakerInvitationRequest);
  yield takeEvery(actions.UPDATE_PARADE_TIMEFRAME_REQUEST, updateParadeTimeframeRequest);
  yield takeEvery(actions.UPLOAD_PARADE_DOWNLOADABLE_FILE_REQUEST, uploadParadeDownloadableFileRequest);
  yield takeEvery(actions.UPLOAD_PARADE_HOME_PAGE_IMAGE_REQUEST, uploadParadeHomePageImageRequest);

  yield takeEvery(actions.ARCHIVE_PARADE_FAILED, apiFailed);
  yield takeEvery(actions.CREATE_PARADE_FAILED, apiFailed);
  yield takeEvery(actions.DOWNLOAD_PARADE_REGISTRATIONS_FAILED, apiFailed);
  yield takeEvery(actions.GET_PARADE_FAILED, apiFailed);
  yield takeEvery(actions.GET_PARADE_LIST_FAILED, apiFailed);
  yield takeEvery(actions.GET_PARADE_PUBLIC_VOTES_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_DATE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_DOWNLOADABLE_FILE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_HOME_PAGE_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_OPTIONAL_FEE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_PROMO_CODE_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_PROPERTY_GROUP_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_REGISTRATION_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_SPONSOR_TIER_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_TICKET_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_TICKET_TAKER_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_TICKET_TAKER_INVITATION_FAILED, apiFailed);
  yield takeEvery(actions.REMOVE_PARADE_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_PARADE_OPTIONAL_FEES_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_PARADE_PROPERTY_GROUPS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_PARADE_SPONSOR_TIERS_FAILED, apiFailed);
  yield takeEvery(actions.REORDER_PARADE_TICKETS_FAILED, apiFailed);
  yield takeEvery(actions.SELECT_PARADE_BANNER_IMAGE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_CONFIRMATION_EMAIL_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_DATE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_HOME_PAGE_CONTENT_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_NAME_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_OPTIONAL_FEE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_PARTNER_PAGE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_PROMO_CODE_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_PROPERTY_GROUP_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_PUBLIC_VOTING_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_REGISTRATION_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_SEO_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_SPONSOR_TIER_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_SPONSOR_OPTIONS_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_TICKET_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_TICKET_TAKER_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_TICKET_TAKER_INVITATION_FAILED, apiFailed);
  yield takeEvery(actions.UPDATE_PARADE_TIMEFRAME_FAILED, apiFailed);
  yield takeEvery(actions.UPLOAD_PARADE_DOWNLOADABLE_FILE_FAILED, apiFailed);
  yield takeEvery(actions.UPLOAD_PARADE_HOME_PAGE_IMAGE_FAILED, apiFailed);
}

export default paradeSagas;
