import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  updateContestGalleryRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fieldTypes, yesOrNo } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ContestWinnersGallery = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestGallery', client, contest }));
    dispatch(updateDrawerContent({
      fetching: contestFetching,
      key: 'contestGallery',
      options: { client, contest } 
    }));
  }, [contestFetching]);

  const handleSubmit = (event) => {
    dispatch(updateContestGalleryRequest({ clientURL: client.url, contestURL: contest.url, form: event }));
  };

  const configuration = {
    formElements: {
      rows: [{
        forward: 'Do NOT change these settings until the awards program is complete and winners have been previously notified.'
      }, {
        elements: [{
          label: 'Activate Winners Gallery',
          name: 'galleryActive',
          type: fieldTypes.select,
          value: contest?.galleryActive,
          options: yesOrNo
        }]
      }]
    },
    submitCTA: 'Save',
    cancelCTA: 'Cancel'
  };

  return (
    <section className="box">
    <div className="box-title">
      <h3>
          {
            !contestFetching && (
              <span>Winners Gallery Settings</span>
            )
          }
          {
            contestFetching && (
              <LoadingCircle variant="dark" />
            )
          }
        </h3>
    </div>
    {
      (!contestFetching && configuration) && (
      <div className="box-content">
        <AutoForm
          configuration={configuration}
          handleSubmit={handleSubmit}
          requestPrefix={'UPDATE_CONTEST_GALLERY'}
        />
        {
          !!contest?.galleryActive && <>
            <hr />
            Public Winners Gallery Page: <Link
              target="_blank"
              to={`https://${client.url}.cpjam.com/${contestURL}/gallery`}
            >{`https://${client.url}.cpjam.com/${contestURL}/gallery`}</Link>
          </>
        }
      </div>
      )
    }
    </section>
  )
}

export default ContestWinnersGallery;
