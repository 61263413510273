import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getParadeRequest,
  uploadParadeDownloadableFileRequest
} from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import ResourceList from 'src/components/ResourceList';
import { openDialog } from 'src/redux/actions/dialog';
import { toast } from 'react-toastify';

const ParadeDownloadableFiles = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const [progress, setProgress] = useState();
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  const actions = [{
    fileUpload: {
      label: 'Upload',
      multiple: true,
      onChange: file => {
        dispatch(uploadParadeDownloadableFileRequest({ clientURL: client.url, paradeURL: parade.url, file, handleProgress: setProgress }));
      },
      requestPrefix: 'UPLOAD_PARADE_DOWNLOADABLE_FILE'
    }
  }, {
    color: 'confirm',
    label: 'Copy URL',
    requiresSelection: true,
    variant: 'contained',
    fn: file => {
      navigator.clipboard.writeText(file.url);
      toast.success('Link copied to clipboard.');
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: file => {
      dispatch(
        openDialog({
          props: { client, parade, file },
          key: 'removeParadeDownloadableFile'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeDownloadableFiles', client, parade }));
    dispatch(updateDrawerContent({
      fetching: paradeFetching,
      key: 'paradeDownloadableFiles',
      options: { client, parade } 
    }));
  }, [paradeFetching]);
  
  return (
    <ResourceList
      actions={actions}
      fetching={paradeFetching}
      list={parade?.files}
      renderItem={item => 
        <div className="downloadable-file">
          <a href={item.url}><span className="downloadable-file-name">{item.name}</span></a>
          <span className="downloadable-file-size">{item.size}</span>
        </div>
      }
      renderKey="name"
      progress={(!!progress && progress < 100) && progress}
      title="Downloadable Files"
    />
  )
}

export default ParadeDownloadableFiles;