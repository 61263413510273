import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadPollSubmissionsRequest, getPollRequest } from 'src/redux/actions/polls';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import ResourceList from 'src/components/ResourceList';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';

const PollVoterList = () => {
  const client = useSelector(state => state.clientStore?.client);
  const poll = useSelector(state => state.pollStore?.poll);
  const pollFetching = useSelector(state => state.loadingStore.GET_POLL);
  const { pollURL } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!pollURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!poll) {
      dispatch(getPollRequest({ clientURL: client.url, pollURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'pollVoterList', client, poll }));
    dispatch(updateDrawerContent({
      fetching: pollFetching,
      key: 'pollVoterList',
      options: { client, poll } 
    }));
  }, [pollFetching]);

  const actions = [{
    color: 'confirm',
    label: 'Download All',
    variant: 'contained',
    fn: () => {
      dispatch(downloadPollSubmissionsRequest({
        clientURL: client.url,
        pollID: poll.pollID,
        pollURL: poll.url
      }))
    }
  }, {
    label: 'View',
    requiresSelection: true,
    fn: submission => {
      dispatch(openModal({
        props: { client, poll, submission },
        key: 'viewPollVote'
      }))
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: voter => {
      dispatch(
        openDialog({
          props: { client, poll, voter },
          key: 'removePollVoter'
        })
      )
    }
  }];

  return (
    <ResourceList
      actions={actions}
      fetching={pollFetching}
      list={poll?.votes}
      renderItem={voter => 
        <div className="voter-list">
          <div className="voter-list-name">{voter.email || '[Not Logged In]'}</div>
          <div className="voter-list-date">{voter.date}</div>
        </div>
      }
      renderKey="voteID"
      title="Survey Submissions"
    />
  )
}

export default PollVoterList;
