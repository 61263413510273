export const ARCHIVE_PARADE_REQUEST = 'ARCHIVE_PARADE_REQUEST';
export const ARCHIVE_PARADE_SUCCESS = 'ARCHIVE_PARADE_SUCCESS';
export const ARCHIVE_PARADE_FAILED = 'ARCHIVE_PARADE_FAILED';

export const CLEAR_PARADE_PROPERTY_REMOVED = 'CLEAR_PARADE_PROPERTY_REMOVED';
export const CLEAR_PARADE_PROPERTY_REMOVED_SUCCESS = 'CLEAR_PARADE_PROPERTY_REMOVED_SUCCESS';

export const CREATE_PARADE_REQUEST = 'CREATE_PARADE_REQUEST';
export const CREATE_PARADE_SUCCESS = 'CREATE_PARADE_SUCCESS';
export const CREATE_PARADE_FAILED = 'CREATE_PARADE_FAILED';

export const DOWNLOAD_PARADE_REGISTRATIONS_REQUEST = 'DOWNLOAD_PARADE_REGISTRATIONS_REQUEST';
export const DOWNLOAD_PARADE_REGISTRATIONS_SUCCESS = 'DOWNLOAD_PARADE_REGISTRATIONS_SUCCESS';
export const DOWNLOAD_PARADE_REGISTRATIONS_FAILED = 'DOWNLOAD_PARADE_REGISTRATIONS_FAILED';

export const GET_PARADE_REQUEST = 'GET_PARADE_REQUEST';
export const GET_PARADE_SUCCESS = 'GET_PARADE_SUCCESS';
export const GET_PARADE_FAILED = 'GET_PARADE_FAILED';

export const GET_PARADE_LIST_REQUEST = 'GET_PARADE_LIST_REQUEST';
export const GET_PARADE_LIST_SUCCESS = 'GET_PARADE_LIST_SUCCESS';
export const GET_PARADE_LIST_FAILED = 'GET_PARADE_LIST_FAILED';

export const GET_PARADE_PUBLIC_VOTES_REQUEST = 'GET_PARADE_PUBLIC_VOTES_REQUEST';
export const GET_PARADE_PUBLIC_VOTES_SUCCESS = 'GET_PARADE_PUBLIC_VOTES_SUCCESS';
export const GET_PARADE_PUBLIC_VOTES_FAILED = 'GET_PARADE_PUBLIC_VOTES_FAILED';

export const REMOVE_PARADE_DATE_REQUEST = 'REMOVE_PARADE_DATE_REQUEST';
export const REMOVE_PARADE_DATE_SUCCESS = 'REMOVE_PARADE_DATE_SUCCESS';
export const REMOVE_PARADE_DATE_FAILED = 'REMOVE_PARADE_DATE_FAILED';

export const REMOVE_PARADE_DOWNLOADABLE_FILE_REQUEST = 'REMOVE_PARADE_DOWNLOADABLE_FILE_REQUEST';
export const REMOVE_PARADE_DOWNLOADABLE_FILE_SUCCESS = 'REMOVE_PARADE_DOWNLOADABLE_FILE_SUCCESS';
export const REMOVE_PARADE_DOWNLOADABLE_FILE_FAILED = 'REMOVE_PARADE_DOWNLOADABLE_FILE_FAILED';

export const REMOVE_PARADE_HOME_PAGE_IMAGE_REQUEST = 'REMOVE_PARADE_HOME_PAGE_IMAGE_REQUEST';
export const REMOVE_PARADE_HOME_PAGE_IMAGE_SUCCESS = 'REMOVE_PARADE_HOME_PAGE_IMAGE_SUCCESS';
export const REMOVE_PARADE_HOME_PAGE_IMAGE_FAILED = 'REMOVE_PARADE_HOME_PAGE_IMAGE_FAILED';

export const REMOVE_PARADE_IMAGE_REQUEST = 'REMOVE_PARADE_IMAGE_REQUEST';
export const REMOVE_PARADE_IMAGE_SUCCESS = 'REMOVE_PARADE_IMAGE_SUCCESS';
export const REMOVE_PARADE_IMAGE_FAILED = 'REMOVE_PARADE_IMAGE_FAILED';

export const REMOVE_PARADE_OPTIONAL_FEE_REQUEST = 'REMOVE_PARADE_OPTIONAL_FEE_REQUEST';
export const REMOVE_PARADE_OPTIONAL_FEE_SUCCESS = 'REMOVE_PARADE_OPTIONAL_FEE_SUCCESS';
export const REMOVE_PARADE_OPTIONAL_FEE_FAILED = 'REMOVE_PARADE_OPTIONAL_FEE_FAILED';

export const REMOVE_PARADE_PROMO_CODE_REQUEST = 'REMOVE_PARADE_PROMO_CODE_REQUEST';
export const REMOVE_PARADE_PROMO_CODE_SUCCESS = 'REMOVE_PARADE_PROMO_CODE_SUCCESS';
export const REMOVE_PARADE_PROMO_CODE_FAILED = 'REMOVE_PARADE_PROMO_CODE_FAILED';

export const REMOVE_PARADE_PROPERTY_GROUP_REQUEST = 'REMOVE_PARADE_PROPERTY_GROUP_REQUEST';
export const REMOVE_PARADE_PROPERTY_GROUP_SUCCESS = 'REMOVE_PARADE_PROPERTY_GROUP_SUCCESS';
export const REMOVE_PARADE_PROPERTY_GROUP_FAILED = 'REMOVE_PARADE_PROPERTY_GROUP_FAILED';

export const REMOVE_PARADE_REGISTRATION_REQUEST = 'REMOVE_PARADE_REGISTRATION_REQUEST';
export const REMOVE_PARADE_REGISTRATION_SUCCESS = 'REMOVE_PARADE_REGISTRATION_SUCCESS';
export const REMOVE_PARADE_REGISTRATION_FAILED = 'REMOVE_PARADE_REGISTRATION_FAILED';

export const REMOVE_PARADE_SPONSOR_TIER_REQUEST = 'REMOVE_PARADE_SPONSOR_TIER_REQUEST';
export const REMOVE_PARADE_SPONSOR_TIER_SUCCESS = 'REMOVE_PARADE_SPONSOR_TIER_SUCCESS';
export const REMOVE_PARADE_SPONSOR_TIER_FAILED = 'REMOVE_PARADE_SPONSOR_TIER_FAILED';

export const REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST = 'REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST';
export const REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS = 'REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS';
export const REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_FAILED = 'REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_FAILED';

export const REMOVE_PARADE_TICKET_REQUEST = 'REMOVE_PARADE_TICKET_REQUEST';
export const REMOVE_PARADE_TICKET_SUCCESS = 'REMOVE_PARADE_TICKET_SUCCESS';
export const REMOVE_PARADE_TICKET_FAILED = 'REMOVE_PARADE_TICKET_FAILED';

export const REMOVE_PARADE_TICKET_TAKER_REQUEST = 'REMOVE_PARADE_TICKET_TAKER_REQUEST';
export const REMOVE_PARADE_TICKET_TAKER_SUCCESS = 'REMOVE_PARADE_TICKET_TAKER_SUCCESS';
export const REMOVE_PARADE_TICKET_TAKER_FAILED = 'REMOVE_PARADE_TICKET_TAKER_FAILED';

export const REMOVE_PARADE_TICKET_TAKER_INVITATION_REQUEST = 'REMOVE_PARADE_TICKET_TAKER_INVITATION_REQUEST';
export const REMOVE_PARADE_TICKET_TAKER_INVITATION_SUCCESS = 'REMOVE_PARADE_TICKET_TAKER_INVITATION_SUCCESS';
export const REMOVE_PARADE_TICKET_TAKER_INVITATION_FAILED = 'REMOVE_PARADE_TICKET_TAKER_INVITATION_FAILED';

export const REMOVE_PARADE_TIMEFRAME_REQUEST = 'REMOVE_PARADE_TIMEFRAME_REQUEST';
export const REMOVE_PARADE_TIMEFRAME_SUCCESS = 'REMOVE_PARADE_TIMEFRAME_SUCCESS';
export const REMOVE_PARADE_TIMEFRAME_FAILED = 'REMOVE_PARADE_TIMEFRAME_FAILED';

export const REORDER_PARADE_OPTIONAL_FEES_REQUEST = 'REORDER_PARADE_OPTIONAL_FEES_REQUEST';
export const REORDER_PARADE_OPTIONAL_FEES_SUCCESS = 'REORDER_PARADE_OPTIONAL_FEES_SUCCESS';
export const REORDER_PARADE_OPTIONAL_FEES_FAILED = 'REORDER_PARADE_OPTIONAL_FEES_FAILED';

export const REORDER_PARADE_PROPERTY_GROUPS_REQUEST = 'REORDER_PARADE_PROPERTY_GROUPS_REQUEST';
export const REORDER_PARADE_PROPERTY_GROUPS_SUCCESS = 'REORDER_PARADE_PROPERTY_GROUPS_SUCCESS';
export const REORDER_PARADE_PROPERTY_GROUPS_FAILED = 'REORDER_PARADE_PROPERTY_GROUPS_FAILED';

export const REORDER_PARADE_SPONSOR_TIERS_REQUEST = 'REORDER_PARADE_SPONSOR_TIERS_REQUEST';
export const REORDER_PARADE_SPONSOR_TIERS_SUCCESS = 'REORDER_PARADE_SPONSOR_TIERS_SUCCESS';
export const REORDER_PARADE_SPONSOR_TIERS_FAILED = 'REORDER_PARADE_SPONSOR_TIERS_FAILED';

export const REORDER_PARADE_TICKETS_REQUEST = 'REORDER_PARADE_TICKETS_REQUEST';
export const REORDER_PARADE_TICKETS_SUCCESS = 'REORDER_PARADE_TICKETS_SUCCESS';
export const REORDER_PARADE_TICKETS_FAILED = 'REORDER_PARADE_TICKETS_FAILED';

export const RESET_PARADE_URL_CHANGED = 'RESET_PARADE_URL_CHANGED';
export const RESET_PARADE_URL_CHANGED_SUCCESS = 'RESET_PARADE_URL_CHANGED_SUCCESS';

export const SELECT_PARADE_BANNER_IMAGE_REQUEST = 'SELECT_PARADE_BANNER_IMAGE_REQUEST';
export const SELECT_PARADE_BANNER_IMAGE_SUCCESS = 'SELECT_PARADE_BANNER_IMAGE_SUCCESS';
export const SELECT_PARADE_BANNER_IMAGE_FAILED = 'SELECT_PARADE_BANNER_IMAGE_FAILED';

export const UPDATE_PARADE_CONFIRMATION_EMAIL_REQUEST = 'UPDATE_PARADE_CONFIRMATION_EMAIL_REQUEST';
export const UPDATE_PARADE_CONFIRMATION_EMAIL_SUCCESS = 'UPDATE_PARADE_CONFIRMATION_EMAIL_SUCCESS';
export const UPDATE_PARADE_CONFIRMATION_EMAIL_FAILED = 'UPDATE_PARADE_CONFIRMATION_EMAIL_FAILED';

export const UPDATE_PARADE_DATE_REQUEST = 'UPDATE_PARADE_DATE_REQUEST';
export const UPDATE_PARADE_DATE_SUCCESS = 'UPDATE_PARADE_DATE_SUCCESS';
export const UPDATE_PARADE_DATE_FAILED = 'UPDATE_PARADE_DATE_FAILED';

export const UPDATE_PARADE_HOME_PAGE_CONTENT_REQUEST = 'UPDATE_PARADE_HOME_PAGE_CONTENT_REQUEST';
export const UPDATE_PARADE_HOME_PAGE_CONTENT_SUCCESS = 'UPDATE_PARADE_HOME_PAGE_CONTENT_SUCCESS';
export const UPDATE_PARADE_HOME_PAGE_CONTENT_FAILED = 'UPDATE_PARADE_HOME_PAGE_CONTENT_FAILED';

export const UPDATE_PARADE_NAME_REQUEST = 'UPDATE_PARADE_NAME_REQUEST';
export const UPDATE_PARADE_NAME_SUCCESS = 'UPDATE_PARADE_NAME_SUCCESS';
export const UPDATE_PARADE_NAME_FAILED = 'UPDATE_PARADE_NAME_FAILED';

export const UPDATE_PARADE_OPTIONAL_FEE_REQUEST = 'UPDATE_PARADE_OPTIONAL_FEE_REQUEST';
export const UPDATE_PARADE_OPTIONAL_FEE_SUCCESS = 'UPDATE_PARADE_OPTIONAL_FEE_SUCCESS';
export const UPDATE_PARADE_OPTIONAL_FEE_FAILED = 'UPDATE_PARADE_OPTIONAL_FEE_FAILED';

export const UPDATE_PARADE_PARTNER_PAGE_REQUEST = 'UPDATE_PARADE_PARTNER_PAGE_REQUEST';
export const UPDATE_PARADE_PARTNER_PAGE_SUCCESS = 'UPDATE_PARADE_PARTNER_PAGE_SUCCESS';
export const UPDATE_PARADE_PARTNER_PAGE_FAILED = 'UPDATE_PARADE_PARTNER_PAGE_FAILED';

export const UPDATE_PARADE_PROMO_CODE_REQUEST = 'UPDATE_PARADE_PROMO_CODE_REQUEST';
export const UPDATE_PARADE_PROMO_CODE_SUCCESS = 'UPDATE_PARADE_PROMO_CODE_SUCCESS';
export const UPDATE_PARADE_PROMO_CODE_FAILED = 'UPDATE_PARADE_PROMO_CODE_FAILED';

export const UPDATE_PARADE_PROPERTY_GROUP_REQUEST = 'UPDATE_PARADE_PROPERTY_GROUP_REQUEST';
export const UPDATE_PARADE_PROPERTY_GROUP_SUCCESS = 'UPDATE_PARADE_PROPERTY_GROUP_SUCCESS';
export const UPDATE_PARADE_PROPERTY_GROUP_FAILED = 'UPDATE_PARADE_PROPERTY_GROUP_FAILED';

export const UPDATE_PARADE_PUBLIC_VOTING_REQUEST = 'UPDATE_PARADE_PUBLIC_VOTING_REQUEST';
export const UPDATE_PARADE_PUBLIC_VOTING_SUCCESS = 'UPDATE_PARADE_PUBLIC_VOTING_SUCCESS';
export const UPDATE_PARADE_PUBLIC_VOTING_FAILED = 'UPDATE_PARADE_PUBLIC_VOTING_FAILED';

export const UPDATE_PARADE_REGISTRATION_REQUEST = 'UPDATE_PARADE_REGISTRATION_REQUEST';
export const UPDATE_PARADE_REGISTRATION_SUCCESS = 'UPDATE_PARADE_REGISTRATION_SUCCESS';
export const UPDATE_PARADE_REGISTRATION_FAILED = 'UPDATE_PARADE_REGISTRATION_FAILED';

export const UPDATE_PARADE_SEO_REQUEST = 'UPDATE_PARADE_SEO_REQUEST';
export const UPDATE_PARADE_SEO_SUCCESS = 'UPDATE_PARADE_SEO_SUCCESS';
export const UPDATE_PARADE_SEO_FAILED = 'UPDATE_PARADE_SEO_FAILED';

export const UPDATE_PARADE_SPONSOR_TIER_REQUEST = 'UPDATE_PARADE_SPONSOR_TIER_REQUEST';
export const UPDATE_PARADE_SPONSOR_TIER_SUCCESS = 'UPDATE_PARADE_SPONSOR_TIER_SUCCESS';
export const UPDATE_PARADE_SPONSOR_TIER_FAILED = 'UPDATE_PARADE_SPONSOR_TIER_FAILED';

export const UPDATE_PARADE_SPONSOR_OPTIONS_REQUEST = 'UPDATE_PARADE_SPONSOR_OPTIONS_REQUEST';
export const UPDATE_PARADE_SPONSOR_OPTIONS_SUCCESS = 'UPDATE_PARADE_SPONSOR_OPTIONS_SUCCESS';
export const UPDATE_PARADE_SPONSOR_OPTIONS_FAILED = 'UPDATE_PARADE_SPONSOR_OPTIONS_FAILED';

export const UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST = 'UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST';
export const UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS = 'UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_SUCCESS';
export const UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_FAILED = 'UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_FAILED';

export const UPDATE_PARADE_TICKET_REQUEST = 'UPDATE_PARADE_TICKET_REQUEST';
export const UPDATE_PARADE_TICKET_SUCCESS = 'UPDATE_PARADE_TICKET_SUCCESS';
export const UPDATE_PARADE_TICKET_FAILED = 'UPDATE_PARADE_TICKET_FAILED';

export const UPDATE_PARADE_TICKET_TAKER_REQUEST = 'UPDATE_PARADE_TICKET_TAKER_REQUEST';
export const UPDATE_PARADE_TICKET_TAKER_SUCCESS = 'UPDATE_PARADE_TICKET_TAKER_SUCCESS';
export const UPDATE_PARADE_TICKET_TAKER_FAILED = 'UPDATE_PARADE_TICKET_TAKER_FAILED';

export const UPDATE_PARADE_TICKET_TAKER_INVITATION_REQUEST = 'UPDATE_PARADE_TICKET_TAKER_INVITATION_REQUEST';
export const UPDATE_PARADE_TICKET_TAKER_INVITATION_SUCCESS = 'UPDATE_PARADE_TICKET_TAKER_INVITATION_SUCCESS';
export const UPDATE_PARADE_TICKET_TAKER_INVITATION_FAILED = 'UPDATE_PARADE_TICKET_TAKER_INVITATION_FAILED';

export const UPDATE_PARADE_TIMEFRAME_REQUEST = 'UPDATE_PARADE_TIMEFRAME_REQUEST';
export const UPDATE_PARADE_TIMEFRAME_SUCCESS = 'UPDATE_PARADE_TIMEFRAME_SUCCESS';
export const UPDATE_PARADE_TIMEFRAME_FAILED = 'UPDATE_PARADE_TIMEFRAME_FAILED';

export const UPLOAD_PARADE_DOWNLOADABLE_FILE_REQUEST = 'UPLOAD_PARADE_DOWNLOADABLE_FILE_REQUEST';
export const UPLOAD_PARADE_DOWNLOADABLE_FILE_SUCCESS = 'UPLOAD_PARADE_DOWNLOADABLE_FILE_SUCCESS';
export const UPLOAD_PARADE_DOWNLOADABLE_FILE_FAILED = 'UPLOAD_PARADE_DOWNLOADABLE_FILE_FAILED';

export const UPLOAD_PARADE_HOME_PAGE_IMAGE_REQUEST = 'UPLOAD_PARADE_HOME_PAGE_IMAGE_REQUEST';
export const UPLOAD_PARADE_HOME_PAGE_IMAGE_SUCCESS = 'UPLOAD_PARADE_HOME_PAGE_IMAGE_SUCCESS';
export const UPLOAD_PARADE_HOME_PAGE_IMAGE_FAILED = 'UPLOAD_PARADE_HOME_PAGE_IMAGE_FAILED';

export function archiveParadeRequest (body) {
  return {
    body,
    type: ARCHIVE_PARADE_REQUEST
  }
}

export function clearParadePropertyRemovedRequest () {
  return {
    type: CLEAR_PARADE_PROPERTY_REMOVED
  }
}

export function createParadeRequest (body) {
  return {
    body,
    type: CREATE_PARADE_REQUEST
  }
}

export function downloadParadeRegistrationsRequest (body) {
  return {
    body,
    type: DOWNLOAD_PARADE_REGISTRATIONS_REQUEST
  }
}

export function getParadeRequest ({ clientURL, paradeURL }) {
  return {
    clientURL,
    paradeURL,
    type: GET_PARADE_REQUEST
  }
}

export function getParadeListRequest ({ clientURL }) {
  return {
    clientURL,
    type: GET_PARADE_LIST_REQUEST
  }
}

export function getParadePublicVotingRequest (body) {
  return {
    body,
    type: GET_PARADE_PUBLIC_VOTES_REQUEST
  }
}

export function removeParadeDateRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_DATE_REQUEST
  }
}

export function removeParadeDownloadableFileRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_DOWNLOADABLE_FILE_REQUEST
  }
}

export function removeParadeHomePageImageRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_HOME_PAGE_IMAGE_REQUEST
  }
}

export function removeParadeImageRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_IMAGE_REQUEST
  }
}

export function removeParadeOptionalFeeRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_OPTIONAL_FEE_REQUEST
  }
}

export function removeParadePromoCodeRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_PROMO_CODE_REQUEST
  }
}

export function removeParadePropertyGroupRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_PROPERTY_GROUP_REQUEST
  }
}

export function removeParadeRegistrationRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_REGISTRATION_REQUEST
  }
}

export function removeParadeSponsorTierRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_SPONSOR_TIER_REQUEST
  }
}

export function removeParadeSupportingProductTimeframeRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST
  }
}

export function removeParadeTicketRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_TICKET_REQUEST
  }
}

export function removeParadeTicketTakerRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_TICKET_TAKER_REQUEST
  }
}

export function removeParadeTicketTakerInvitationRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_TICKET_TAKER_INVITATION_REQUEST
  }
}

export function removeParadeTimeframeRequest (body) {
  return {
    body,
    type: REMOVE_PARADE_TIMEFRAME_REQUEST
  }
}

export function reorderParadeFeesRequest (body) {
  return {
    body,
    type: REORDER_PARADE_OPTIONAL_FEES_REQUEST
  }
}

export function reorderParadePropertyGroupsRequest (body) {
  return {
    body,
    type: REORDER_PARADE_PROPERTY_GROUPS_REQUEST
  }
}

export function reorderParadeSponsorTiersRequest (body) {
  return {
    body,
    type: REORDER_PARADE_SPONSOR_TIERS_REQUEST
  }
}

export function reorderParadeTicketsRequest (body) {
  return {
    body,
    type: REORDER_PARADE_TICKETS_REQUEST
  }
}

export function resetParadeURLChanged () {
  return {
    type: RESET_PARADE_URL_CHANGED
  }
}

export function selectParadeBannerImageRequest (body) {
  return {
    body,
    type: SELECT_PARADE_BANNER_IMAGE_REQUEST
  }
}

export function updateParadeConfirmationEmailRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_CONFIRMATION_EMAIL_REQUEST
  }
}

export function updateParadeDateRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_DATE_REQUEST
  }
}

export function updateParadeHomePageContentRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_HOME_PAGE_CONTENT_REQUEST
  }
}

export function updateParadeNameRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_NAME_REQUEST
  }
}

export function updateParadeOptionalFeeRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_OPTIONAL_FEE_REQUEST
  }
}

export function updateParadePartnerPageRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_PARTNER_PAGE_REQUEST
  }
}

export function updateParadePromoCodeRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_PROMO_CODE_REQUEST
  }
}

export function updateParadePropertyGroupRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_PROPERTY_GROUP_REQUEST
  }
}

export function updateParadePublicVotingRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_PUBLIC_VOTING_REQUEST
  }
}

export function updateParadeRegistrationRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_REGISTRATION_REQUEST
  }
}

export function updateParadeSEORequest (body) {
  return {
    body,
    type: UPDATE_PARADE_SEO_REQUEST
  }
}

export function updateParadeSponsorTierRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_SPONSOR_TIER_REQUEST
  }
}

export function updateParadeSponsorOptionsRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_SPONSOR_OPTIONS_REQUEST
  }
}

export function updateParadeSupportingProductTimeframeRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME_REQUEST
  }
}

export function updateParadeTicketRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_TICKET_REQUEST
  }
}

export function updateParadeTicketTakerRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_TICKET_TAKER_REQUEST
  }
}

export function updateParadeTicketTakerInvitationRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_TICKET_TAKER_INVITATION_REQUEST
  }
}

export function updateParadeTimeframeRequest (body) {
  return {
    body,
    type: UPDATE_PARADE_TIMEFRAME_REQUEST
  }
}

export function uploadParadeDownloadableFileRequest (body) {
  return {
    body,
    type: UPLOAD_PARADE_DOWNLOADABLE_FILE_REQUEST
  }
}

export function uploadParadeHomePageImageRequest (body) {
  return {
    body,
    type: UPLOAD_PARADE_HOME_PAGE_IMAGE_REQUEST
  }
}
