import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPollRequest } from 'src/redux/actions/polls';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import AppCard from 'src/components/Elements/AppCard';

const Poll = () => {
  const client = useSelector(state => state.clientStore?.client);
  const poll = useSelector(state => state.pollStore?.poll);
  const pollFetching = useSelector(state => state.loadingStore.GET_POLL);
  const { pollURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!pollURL) {
    navigate('/');
  }

  useEffect(() => {
    dispatch(getPollRequest({ clientURL: client.url, pollURL }));
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'poll', client, poll }));
    dispatch(updateDrawerContent({
      fetching: pollFetching,
      key: 'poll',
      options: { client, poll } 
    }));
  }, [poll?.name, poll?.url, pollFetching]);

  return (
    <section className="app-grid">
      {
        pollFetching && (
          <div className="app-grid-loading">
              <LoadingCircle variant="dark" />
          </div>
        )
      }
      {
        !pollFetching && (
          <>
            <div className="app-grid-row">
              <AppCard
                className="flex-1"
                title="Online Survey Portal"
              >
                <div className="app-grid-title">
                  <div className="app-grid-title-details">
                    <h1>
                      {poll?.name}
                    </h1>
                  </div>
                </div>
              </AppCard>
              <div className="card-group">
                <AppCard
                  className="shrinkable"
                  title="Landing Page"
                >
                  <Link
                    className="item"
                    target="_blank"
                    to={`https://${client.url}.cpjam.com/${poll?.url}`}
                  >
                    {`https://${client.url}.cpjam.com/${poll?.url}`}
                  </Link>
                </AppCard>
              </div>
            </div>
          </>
        )
      }
    </section>
  )
}

export default Poll;
