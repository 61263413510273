import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getParadeRequest,
  resetParadeURLChanged,
  updateParadeNameRequest
} from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fieldTypes, inputTypes } from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const ParadeName = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const [configuration, setConfiguration] = useState(null);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  const handleSubmit = (parade) => {
    dispatch(updateParadeNameRequest({ clientURL: client.url, paradeURL, form: parade }));
  }

  useEffect(() => {
    if (!parade) {
      dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
    }
  }, []);

  useEffect(() => {
    if (parade?.urlChanged) {
      dispatch(resetParadeURLChanged());
      navigate(`/${client.url}/parades/${parade.urlChanged}/name`);
    }
  }, [parade?.urlChanged]);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'paradeName', client, parade }));
    dispatch(updateDrawerContent({
      fetching: paradeFetching,
      key: 'paradeName',
      options: { client, parade } 
    }));

    if (!configuration && parade) {
      setConfiguration({
        formElements: {
          rows: [{
            elements: [{
              label: 'Parade Name',
              name: 'name',
              type: fieldTypes.input,
              inputType: inputTypes.text,
              value: parade?.name,
              required: true
            }]
          }, {
            elements: [{
              label: 'Parade URL',
              name: 'url',
              type: fieldTypes.input,
              inputType: inputTypes.domain,
              value: parade?.url,
              required: true
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [parade?.name, parade?.url, paradeFetching]);

  return (
    <section className="parade-name box">
      <div className="box-title">
        <h3>
            {
              !paradeFetching && (
                <span>Name & URL</span>
              )
            }
            {
              paradeFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!paradeFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'UPDATE_PARADE_NAME'}
          />
          <hr />
          Public Landing Page: <Link
            target="_blank"
            to={`https://${client.url}.cpjam.com/${paradeURL}`}
          >{`https://${client.url}.cpjam.com/${paradeURL}`}</Link>
        </div>
        )
      }
    </section>
  )
}

export default ParadeName;
