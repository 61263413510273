import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContestRequest,
  reorderContestDocumentFoldersRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';

const ContestDocumentFolders = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const updatingDocumentFolders = useSelector(state => state.loadingStore.REORDER_CONTEST_DOCUMENT_FOLDERS);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, contest },
          key: 'createContestDocumentFolder'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: documentFolder => {
      dispatch(
        openModal({
          props: { client, contest, documentFolder },
          key: 'editContestDocumentFolder'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: documentFolder => {
      dispatch(
        openDialog({
          props: { client, contest, documentFolder },
          key: 'removeContestDocumentFolder'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestDocumentFolders', client, contest }));
    dispatch(updateDrawerContent({
      fetching: contestFetching,
      key: 'contestDocumentFolders',
      options: { client, contest } 
    }));
  }, [contestFetching]);

  const reorderDocumentFolderList = ({ list }) => {
    dispatch(reorderContestDocumentFoldersRequest({ clientURL: client.url, contestURL: contest.url, form: { documentFolders: list } }));
  }
  
  return (
    <ResourceList
      actions={actions}
      draggable
      fetching={contestFetching}
      handleDrag={reorderDocumentFolderList}
      list={contest?.documentFolders}
      renderItem={folder => 
        <div className="folder">
          <div className="folder-name">{folder.name}</div>
        </div>
      }
      renderKey="name"
      title="Document Folder List"
      updating={updatingDocumentFolders}
    />
  )
}

export default ContestDocumentFolders;