import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getContestRequest,
  getContestWinnersRequest
} from 'src/redux/actions/contests';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import { Button, MenuItem, Select } from '@mui/material';
import UserProfile from 'src/components/UserProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck, faSquare, faSquareMinus } from '@fortawesome/free-regular-svg-icons';

const ScholarshipConfirmWinners = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const fetchingWinners = useSelector(state => state.loadingStore.GET_CONTEST_WINNERS);
  const [scoresheet, setScoresheet] = useState('');
  const [scoresheets, setScoresheets] = useState();
  const [scoresheetName, setScoresheetName] = useState();
  const [currentScholarship, setCurrentScholarship] = useState();
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  const handleScoresheetChange = ({ value }) => {
    dispatch(getContestWinnersRequest({ clientURL: client.url, contestURL: contest.url, scoresheetID: value }));
    setScoresheet(value);
    const newScoresheet = scoresheets.find(scoresheet => scoresheet.scoresheetID === value);
    setScoresheetName(newScoresheet.name);
    setCurrentScholarship();
  }

  const handleSelectScholarship = (scholarship)  => {
    setCurrentScholarship(scholarship);
  }

  const scholarshipHasNoApplications = (item) => {
    return !item?.applications?.length;
  }

  const actions = [{
    color: 'confirm',
    label: 'Assign Awards',
    requiresSelection: true,
    variant: 'contained',
    fn: application => {
      dispatch(
        openModal({
          props: { client, contest, scholarship: currentScholarship, entry: application },
          key: 'assignContestAwards'
        })
      )
    }
  }];

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    if (contest?.url && !scoresheets) {
      setScoresheets(contest?.scoresheets);
      if (contest?.scoresheets.length > 0) {
        dispatch(getContestWinnersRequest({ clientURL: client.url, contestURL: contest.url, scoresheetID: contest?.scoresheets[0].scoresheetID }));
        setScoresheet(contest?.scoresheets[0].scoresheetID);
        setScoresheetName(contest?.scoresheets[0].name);
      }
    }
  }, [contest?.url]);

  useEffect(() => {
    if (currentScholarship) {
      const newScholarship = contest?.winnersScholarshipList.find(scholarship => scholarship.scholarshipID === currentScholarship.scholarshipID);
      setCurrentScholarship(newScholarship);
    }
  }, [contest?.winnersScholarshipList]);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestConfirmWinners', client, contest }));
    dispatch(updateDrawerContent({
      fetching: contestFetching,
      key: 'contestConfirmWinners',
      options: { client, contest }
    }));
  }, [contestFetching]);

  return (
    <section className="confirm-winners grid">
      <section className="box">
        <div className="box-title">
          <h3>
              {
                !contestFetching && (
                  <span>Confirm Winners Options
                  {
                    fetchingWinners && (
                      <LoadingCircle variant="dark" />
                    )
                  }
                  </span>
                )
              }
              {
                contestFetching && (
                  <LoadingCircle variant="dark" />
                )
              }
            </h3>
        </div>
        {
          (!contestFetching && scoresheets?.length) && (
            <>
            <div className="box-content options">
              <div>
                <label>Viewing Scores For:</label>
                <Select 
                  onChange={event => handleScoresheetChange({ value: event.target.value })}
                  value={scoresheet}
                >{
                  (scoresheets || [])?.map(scoresheet => {
                    return (
                      <MenuItem
                        key={scoresheet.scoresheetID}
                        value={scoresheet.scoresheetID}
                      >{scoresheet.name}</MenuItem>
                    )
                  })
                }</Select>
              </div>
              <div className="selected-items">
                {
                  currentScholarship && (
                    <Button
                      onClick={() => {
                        setCurrentScholarship();
                      }}
                      size="small"
                      variant="contained"
                    >Clear Selected Scholarship</Button>
                  )
                }
              </div>
            </div>
            {
              (!fetchingWinners && currentScholarship?.judges?.length > 0) && (
                <div className="box-content judges">
                  <p>Contributing Judges: </p>
                  <div className="users">
                    {
                      (currentScholarship?.judges || [])?.map(judge => 
                        <UserProfile
                          key={judge.userID}
                          showEmail
                          user={judge}
                        />
                      )
                    }
                  </div>
                </div>
              )
            }
            </>
          )
        }
      </section>
      {
        (!contestFetching && !fetchingWinners && !currentScholarship) && (
          <ResourceList
            itemDisabled={scholarshipHasNoApplications}
            list={contest?.winnersScholarshipList || []}
            notSelectable
            onClickFn={handleSelectScholarship}
            renderItem={scholarship => {
              const hasAwards = scholarship.applications.some(application => {
                return application.awards.some(award => award.selected);
              });
              const noApplications = scholarshipHasNoApplications(scholarship);
              return (
                <div className="category">
                  {
                    noApplications && (
                      <FontAwesomeIcon icon={faSquareMinus} />
                    )
                  }
                  {
                    hasAwards && (
                      <FontAwesomeIcon icon={faSquareCheck} />
                    )
                  }
                  {
                    (!hasAwards &&!noApplications) && (
                      <FontAwesomeIcon icon={faSquare} />
                    )
                  }
                  <div className="scholarship-name">{scholarship.name}</div>
                </div>
              )
            }}
            renderKey="scholarshipID"
            title={`Scholarship List For ${scoresheetName}`}
          />
        )
      }
      {
        (!contestFetching && !fetchingWinners && currentScholarship) && (
          <ResourceList
            actions={actions}
            list={currentScholarship?.applications || []}
            renderItem={application => 
              <div className="entry">
                <div className="entry-row">
                  <div className="entry-number">{application.applicantName} / {application.applicantEmail}</div>
                  <div className="entry-score">
                    <span>Score: {application.average}</span>
                  </div>
                  <div className="entry-awards">
                    <span>Awards: </span>
                    <span className="awards">
                      {
                        (application?.awards || [])?.filter(award => {
                          return award.selected;
                        }).map(award => 
                          <span key={award.awardID}>{award.name}</span>
                        ).reduce((acc, item) => acc === null ? [item] : [...acc, ', ', item], null) || '[None]'
                      }
                    </span>
                  </div>
                </div>
              </div>
            }
            renderKey="entryID"
            title={`Application List For ${currentScholarship?.name}`}
          />
        )
      }
    </section>
  )
}

export default ScholarshipConfirmWinners;
