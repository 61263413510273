import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventRequest,
  reorderEventTicketsRequest
} from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import ResourceList from 'src/components/ResourceList';

const EventTickets = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const updatingTickets = useSelector(state => state.loadingStore.REORDER_EVENT_TICKETS);
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  const actions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, event },
          key: 'createEventTicket'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: ticket => {
      dispatch(
        openModal({
          props: { client, event, ticket },
          key: 'editEventTicket'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: ticket => {
      dispatch(
        openDialog({
          props: { client, event, ticket },
          key: 'removeEventTicket'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventTickets', client, event }));
    dispatch(updateDrawerContent({
      fetching: eventFetching,
      key: 'eventTickets',
      options: { client, event } 
    }));
  }, [eventFetching]);

  const reorderTicketList = ({ list }) => {
    dispatch(reorderEventTicketsRequest({ clientURL: client.url, eventURL: event.url, form: { tickets: list } }));
  }
  
  return (
    <ResourceList
      actions={actions}
      draggable
      fetching={eventFetching}
      handleDrag={reorderTicketList}
      list={event?.tickets}
      renderItem={ticket => 
        <div className="ticket">
          <div className="ticket-name">{ticket.name}</div>
        </div>
      }
      renderKey="ticketID"
      title="Ticket List"
      updating={updatingTickets}
    />
  )
}

export default EventTickets;