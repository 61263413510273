import React, { useEffect } from 'react';
import {
  fieldTypes,
  inputTypes
} from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeUserPasswordByEmailRequest,
  getPasswordKeyRequest
} from 'src/redux/actions/users';
import { Button } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';

const ChangePasswordByEmail = () => {
  const { passwordKey } = useParams();
  const changeRequest = useSelector(state => state.userStore?.changeRequest);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (passwordKey) {
      dispatch(getPasswordKeyRequest({ passwordKey }));
      return;
    }

     navigate('/');
  }, []);

  const handleSubmit = (event) => {
    dispatch(changeUserPasswordByEmailRequest({ form: { ...event, passwordKey }}));
  }

  const configuration = {
    formElements: {
      rows: [{
        elements: [{
          label: 'Email Address',
          name: 'email',
          disabled: true,
          type: fieldTypes.input,
          inputType: inputTypes.email,
          value: changeRequest?.email,
          required: true
        }]
      }, {
        forward: 'Passwords are required to include a combination of at least 7 uppercase, lowercase and numeric characters.'
      }, {
        elements: [{
          label: 'Password',
          name: 'password',
          mustMatchPassword: 'confirm-password',
          type: fieldTypes.input,
          inputType: inputTypes.password,
          value: '',
          required: true,
          permitShowPassword: true
        }]
      }, {
        elements: [{
          label: 'Confirm Password',
          name: 'confirm-password',
          passwordToMatch: 'password',
          type: fieldTypes.input,
          inputType: inputTypes.passwordAny,
          value: '',
          required: true,
          permitShowPassword: true
        }]
      }]
    },
    submitCTA: 'Change Password'
  };

  return (
    <section className="login">
      <div className="login-form floating">
        <img className="logo" src="/assets/images/logo.svg" alt="Logo" />
        {
          !changeRequest?.passwordChanged && <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            requestPrefix={'CHANGE_USER_PASSWORD_BY_EMAIL'}
          />
        }
        {
          changeRequest?.passwordChanged && <div className="change-password-by-email-continue">
            <p className="change-password-by-email-continue-paragraph">Your password has been changed. Click to continue.</p>
            <Link to="/login">
              <Button
                variant="contained"
              >Go To Login</Button>
            </Link>
          </div>
        }
      </div>
    </section>
  )
}

export default ChangePasswordByEmail;
