import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPollRequest } from 'src/redux/actions/polls';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import PollGenerator from 'src/components/TemplateForms/Polls/PollGenerator';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const PollQuestions = () => {
  const client = useSelector(state => state.clientStore?.client);
  const poll = useSelector(state => state.pollStore?.poll);
  const pollFetching = useSelector(state => state.loadingStore.GET_POLL);
  const { pollURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!pollURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!poll) {
      dispatch(getPollRequest({ clientURL: client.url, pollURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'pollQuestions', client, poll }));
    dispatch(updateDrawerContent({
      fetching: pollFetching,
      key: 'pollQuestions',
      options: { client, poll } 
    }));
  }, [pollFetching]);
  
  return (
    <section className="box">
      <div className="box-title">
        <h3>
            {
              !pollFetching && (
                <span>Survey Questions / Structure</span>
              )
            }
            {
              pollFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        !pollFetching && (
        <div className="box-content">
          <PollGenerator />
        </div>
        )
      }
    </section>
  )
}

export default PollQuestions;