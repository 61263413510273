import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import ResourceList from 'src/components/ResourceList';
import TypeaheadList from 'src/components/TypeaheadList';
import UserProfile from 'src/components/UserProfile';
import { toast } from 'react-toastify';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import createLoadingSelector from 'src/redux/loading';

const ClientAdministrative = ({ creatingAdministrator }) => {
  const client = useSelector(state => state.clientStore?.client);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'clientAdministrative', client }));
    dispatch(updateDrawerContent({ key: 'clientAdministrative', options: { client }  }));
  }, []);

  const handleCreateAdministrator = (event) => {
    if (event.sendInvite) {
      dispatch(
        openModal({
          props: { administrator: event, client },
          key: 'createClientAdministratorInvite'
        })
      )
      return;
    }

    dispatch(
      openModal({
        props: { administrator: event, client },
        key: 'createClientAdministrator'
      })
    )
  }

  const actions = [{
    label: 'Edit',
    requiresSelection: true,
    fn: administrator => {
      dispatch(
        openModal({
          props: { administrator, client },
          key: 'editClientAdministrator'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: administrator => {
      dispatch(
        openDialog({
          props: { administrator, client },
          key: 'removeAdministrator'
        })
      )
    }
  }];

  const invitationActions = [{
    label: 'Edit',
    requiresSelection: true,
    fn: invitation => {
      const { payload, ...props } = invitation;
      dispatch(
        openModal({
          props: { administrator: { ...payload, email: props.email, invitationKey: props.invitationKey }, client },
          key: 'editClientAdministratorInvitation'
        })
      )
    }
  }, {
    color: 'confirm',
    label: 'Copy Link',
    requiresSelection: true,
    variant: 'contained',
    fn: invitation => {
      navigator.clipboard.writeText(`https://admin.cpjam.com/signup/${invitation.invitationKey}`);
      toast.success('Link copied to clipboard.');
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: invitation => {
      dispatch(
        openDialog({
          props: { client, invitation },
          key: 'removeAdministratorInvitation'
        })
      )
    }
  }];

  return (
    <section className="client-administrative grid">
      <TypeaheadList
        actions={actions}
        create={handleCreateAdministrator}
        list={client.administrators}
        permitInvitation
        renderItem={user =>
          <UserProfile
            showEmail
            user={user}
          ></UserProfile>
        }
        renderKey="fullName"
        selecting={creatingAdministrator}
        title="Administrative Rights"
      />
      {
        ((client.invitations || []).length > 0) && (
          <ResourceList
            actions={invitationActions}
            list={client.invitations}
            renderItem={invitation => 
              <div className="invitation">
                <div className="invitation-details">
                  <span className="strong">{invitation.email}</span> &mdash; <span className="italic">expires: {invitation.exp}</span>
                </div>
                <div className="invitation-link">
                  {`https://admin.cpjam.com/signup/${invitation.invitationKey}`}
                </div>
              </div>}
            renderKey="email"
            title="Pending Invitations"
          />
        )
      }
    </section>
  )
}

const loadingSelector = createLoadingSelector(['UPDATE_ADMINISTRATOR', 'UPDATE_ADMINISTRATOR_INVITATION']);
const mapStateToProps = (state) => ({ creatingAdministrator: loadingSelector(state) });
export default connect(mapStateToProps)(ClientAdministrative);
