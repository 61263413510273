import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventRequest,
  updateEventConnectedContestRequest
} from 'src/redux/actions/events';
import {
  getContestListRequest
} from 'src/redux/actions/contests';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fieldTypes,
  inputTypes,
  none
} from 'src/constants';
import AutoForm from 'src/components/AutoForm';
import LoadingCircle from 'src/components/Elements/LoadingCircle';

const EventConnectedContest = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const contests = useSelector(state => state.contestStore?.contests);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const [configuration, setConfiguration] = useState(null);
  const [hasSelectedContest, setHasSelectedContest] = useState(event?.connectedContest !== none);
  const [title, setTitle] = useState();
  const [contest, setContest] = useState();
  const [CTA, setCTA] = useState();
  const [description, setDescription] = useState();
  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  const handleSubmit = (contest) => {
    dispatch(updateEventConnectedContestRequest({ clientURL: client.url, eventURL, form: contest }));
  }

  const onChange = ({ element, value }) => {
    if (element?.name === 'connectedContest') {
      setHasSelectedContest(value !== none);
      setContest(value);
    }
    if (element?.name === 'contestSectionTitle') {
      setTitle(value);
    }
    if (element?.name === 'contestCTA') {
      setCTA(value);
    }
    if (element?.name === 'contestDescription') {
      setDescription(value);
    }
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
    if (!contests) {
      dispatch(getContestListRequest({ clientURL: client.url }));
    }
  }, []);
  
  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventConnectedContest', client, event }));
    dispatch(updateDrawerContent({
      fetching: eventFetching,
      key: 'eventConnectedContest',
      options: { client, event } 
    }));

    if (!!contests?.length && !eventFetching) {
      setConfiguration({
        formElements: {
          rows: [{
            elements: [{
              label: 'Connected Awards Program',
              name: 'connectedContest',
              type: fieldTypes.select,
              options: [{ label: 'None', value: none },
                ...(contests || [])
                  .filter(contest => !contest.archived)
                  .map(contest => { return { label: contest.name, value: contest.contestID }})
              ],
              value: contest || event?.connectedContest || none
            }]
          }, {
            elements: [{
              label: 'Section Title',
              name: 'contestSectionTitle',
              type: fieldTypes.input,
              inputType: inputTypes.text,
              value: title || event?.contestSectionTitle || '',
              hide: { key: 'connectedContest', value: none },
              required: !!hasSelectedContest
            }, {
              label: 'Contest Call To Action',
              name: 'contestCTA',
              type: fieldTypes.input,
              inputType: inputTypes.text,
              value: CTA || event?.contestCTA || '',
              hide: { key: 'connectedContest', value: none },
              required: !!hasSelectedContest
            }]
          }, {
            elements: [{
              label: 'Contest Description',
              name: 'contestDescription',
              type: fieldTypes.textarea,
              value: description || event?.contestDescription || '',
              hide: { key: 'connectedContest', value: none },
              required: !!hasSelectedContest
            }]
          }]
        },
        submitCTA: 'Save'
      });
    }
  }, [contests, eventFetching, hasSelectedContest]);

  return (
    <section className="event-name box">
      <div className="box-title">
        <h3>
            {
              !eventFetching && (
                <span>Connected Awards Program</span>
              )
            }
            {
              eventFetching && (
                <LoadingCircle variant="dark" />
              )
            }
          </h3>
      </div>
      {
        (!eventFetching && configuration) && (
        <div className="box-content">
          <AutoForm
            configuration={configuration}
            handleSubmit={handleSubmit}
            onChange={onChange}
            requestPrefix={'UPDATE_EVENT_CONNECTED_CONTEST'}
          />
        </div>
        )
      }
    </section>
  )
}

export default EventConnectedContest;
