import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { gatewayOptions } from 'src/constants';
import { currency } from 'src/utilities/strings';
import StripeCheckout from 'src/components/CreditCardForms/Stripe';
import { toast } from 'react-toastify';

const Checkout = ({ balance, gateway, handleCheckout, items }) => {
  const client = useSelector(state => state.clientStore?.client);
  const [tokenRequesting, setTokenRequesting] = useState(false);
  const handleTokenResponse = ({ error, token }) => {
    setTokenRequesting(false);
    if (error) {
      toast.error(error.message);
      return;
    }
    handleCheckout({ token });
  }

  return (
    <section className="checkout">
      <div className="checkout-item heading">
        <div className="checkout-item-name">Name</div>
        <div className="checkout-item-price">Price</div>
        <div className="checkout-item-quantity">Qty</div>
        <div className="checkout-item-total">Total</div>
      </div>
      {
        (items || []).map(item => {
          return (
            <div className="checkout-item" key={item?.name}>
              <div className="checkout-item-name">{item?.name}</div>
              <div className="checkout-item-price">{currency(parseFloat(item?.amount))}</div>
              <div className="checkout-item-quantity">{(item?.quantity || 1)}</div>
              <div className="checkout-item-total">{currency((item?.quantity || 1) * parseFloat(item?.amount))}</div>
            </div>
          )
        })
      }
      <div className="checkout-item summary">
        <div className="checkout-item-name">Total</div>
        <div className="checkout-item-price"></div>
        <div className="checkout-item-quantity"></div>
        <div className="checkout-item-total">{currency(balance)}</div>
      </div>
      {
        (gateway === gatewayOptions.stripe.value || client.gateway === gatewayOptions.stripe.value) && (
          <StripeCheckout
            handleTokenResponse={handleTokenResponse}
            setTokenRequesting={setTokenRequesting}
            stripePublishKey={client.stripePublishKey}
            tokenRequesting={tokenRequesting}
          />
        )
      }
    </section>
  )
}

export default Checkout;
